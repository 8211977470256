import {
  Badge,
  Box,
  ButtonGroup,
  Center,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { TransactionResponseDTO } from "models/Transactions";
import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import API from "services/API";
import {
  denominationSymbolState,
  languageState,
  orgSecondaryColorState,
} from "store/Store";
import { relativeTime } from "utils/dateCalcs";
import { LanguageKey, strings } from "utils/languageStrings";

export const Transactions = () => {
  const PAGE_LIMIT = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] =
    useState<TransactionResponseDTO | null>(null);

  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);
  const language = useRecoilValue<LanguageKey>(languageState);

  var transactionRows: JSX.Element[] | any = [] as any;

  const fetchTransactions = () => {
    setLoading(true);
    API.transactions(PAGE_LIMIT, currentPage)
      .then((data) => setTransactions(data))
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage]);

  if (transactions) {
    transactionRows = transactions!.data.map((transaction: any) => {
      return (
        <Tr key={transaction.id} h="50px">
          <Td textAlign={"center"} px="10">
            {transaction.id}
          </Td>
          <Td px="10">{relativeTime(transaction.createdAt, language)}</Td>
          {transaction.betTicket &&
          transaction.betTicket.ticketType === "Fantasy" ? (
            <Td pl="4" pr="10">
              {transaction.method.replace("Bet", "Entry")}
            </Td>
          ) : (
            <Td pl="4" pr="10">
              {transaction.method}
            </Td>
          )}
          <Td px="4">
            <Center>
              {transaction.amount < 0 && (
                <Badge color="red">{`-${
                  denominationSymbol + Math.abs(transaction.amount).toFixed(2)
                }`}</Badge>
              )}
              {transaction.amount > 0 && (
                <Badge color="#35d900">{`${
                  denominationSymbol + transaction.amount.toFixed(2)
                }`}</Badge>
              )}
            </Center>
          </Td>
          <Td pl="16" textAlign="right">
            {transaction.betTicket?.id ?? "-"}
          </Td>
        </Tr>
      );
    });
  }

  return (
    <Box mt="40px">
      {!loading && (
        <Table
          size="sm"
          bg="white"
          variant="unstyled"
          display="block"
          whiteSpace="nowrap"
          overflowX="auto"
          w="100%"
        >
          <Thead>
            <Tr>
              <Th
                color="#FFFFFF"
                background={orgSecondaryColor}
                pr="10"
                minW="85px"
              >
                {strings[language].transactions.transId}
              </Th>
              <Th
                color="#FFFFFF"
                background={orgSecondaryColor}
                px="10"
                minW="110px"
              >
                {strings[language].transactions.createdAT}
              </Th>
              <Th
                color="#FFFFFF"
                background={orgSecondaryColor}
                px="10"
                minW="110px"
              >
                {strings[language].transactions.method}
              </Th>
              <Th
                color="#FFFFFF"
                background={orgSecondaryColor}
                px="10"
                minW="85px"
              >
                {strings[language].transactions.amount}
              </Th>
              <Th
                color="#FFFFFF"
                background={orgSecondaryColor}
                pl="10"
                w="100%"
                textAlign="right"
              >
                {strings[language].transactions.betTicketId}
              </Th>
            </Tr>
          </Thead>
          <Tbody position="relative">{transactionRows}</Tbody>
        </Table>
      )}
      {loading && (
        <Center>
          <Box
            mt="5"
            maxW="lg"
            background="white"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
          >
            <Box p="6">
              <Spinner />
            </Box>
          </Box>
        </Center>
      )}
      {transactionRows.length == 0 && !loading && (
        <Center>
          <Box
            mt="5"
            maxW="lg"
            background="white"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
          >
            <Box p="6">
              <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
                {strings[language].transactions.noItems}
              </Box>
            </Box>
          </Box>
        </Center>
      )}
      {transactionRows.length != 0 && !loading && (
        <Box mt="10" pb="10">
          <Center>
            <ButtonGroup
              color="gray.200"
              size="md"
              isAttached
              variant="outline"
            >
              {currentPage == 1 ||
                ((transactions?.meta.total_pages ?? 0) == 1 && <div></div>)}

              {currentPage !== 1 && (
                <IconButton
                  children={<FaChevronLeft color="black" />}
                  bgColor="white"
                  borderRadius="6px 0px 0px 6px"
                  aria-label="Previous Page"
                  onClick={(e) => {
                    setCurrentPage(currentPage - 1);
                  }}
                />
              )}

              <Box bgColor="white">
                <Center>
                  <Text padding="6px" color="black">
                    {currentPage} / {transactions?.meta.total_pages ?? 0}
                  </Text>
                </Center>
              </Box>

              {currentPage !== (transactions?.meta.total_pages ?? 0) && (
                <IconButton
                  children={<FaChevronRight color="black" />}
                  bgColor="white"
                  borderRadius="0px 6px 6px 0px"
                  aria-label="Next Page"
                  onClick={(e) => {
                    setCurrentPage(currentPage + 1);
                  }}
                />
              )}
            </ButtonGroup>
          </Center>
        </Box>
      )}
    </Box>
  );
};
