import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Image,
  Flex,
  SimpleGrid,
  Text,
  useToast,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import BBSpinner from "components/shared/BBSpinner";
import { Bet, OddsChangeData, SportBettingTypes } from "../../../models/Bets";
import { EventDTO } from "models/Events";
import {
  useQuery,
  useMutation,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import Event from "./Event";
import API from "services/API";
import { BetSlip } from "../betslip/BetSlip";
import { useHistory, useParams } from "react-router-dom";
import { ParticipantDTO } from "models/Participant";
import TournamentEvents from "../TournamentEvents";
import { Footer } from "components/shared/Footer";
import AdCarousel from "components/shared/AdCarousel";
import { useRecoilValue } from "recoil";
import { languageState, orgSecondaryColorState } from "store/Store";
import { LeagueDTO } from "models/Leagues";
import { MobileBetSlip } from "../betslip/MobileBetSlip";
import { SportDTO } from "models/Sports";
import { strings, LanguageKey } from "utils/languageStrings";
import OddsChangeModal from "../betslip/OddsChangeModal";

interface EventsProps {
  refetchProfile: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
  leagueFilter?: number;
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

interface EventsParams {
  sportId: string;
}

export default function Events(props: EventsProps) {
  const { sportId } = useParams<EventsParams>();
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const [bets, setBets] = useState<Bet[]>([]);
  const [sportFilters, setSportFilters] = useState(0);
  const [leagueId, setLeagueId] = useState(0);
  const [sports, setSports] = useState<SportDTO[] | null>(null);
  const [events, setEvents] = useState<SportDTO | null>(null);
  const [loading, setLoading] = useState(true);
  const [oddsChangeData, setOddsChangeData] = useState<OddsChangeData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const history = useHistory();
  const toast = useToast();
  const ref = useRef<null | HTMLDivElement>(null);

  // Get current selected bets
  var betArr: [];
  var stringBets = window.localStorage.getItem("bets");
  stringBets !== null ? (betArr = JSON.parse(stringBets!)) : (betArr = []);

  const fetchEvents = () => {
    setLoading(true);
    API.getEvents(+sportId, 0)
      .then((data) => {
        setEvents(data);
      })
      .catch(() => {
        toast({
          title: `Error fetching events.`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSports = () => {
    API.getSports()
      .then((data) => setSports(data))
      .catch();
  };

  useEffect(() => {
    setLeagueId(props.leagueFilter || 0);
    setSportFilters(+sportId);
    setBets(betArr);
  }, [sportId, props.leagueFilter]);

  /* Auto scroll to selected league */
  useEffect(() => {
    if (!ref.current) {
      return;
    } else {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, [leagueId]);

  useEffect(() => {
    fetchSports();
    fetchEvents();
  }, [sportId]);

  var eventRows: JSX.Element[] = [];
  var leagues: JSX.Element[] = [];
  var tournaments: JSX.Element[] = [];
  var sportRows: JSX.Element[] = [];

  const { mutate: placeBet } = useMutation(API.placeBet, {
    onSuccess: (data) => {
      setBets([]);

      props.refetchProfile();

      toast({
        title: `Bet successfully placed!`,
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
    onError: (error: any) => {
      if (error.response.status == "409") {
        setOddsChangeData(error.response.data);
        onOpen();
      }

      toast({
        title: `Error placing bet`,
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
  });

  sportRows = (sports ?? []).map((sport: any) => {
    var selected = false;
    if (sportFilters === sport.id) {
      selected = true;
    }

    return (
      <Button
        key={sport.id}
        variant="ghost"
        color={selected ? "white" : "darkgray"}
        _hover={{ color: "white" }}
        m="1"
        onClick={() => {
          setSportFilters(sport.id);
          history.push(`/sports/${sport.id}`);
        }}
      >
        {sport.name}
      </Button>
    );
  });

  // Proceed creating events if leagues exist and sport is not a tournament sport
  if (events?.leagues && !events?.allowTournaments) {
    if ((events?.leagues.length ?? 0) !== 0) {
      leagues = (events?.leagues ?? []).map((league: LeagueDTO) => {
        eventRows = league.events.map((event: EventDTO) => {
          // Ensure the betOverride field is overriding the bets field
          const eventOverride: EventDTO = {
            ...event,
            bettingLines: event.bettingLines.map((bl) => {
              return {
                ...bl,
                odds: bl.oddsOverride != null ? bl.oddsOverride : bl.odds,
              };
            }),
          };
          return (
            <Box bg="white" key={event.id}>
              <Box>
                <Event
                  bets={bets}
                  event={eventOverride}
                  sport={events}
                  moreWagersVisible={true}
                  clickHandler={(
                    bettingLineId: string,
                    externalId: string,
                    odds: number,
                    betType: SportBettingTypes,
                    category: string,
                    participant: ParticipantDTO
                  ) => {
                    if (bets.some((e) => e.bettingLineId === bettingLineId)) {
                      var newBets = bets;
                      const index = bets.findIndex((betItem) => {
                        return betItem.bettingLineId === bettingLineId;
                      });

                      if (index > -1) {
                        newBets.splice(index, 1);
                      }

                      setBets([...newBets]);
                      const betString = JSON.stringify(bets);
                      window.localStorage.setItem("bets", betString);
                      return;
                    }

                    let bet: Bet = {
                      event: event,
                      riskAmount: 0,
                      winAmount: 0,
                      bettingLineId: bettingLineId,
                      externalId: externalId,
                      bettingType: betType,
                      category: category,
                      odds: odds,
                      participant: participant,
                      sport: sportName,
                    };
                    newBets = bets;

                    newBets.push(bet);
                    setBets([...newBets]);
                    const betString = JSON.stringify(bets);
                    window.localStorage.setItem("bets", betString);

                    /** Display Error Toast if bet slip is greater than 25 */
                    if (bets.length > 25) {
                      toast({
                        title: `Error`,
                        description: "Only 25 Bets Allowed On A Ticket",
                        position: "bottom-right",
                        status: "error",
                        isClosable: true,
                      });
                    }
                  }}
                />
              </Box>
            </Box>
          );
        });

        return (
          <Box ref={league.id === leagueId ? ref : null} key={league.id}>
            <Box
              h="68px"
              w="100%"
              display="flex"
              alignItems="center"
              background={`${orgSecondaryColor}30 0% 0% no-repeat padding-box`}
              boxShadow="inset 0px 4px 4px #00000029"
            >
              <Text color="#000000" fontWeight="bold" ml="40px">
                {league.name}
              </Text>
            </Box>
            <Box>{eventRows}</Box>
          </Box>
        );
      });
    }
  } else if (events?.tournamentEvents && events?.allowTournaments) {
    if (events?.tournamentEvents.length > 0) {
      events?.tournamentEvents.forEach((tournament, i) => {
        tournaments.push(
          <TournamentEvents
            tournament={tournament}
            expanded={i === 0 ? true : false}
            bets={bets}
            setBets={setBets}
          />
        );
      });
    } else {
      tournaments.push(
        <Box mt="10" p="10" background="gray.800">
          <Center>
            <Alert status="warning" variant="top-accent" width="auto">
              <AlertIcon />
              <Center>
                <Text align="center">
                  {strings[language].sportsbook.noEventsSport}
                </Text>
              </Center>
            </Alert>
          </Center>
        </Box>
      );
    }
  }

  var sportName = events?.name ?? "";

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Box
          pb="0"
          h="100vh"
          style={{ position: "relative", overflowY: "auto" }}
          width={{ base: "100%", lg: "calc(200% - 335px)" }}
        >
          <Box minH="100vh">
            <Box p={2} w="100%">
              <AdCarousel bets={bets} setBets={setBets} />
            </Box>
            <Flex pl="4" alignItems="center" h="5rem" hideBelow="sm">
              <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                {sportName.charAt(0).toUpperCase() + sportName.slice(1)}{" "}
              </Text>
            </Flex>

            {(sports?.length ?? 0) !== 0 && (
              <Box
                p="4"
                h="43px"
                w="100%"
                display="flex"
                alignItems="center"
                background={`${orgSecondaryColor} 0% 0% no-repeat padding-box`}
                boxShadow="inset 0px 4px 4px #00000029, 0px 4px 4px #00000029"
                overflowX="auto"
                overflowY="hidden"
              >
                <HStack ml="2">{sportRows}</HStack>
              </Box>
            )}

            {loading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30%",
                  height: "100%",
                }}
              >
                <BBSpinner />
              </div>
            )}

            {leagues.length !== 0 && (
              <Box
                borderRadius="0px 0px 5px 5px"
                maxW="100%"
                position="relative"
                overflowY="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {leagues}
              </Box>
            )}
            {!loading && (events?.leagues?.length ?? 0) === 0 && (
              <Box mt="20" p="10" background="white" h="50vh">
                <Center>
                  <Box>
                    <Image src="/event.png" w="141px" />
                  </Box>
                </Center>
                <Center mt="5">
                  <Alert colorScheme="whiteAlpha" width="auto">
                    <AlertIcon color="#FCD28D" />
                    <Center>
                      <Text as="b" align="center" color="black">
                        {strings[language].sportsbook.noEventsSport}!
                      </Text>
                    </Center>
                  </Alert>
                </Center>
              </Box>
            )}
            {!loading &&
              leagues.length === 0 &&
              (events?.leagues?.length ?? 0) !== 0 && (
                <Box mt="20" p="10" background="white" h="50vh">
                  <Center>
                    <Box>
                      <Image src="/event.png" w="141px" />
                    </Box>
                  </Center>
                  <Center mt="5">
                    <Alert colorScheme="whiteAlpha" width="auto">
                      <AlertIcon color="#FCD28D" />
                      <Center>
                        <Text as="b" align="center" color="black">
                          {strings[language].sportsbook.noEventsLeague}!
                        </Text>
                      </Center>
                    </Alert>
                  </Center>
                </Box>
              )}
          </Box>
          <Box mb="60px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          left="100%"
          zIndex={1}
          hideBelow="lg"
        >
          <BetSlip
            placeBetLoading={placeBetLoading}
            setPlaceBetLoading={setPlaceBetLoading}
            bets={bets}
            setBets={setBets}
            placeBet={placeBet}
          />
        </Box>
      </SimpleGrid>
      <MobileBetSlip
        placeBetLoading={placeBetLoading}
        setPlaceBetLoading={setPlaceBetLoading}
        bets={bets}
        setBets={setBets}
        placeBet={placeBet}
        collapseSlip={props.collapseSlip}
        setCollapseSlip={props.setCollapseSlip}
      />
      {oddsChangeData && (
        <OddsChangeModal
          isOpen={isOpen}
          onClose={onClose}
          oddsChangeData={oddsChangeData}
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
        />
      )}
    </Box>
  );
}
