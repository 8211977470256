import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { PasswordField } from "components/auth/PasswordField";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import APIService from "services/API";
import { UserEmail } from "../../models";
import { validEmail } from "utils/Regex";

interface ForgotPasswordFormProps {
  admin: boolean;
}

export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const history = useHistory();
  const toast = useToast();

  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { register, handleSubmit, setValue } = useForm<UserEmail>({
    mode: "onChange",
  });

  const { mutate, isLoading, error, isError } = useMutation(
    APIService.resetPassword,
    {
      onSuccess: (data) => {
        setEmailSent(true);
        toast({
          title: "Success",
          description:
            "Reset password email sent to the corresponding account.",
          position: "bottom-right",
          status: "success",
          isClosable: true,
        });
        // history.push("/");
      },
      onError: (err) => {
        if (validateEmail() == false) {
          toast({
            title: "Error",
            description: "Invalid Email",
            position: "bottom-right",
            status: "error",
            isClosable: true,
          });
        } else {
          setEmailSent(true);
          toast({
            title: "Success",
            description:
              "Reset password email sent to the corresponding account.",
            position: "bottom-right",
            status: "success",
            isClosable: true,
          });
        }
      },
    }
  );

  const validateEmail = () => {
    if (!validEmail.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = (data: UserEmail) => {
    const userLogin = {
      ...data,
    };
    mutate(userLogin);
  };

  return (
    <Box w="85%" mx="auto">
      {emailSent == false && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading
            textAlign="center"
            size="lg"
            fontWeight="bold"
            mb="4"
            mt="4"
            color="black"
          >
            Forgot your password?
          </Heading>
          <Text color="#575757" textAlign="center">
            Enter your email and we’ll send you a link to reset your password.
          </Text>
          <Stack spacing="6">
            <FormErrorMessage>{errorMessage}</FormErrorMessage>

            <FormControl mt="6" id="email" isInvalid={errorMessage != ""}>
              <FormLabel color="#000000">Email</FormLabel>
              <Input
                required
                placeholder="Enter your email address"
                _placeholder={{ opacity: 1, color: "gray.500" }}
                tabIndex={1}
                outline="1px solid lightgray"
                color="black"
                {...register("email")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              color="white"
              size="lg"
              fontSize="md"
              bg="#47AB37"
              tabIndex={4}
              isLoading={isLoading}
              onClick={validateEmail}
            >
              Reset password
            </Button>
          </Stack>
        </form>
      )}

      {emailSent == true && (
        <Center>
          <Stack>
            <Heading
              textAlign="center"
              size="lg"
              fontWeight="bold"
              mb="4"
              mt="4"
              color="black"
            >
              Check your email!
            </Heading>
            <Center>
              <Text textAlign="center" color="#575757" mb="5">
                We sent a password reset link to {" " + email}
              </Text>
            </Center>
            <Center>
              <Button
                colorScheme="blue"
                color="white"
                size="lg"
                w="325px"
                mb="5"
                fontSize="md"
                bg="#47AB37"
                tabIndex={4}
                isLoading={isLoading}
                onClick={() => history.push("/")}
              >
                Ok
              </Button>
            </Center>
            <Center>
              <Text textAlign="center" color="#0C1335">
                Didn’t receive the email? Click{" "}
                <Text
                  as="button"
                  color="#47AB37"
                  textDecoration="underline"
                  onClick={() => setEmailSent(false)}
                >
                  here
                </Text>{" "}
                to resend.
              </Text>
            </Center>
          </Stack>
        </Center>
      )}
    </Box>
  );
};
