export function sportIconsFilter(sportName: string) {
  switch (sportName.toLowerCase()) {
    case "football":
    case "aussie rules":
    case "rugby":
      return "football.svg";
    case "boxing":
    case "mixed martial arts":
      return "fighting.svg";
    case "basketball":
      return "basketball.svg";
    case "baseball":
      return "baseball.svg";
    case "hockey":
      return "hockey.svg";
    case "soccer":
      return "soccer.svg";
    case "cricket":
      return "cricket.svg";
    case "golf":
      return "golf.svg";
    case "tennis":
      return "tennis.svg";
    case "racing":
      return "racing.svg";

    default:
      return "default.svg";
  }
}
