import { Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { Bet, SportBettingTypes } from "models/Bets";
import { EventDTO } from "models/Events";
import { ParticipantDTO } from "models/Participant";
import React from "react";
import { useRecoilValue } from "recoil";
import { oddsDisplayState, orgPrimaryColorState } from "store/Store";
import { metricToAmericanOdds } from "utils/oddsCalc";

interface PropTypes {
  bets: Bet[];
  tournament: EventDTO;
  clickHandler: (
    bettingLineId: string,
    externalId: string,
    odds: number,
    betType: SportBettingTypes,
    betDescription: string,
    participant: ParticipantDTO
  ) => void;
}

export default function TournamentEvent({
  bets,
  tournament,
  clickHandler,
}: PropTypes) {
  tournament.bettingLines = tournament.bettingLines.sort(
    (a, b) => a.odds - b.odds
  );
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const participants = tournament.bettingLines.map((participant) => {
    // change participant odds to override odds if it exists
    participant.odds =
      participant.oddsOverride != null
        ? participant.oddsOverride
        : participant.odds;

    // Is participant selected
    let participantSelected = false;
    if (bets.some((e) => e.bettingLineId === participant?.id ?? "")) {
      participantSelected = true;
    }
    return (
      <GridItem>
        {participant.name && participant.odds != null && (
          <VStack h="100%">
            <VStack
              w="140px"
              h="100%"
              textAlign={"center"}
              background={participantSelected ? orgPrimaryColor : "gray.800"}
              borderWidth="1px"
              borderColor="gray.500"
              fontWeight="bold"
              cursor="pointer"
              fontSize="sm"
              p="5px"
              onClick={() => {
                clickHandler(
                  participant!.id,
                  participant!.externalId,
                  participant!.odds,
                  //FIX BELOWWWWWWWWW
                  SportBettingTypes.total,
                  participant!.name,
                  participant!.paramParticipant1
                );
              }}
            >
              <Text>{participant.name}</Text>
              <Text
                color={participantSelected ? "white" : orgPrimaryColor}
                align="right"
              >
                {/* FIX BELOW */}
                {`${participant!.odds >= 2 && oddsDisplay === "American" ? "+" : ""}${
                  oddsDisplay === "American"
                    ? metricToAmericanOdds(participant!.odds).toFixed(0)
                    : participant!.odds}
                }
                }`}
              </Text>
            </VStack>
          </VStack>
        )}
      </GridItem>
    );
  });

  return (
    <Grid mt="5" templateColumns="repeat(3, 1fr)" gap={2}>
      {participants}
    </Grid>
  );
}
