import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useRecoilValue } from "recoil";
import { languageState } from "store/Store";
import { strings, LanguageKey } from "utils/languageStrings";

export const PasswordField = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const language = useRecoilValue<LanguageKey>(languageState);

    const mergeRef = useMergeRefs(inputRef, ref);

    const onClickReveal = () => {
      onToggle();
      const input = inputRef.current;
      if (input) {
        input.focus({ preventScroll: true });
        const length = input.value.length * 2;
        requestAnimationFrame(() => {
          input.setSelectionRange(length, length);
        });
      }
    };

    return (
      <FormControl id="password">
        {/* <Flex justify="space-between">
          <FormLabel>Password</FormLabel>
        </Flex> */}
        <InputGroup>
          <InputRightElement mt="8px" mr="8px">
            <IconButton
              color="#989898"
              variant="ghost"
              aria-label={isOpen ? "Mask password" : "Reveal password"}
              icon={isOpen ? <HiEyeOff /> : <HiEye />}
              onClick={onClickReveal}
              tabIndex={3}
            />
          </InputRightElement>
          <Input
            ref={mergeRef}
            name="password"
            outline="1px solid lightgray"
            type={isOpen ? "text" : "password"}
            autoComplete="current-password"
            placeholder={strings[language].login.password}
            _placeholder={{ opacity: 1, color: "gray.500" }}
            backgroundColor="#FAFAFA"
            h="53px"
            w="426px"
            color="black"
            required
            tabindex="2"
            minLength={8}
            {...props}
          />
        </InputGroup>
      </FormControl>
    );
  }
);

PasswordField.displayName = "PasswordField";
