import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useToast,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  HStack,
  Stack,
  Center,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { FaArrowLeft } from "react-icons/fa";
import BBSpinner from "components/shared/BBSpinner";
import { Bet, OddsChangeData, SportBettingTypes } from "../../../models/Bets";
import {
  useQuery,
  useMutation,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import Event from "./Event";
import API from "services/API";
import { BetSlip } from "../betslip/BetSlip";
import { useHistory, useParams } from "react-router-dom";
import { ParticipantDTO } from "models/Participant";
import { Footer } from "components/shared/Footer";
import AdCarousel from "components/shared/AdCarousel";
import { metricToAmericanOdds } from "utils/oddsCalc";
import { BettingLineDTO, BettingLineGroupDTO } from "models/BettingLine";
import { useRecoilValue } from "recoil";
import {
  orgSecondaryColorState,
  orgPrimaryColorState,
  oddsDisplayState,
  languageState,
} from "store/Store";
import { MobileBetSlip } from "../betslip/MobileBetSlip";
import { strings, LanguageKey } from "utils/languageStrings";
import OddsChangeModal from "../betslip/OddsChangeModal";

interface MoreWagersProps {
  refetchProfile: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

interface MoreWagersParams {
  eventId: string;
}

type ShowMoreLines = Record<string, boolean>;

export default function MoreWagers(props: MoreWagersProps) {
  const { eventId } = useParams<MoreWagersParams>();
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const [bets, setBets] = useState<Bet[]>([]);
  const [showMoreLines, setShowMoreLines] = useState<ShowMoreLines>({});
  const [sportFilters, setSportFilters] = useState(0);
  const [oddsChangeData, setOddsChangeData] = useState<OddsChangeData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const history = useHistory();

  // Get current selected bets
  var betArr: [];
  var stringBets = window.localStorage.getItem("bets");
  stringBets !== null ? (betArr = JSON.parse(stringBets!)) : (betArr = []);

  const toggleShowMore = (groupIndex: string) => {
    setShowMoreLines((prevShowMoreLines: ShowMoreLines) => ({
      ...prevShowMoreLines,
      [groupIndex]: !prevShowMoreLines[groupIndex],
    }));
  };

  const toast = useToast();

  const getEventsData = useQuery(["getEvents", eventId], () => {
    return API.getEvent(eventId);
  });

  const getSportsData = useQuery("getSports", () => {
    return API.getSports();
  });

  useEffect(() => {
    setBets(betArr);
  }, [eventId]);

  const { mutate: placeBet } = useMutation(API.placeBet, {
    onSuccess: (data) => {
      setBets([]);

      props.refetchProfile();

      toast({
        title: `Bet successfully placed!`,
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
    onError: (error: any) => {
      if (error.response.status == "409") {
        setOddsChangeData(error.response.data);
        onOpen();
      }

      toast({
        title: `Error placing bet`,
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
  });

  var sportName = "";
  var sportId = 0;
  var eventOverride: any = "";
  var bettingLines: JSX.Element[] = [];
  var sports: JSX.Element[] = [];
  var groupedBettingLines: JSX.Element[] = [];
  var moreWagersBettingLines: BettingLineGroupDTO[] = [];

  if (getSportsData.status === "success") {
    sports = getSportsData.data.map((sport: any) => {
      var selected = false;
      if (sportFilters === sport.id) {
        selected = true;
      }

      return (
        <Button
          key={sport.id}
          variant="ghost"
          color={selected ? "white" : "darkgray"}
          _hover={{ color: "white" }}
          m="1"
          onClick={() => {
            setSportFilters(sport.id);
            history.push(`/sports/${sport.id}`);
          }}
        >
          {sport.name}
        </Button>
      );
    });
  }

  if (getEventsData.status === "success") {
    console.log(getEventsData.data);
    if (getEventsData.data.sport) {
      sportName = getEventsData.data.sport.name;
      sportId = getEventsData.data.sport.id;
    }
    bettingLines = getEventsData.data.bettingLineGroups.map(
      (line: BettingLineGroupDTO) => {
        var itr = 0;
        const groupName = line.group;
        // Add Featured lines to top betting card
        if (line.group === "Featured") {
          eventOverride = {
            ...getEventsData.data,
            bettingLines: line.lines.map((bl: any) => {
              return {
                ...bl,
                odds: bl.oddsOverride != null ? bl.oddsOverride : bl.odds,
              };
            }),
          };
        }

        groupedBettingLines = line.lines.map(
          (line: BettingLineDTO, index: number) => {
            ++itr;

            // Omit lines after 18 if group is not included in showMoreLines
            if (!showMoreLines[groupName] && index >= 18) {
              return <></>;
            }

            let selected = false;
            if (bets.some((e) => e.bettingLineId === line.id ?? "")) {
              selected = true;
            } else {
              selected = false;
            }
            return (
              <HStack justifyContent="space-between">
                <SimpleGrid
                  columns={2}
                  justifyContent="space-between"
                  display="flex"
                  w="95%"
                >
                  <Box alignItems="center" display="inherit" w="85%">
                    <Text>{line.name}</Text>
                  </Box>
                  <Box
                    background={selected ? orgPrimaryColor : "#FFFFFF"}
                    borderRadius="md"
                    height="45px"
                    width="75px"
                    my={1}
                    cursor="pointer"
                    borderWidth="1px"
                    _hover={selected ? {} : { background: "#aafa9d" }}
                    borderColor={orgPrimaryColor}
                    fontWeight="bold"
                    alignItems="center"
                    display="inherit"
                    onClick={() => {
                      if (bets.some((e) => e.bettingLineId === line.id)) {
                        var newBets = bets;
                        const index = bets.findIndex((betItem) => {
                          return betItem.bettingLineId === line.id;
                        });

                        if (index > -1) {
                          newBets.splice(index, 1);
                        }

                        setBets([...newBets]);
                        const betString = JSON.stringify(bets);
                        window.localStorage.setItem("bets", betString);
                        return;
                      }

                      let bet: Bet = {
                        event: getEventsData.data,
                        riskAmount: 0,
                        winAmount: 0,
                        bettingLineId: line.id,
                        externalId: line.externalId,
                        bettingType: SportBettingTypes.spread,
                        category: line.name,
                        odds: line.odds,
                        participant: line.paramParticipant1,
                        sport: getEventsData.data.sport.name,
                      };
                      newBets = bets;

                      newBets.push(bet);
                      setBets([...newBets]);
                      const betString = JSON.stringify(bets);
                      window.localStorage.setItem("bets", betString);

                      /** Display Error Toast if bet slip is greater than 25 */
                      if (bets.length > 25) {
                        toast({
                          title: `Error`,
                          description: "Only 25 Bets Allowed On A Ticket",
                          position: "bottom-right",
                          status: "error",
                          isClosable: true,
                        });
                      }
                    }}
                  >
                    <Text color={selected ? "white" : orgPrimaryColor} m="auto">
                      {`${
                        line.odds >= 2 && oddsDisplay === "American" ? "+" : ""
                      }${
                        oddsDisplay === "American"
                          ? metricToAmericanOdds(line.odds).toFixed(0)
                          : line.odds
                      }`}
                    </Text>
                  </Box>
                </SimpleGrid>
                {itr % 3 !== 0 && (
                  <Flex h="100%" w="5%" pl={5}>
                    <Divider bgColor="black" orientation="vertical" />
                  </Flex>
                )}
              </HStack>
            );
          }
        );

        if (line.group !== "Featured" && line.lines.length > 1) {
          moreWagersBettingLines.push(line);
          return (
            <AccordionItem bg="#F6F6F6" m="10px">
              <h2>
                <AccordionButton
                  _expanded={{ bg: orgSecondaryColor, color: "white" }}
                  color="black"
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="semibold"
                  >
                    {line.group}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                color="black"
                display="flex"
                justifyContent="center"
              >
                <Stack w="98%">
                  <SimpleGrid columns={3} columnGap={8} rowGap={0}>
                    {groupedBettingLines}
                  </SimpleGrid>
                  <Center>
                    <Button
                      variant="ghost"
                      color={orgPrimaryColor}
                      onClick={() => toggleShowMore(line.group)}
                    >
                      {showMoreLines[line.group] ? "Show Less" : "Show More"}
                    </Button>
                  </Center>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          );
        } else {
          return <></>;
        }
      }
    );
  }

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Box
          pb="0"
          h="100vh"
          style={{ position: "relative", overflowY: "auto" }}
          width={{ base: "100%", lg: "calc(200% - 335px)" }}
        >
          <Box minH="100vh">
            <Box p={2} w="100%">
              <AdCarousel bets={bets} setBets={setBets} />
            </Box>
            {getEventsData.data && (
              <Flex pl="4" alignItems="center" h="5rem" hideBelow="sm">
                <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                  {getEventsData.data.name}
                </Text>
              </Flex>
            )}

            {sports.length !== 0 && (
              <Box
                p="4"
                h="43px"
                w="100%"
                display="flex"
                alignItems="center"
                background={`${orgSecondaryColor} 0% 0% no-repeat padding-box`}
                boxShadow="inset 0px 4px 4px #00000029, 0px 4px 4px #00000029"
                overflowX="auto"
                overflowY="hidden"
              >
                {getSportsData.status === "success" && (
                  <HStack ml="2">{sports}</HStack>
                )}
              </Box>
            )}

            {getEventsData.status === "loading" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30%",
                  height: "100%",
                }}
              >
                <BBSpinner />
              </div>
            )}

            {getEventsData.status === "success" && (
              <Box>
                <Box
                  background={`${orgSecondaryColor}30 0% 0% no-repeat padding-box`}
                  boxShadow="inset 0px 4px 4px #00000029"
                  p={1}
                >
                  {sportName !== "" && sportId !== 0 ? (
                    <Button
                      leftIcon={<FaArrowLeft style={{ marginTop: "2px" }} />}
                      colorScheme="gray"
                      variant="ghost"
                      borderRadius="none"
                      onClick={() => history.push(`/sports/${sportId}`)}
                    >
                      {strings[language].sportsbook.backToSport + sportName}
                    </Button>
                  ) : (
                    <Button
                      leftIcon={<FaArrowLeft style={{ marginTop: "2px" }} />}
                      colorScheme="gray"
                      variant="ghost"
                      borderRadius="none"
                      onClick={() => history.push(`/`)}
                    >
                      {strings[language].sportsbook.backToEvents}
                    </Button>
                  )}
                </Box>
                <Box
                  h="68px"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  background={`${orgSecondaryColor}30 0% 0% no-repeat padding-box`}
                  boxShadow="inset 0px 0px 4px #00000029"
                >
                  <Text color="#000000" fontWeight="bold" ml="40px">
                    {strings[language].sportsbook.gameLines}
                  </Text>
                </Box>
              </Box>
            )}
            {getEventsData.data && (
              <Box
                borderRadius="0px 0px 5px 5px"
                maxW="100%"
                position="relative"
                overflowY="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Box bg="white">
                  <Box>
                    <Event
                      key={getEventsData.data.id}
                      bets={bets}
                      event={eventOverride}
                      sport={getEventsData.data.sport}
                      moreWagersVisible={false}
                      clickHandler={(
                        bettingLineId: string,
                        externalId: string,
                        odds: number,
                        betType: SportBettingTypes,
                        category: string,
                        participant: ParticipantDTO
                      ) => {
                        if (
                          bets.some((e) => e.bettingLineId === bettingLineId)
                        ) {
                          var newBets = bets;
                          const index = bets.findIndex((betItem) => {
                            return betItem.bettingLineId === bettingLineId;
                          });

                          if (index > -1) {
                            newBets.splice(index, 1);
                          }

                          setBets([...newBets]);
                          const betString = JSON.stringify(bets);
                          window.localStorage.setItem("bets", betString);
                          return;
                        }

                        let bet: Bet = {
                          event: getEventsData.data,
                          riskAmount: 0,
                          winAmount: 0,
                          bettingLineId: bettingLineId,
                          externalId: externalId,
                          bettingType: betType,
                          category: category,
                          odds: odds,
                          participant: participant,
                          sport: getEventsData.data.sport.name,
                        };
                        newBets = bets;

                        newBets.push(bet);
                        setBets([...newBets]);
                        const betString = JSON.stringify(bets);
                        window.localStorage.setItem("bets", betString);

                        /** Display Error Toast if bet slip is greater than 25 */
                        if (bets.length > 25) {
                          toast({
                            title: `Error`,
                            description: "Only 25 Bets Allowed On A Ticket",
                            position: "bottom-right",
                            status: "error",
                            isClosable: true,
                          });
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box>
              <Accordion allowToggle allowMultiple>
                {bettingLines}
              </Accordion>
            </Box>
            {moreWagersBettingLines.length === 0 && (
              <Center p={6}>
                <Text color="#646879" fontWeight="semibold">
                  {strings[language].sportsbook.noAdditionalWagers}
                </Text>
              </Center>
            )}
          </Box>
          <Box mb="60px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          left="100%"
          zIndex={1}
          hideBelow="lg"
        >
          <BetSlip
            placeBetLoading={placeBetLoading}
            setPlaceBetLoading={setPlaceBetLoading}
            bets={bets}
            setBets={setBets}
            placeBet={placeBet}
          />
        </Box>
      </SimpleGrid>
      <MobileBetSlip
        placeBetLoading={placeBetLoading}
        setPlaceBetLoading={setPlaceBetLoading}
        bets={bets}
        setBets={setBets}
        placeBet={placeBet}
        collapseSlip={props.collapseSlip}
        setCollapseSlip={props.setCollapseSlip}
      />
      {oddsChangeData && (
        <OddsChangeModal
          isOpen={isOpen}
          onClose={onClose}
          oddsChangeData={oddsChangeData}
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
        />
      )}
    </Box>
  );
}
