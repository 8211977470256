import {
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CM, CA, US } from "country-flag-icons/react/3x2";
import { VerifyUserRequest } from "models";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FaBolt,
  FaChevronDown,
  FaClipboard,
  FaFile,
  FaFileAlt,
  FaMobileAlt,
  FaRegEnvelope,
  FaRegFile,
} from "react-icons/fa";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import API from "services/API";
import {
  languageState,
  orgPrimaryColorState,
  orgSecondaryColorState,
  userState,
} from "store/Store";
import ReCaptcha from "./ReCaptcha";
import { useHistory } from "react-router-dom";
import { PasswordField } from "./PasswordField";
import { strings, LanguageKey } from "utils/languageStrings";

interface RegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  isRegistered?: boolean;
  setIsRegistered?: Dispatch<SetStateAction<boolean>>;
}

export const RegisterModal = (props: RegisterModalProps) => {
  const user = useRecoilValue(userState);
  const toast = useToast();
  const {
    isOpen: isOneClickOpen,
    onOpen: onOneClickOpen,
    onClose: onOneClickClose,
  } = useDisclosure();
  const {
    isOpen: isEmailOpen,
    onOpen: onEmailOpen,
    onClose: onEmailClose,
  } = useDisclosure();
  const {
    isOpen: isPhoneOpen,
    onOpen: onPhoneOpen,
    onClose: onPhoneClose,
  } = useDisclosure();
  const {
    isOpen: isVerifyUserOpen,
    onOpen: onVerifyUserOpen,
    onClose: onVerifyUserClose,
  } = useDisclosure();
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const [oneClickId, setOneClickId] = useState("");
  const [oneClickPassword, setOneClickPassword] = useState("");
  const [countryCode, setCountryCode] = useState("+237");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [countryCodeIconSelector, setCountryCodeIconSelector] = useState("CM");
  const [country, setCountry] = useState("CM");
  const [countryLong, setCountryLong] = useState("Cameroon");
  const [registrationMethod, setRegistrationMethod] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>("");
  const [recaptchaSuccessful, setRecaptchaSuccessful] = useState(false);
  const [recaptchaEnabled, setRecaptchaEnabled] = useState(false);

  const history = useHistory();

  const { register, handleSubmit } = useForm<VerifyUserRequest>({
    mode: "onChange",
  });

  useEffect(() => {
    parseInt(process.env.REACT_APP_RECAPTCHA_ENABLED!) === 1
      ? setRecaptchaEnabled(true)
      : setRecaptchaEnabled(false);
  }, []);

  var countryIcon: JSX.Element = <CM width="20px" height="30px" />;
  var countryCodeIcon: JSX.Element = <CM width="20px" height="30px" />;

  /* Country selectors */

  switch (country) {
    case "CM":
      countryIcon = <CM width="20px" height="30px" />;
      break;
    case "CA":
      countryIcon = <CA width="20px" height="30px" />;
      break;
    case "US":
      countryIcon = <US width="20px" height="30px" />;
      break;

    default:
      countryIcon = <CM width="20px" height="30px" />;
      break;
  }

  switch (countryCodeIconSelector) {
    case "CM":
      countryCodeIcon = <CM width="20px" height="30px" />;
      break;
    case "CA":
      countryCodeIcon = <CA width="20px" height="30px" />;
      break;
    case "US":
      countryCodeIcon = <US width="20px" height="30px" />;
      break;

    default:
      countryCodeIcon = <CM width="20px" height="30px" />;
      break;
  }

  /* MUTATIONS */

  const { mutate: mutateRegisterOneClick, isLoading: isLoadingOneClick } =
    useMutation(API.registerOneClick, {
      onSuccess: (data) => {
        console.log(data);

        window.localStorage.loggedIn = "true";
        window.localStorage.jwt = data.access_token;

        setOneClickId(data.id);
        setOneClickPassword(data.password);

        props.onClose();
        onOneClickOpen();
      },
      onError: () => {
        toast({
          title: `Error registering user`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      },
    });

  const { mutate: mutateRegisterUser, isLoading: isLoadingRegister } =
    useMutation(API.registerUser, {
      onSuccess: (data) => {
        props.onClose();

        if (registrationMethod === "Email") {
          onEmailOpen();
        } else if (registrationMethod === "Phone") {
          onPhoneOpen();
        }
      },
      onError: () => {
        toast({
          title: `Error registering user`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      },
    });

  const { mutate: mutateVerifyUser, isLoading: isLoadingVerifyUser } =
    useMutation(API.verifyUser, {
      onSuccess: (data) => {
        if (registrationMethod === "Email") {
          onEmailClose();
        } else if (registrationMethod === "Phone") {
          onPhoneClose();
        }

        window.localStorage.loggedIn = "true";
        window.localStorage.jwt = data;

        if (props.setIsRegistered) {
          props.setIsRegistered(true);
        } else {
          onVerifyUserOpen();
        }
      },
      onError: () => {
        toast({
          title: `Error registering user`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      },
    });

  const onSubmitEmail = (data: VerifyUserRequest) => {
    const userVerify = {
      email: data.email,
      MFAToken: data.MFAToken,
    };

    mutateVerifyUser(userVerify);
  };

  const onSubmitPhone = (data: VerifyUserRequest) => {
    const userVerify = {
      phoneNo: countryCode + data.phoneNo,
      MFAToken: data.MFAToken,
    };

    mutateVerifyUser(userVerify);
  };

  const copyOneClickRegisterInfo = () => {
    navigator.clipboard.writeText(
      `${strings[language].signupSuccess.userId}: ${oneClickId}\n${strings[language].login.password}: ${oneClickPassword}`
    );

    toast({
      title: strings[language].signupSuccess.copiedClipboard,
      position: "bottom-right",
      status: "success",
      isClosable: true,
    });
  };

  const saveOneClickRegisterInfo = () => {
    const link = document.createElement("a");
    const file = new Blob(
      [
        `${strings[language].signupSuccess.userId}: ${oneClickId}\n${strings[language].login.password}: ${oneClickPassword}`,
      ],
      { type: "text/plain" }
    );
    link.href = URL.createObjectURL(file);
    link.download = "RegistrationInfo.txt";
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        size="xl"
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings[language].navbar.register}</ModalHeader>
          <ModalCloseButton onClick={() => history.push("/")} />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab
                  _selected={{
                    color: orgSecondaryColor,
                    borderColor: orgSecondaryColor,
                  }}
                  _focus={{ boxShadow: "none" }}
                >
                  <HStack>
                    <FaBolt />
                    <Text>One-Click</Text>
                  </HStack>
                </Tab>
                <Tab
                  _selected={{
                    color: orgSecondaryColor,
                    borderColor: orgSecondaryColor,
                  }}
                  _focus={{ boxShadow: "none" }}
                  onFocus={() => setRegistrationMethod("Email")}
                >
                  <HStack>
                    <FaRegEnvelope />
                    <Text>By Email</Text>
                  </HStack>
                </Tab>
                <Tab
                  _selected={{
                    color: orgSecondaryColor,
                    borderColor: orgSecondaryColor,
                  }}
                  onFocus={() => setRegistrationMethod("Phone")}
                  _focus={{ boxShadow: "none" }}
                >
                  <HStack>
                    <FaMobileAlt />
                    <Text>By Phone</Text>
                  </HStack>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack spacing={4}>
                    <FormControl>
                      <FormLabel>{strings[language].signup.country}</FormLabel>
                      <Menu>
                        <MenuButton
                          w="100%"
                          as={Button}
                          rightIcon={<FaChevronDown />}
                          leftIcon={<CM width="20px" height="30px" />}
                        >
                          Cameroon
                        </MenuButton>
                        <MenuList>
                          <MenuItem>
                            <CM width="20px" height="30px" />{" "}
                            <Text ml="10px">Cameroon</Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </FormControl>

                    <Button
                      background={orgPrimaryColor}
                      color="white"
                      isLoading={isLoadingOneClick}
                      isDisabled={!recaptchaSuccessful && recaptchaEnabled}
                      onClick={() => {
                        if (recaptchaEnabled) {
                          mutateRegisterOneClick({
                            method: "OneClick",
                            orgId: user.org.id,
                            recaptcha_token: recaptchaToken,
                          });
                        } else {
                          mutateRegisterOneClick({
                            method: "OneClick",
                            orgId: user.org.id,
                          });
                        }
                      }}
                    >
                      {strings[language].navbar.register}
                    </Button>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack spacing={4}>
                    <FormControl>
                      <FormLabel>{strings[language].signup.country}</FormLabel>
                      <Menu>
                        <MenuButton
                          w="100%"
                          as={Button}
                          rightIcon={<FaChevronDown />}
                          leftIcon={countryIcon}
                        >
                          {countryLong}
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setCountry("CM");
                              setCountryLong("Cameroon");
                            }}
                          >
                            <CM width="20px" height="30px" />{" "}
                            <Text ml="10px">Cameroon</Text>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCountry("CA");
                              setCountryLong("Canada");
                            }}
                          >
                            <CA width="20px" height="30px" />{" "}
                            <Text ml="10px">Canada</Text>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCountry("US");
                              setCountryLong("United States");
                            }}
                          >
                            <US width="20px" height="30px" />{" "}
                            <Text ml="10px">United States</Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </FormControl>

                    <FormControl>
                      <FormLabel>{strings[language].signup.email}</FormLabel>
                      <Input
                        type="email"
                        value={email}
                        placeholder="Email"
                        _placeholder={{ opacity: 1, color: "gray.500" }}
                        h="53px"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>{strings[language].login.password}</FormLabel>
                      <PasswordField
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        width="100%"
                        bg="white"
                      />
                      <Text as="sub" color="#575757">
                        {strings[language].signup.passwordError}
                      </Text>
                    </FormControl>

                    <Button
                      background={orgPrimaryColor}
                      color="white"
                      isLoading={isLoadingRegister}
                      isDisabled={
                        password.length < 8 ||
                        email === "" ||
                        (!recaptchaSuccessful && recaptchaEnabled)
                      }
                      onClick={() => {
                        if (recaptchaEnabled) {
                          mutateRegisterUser({
                            country,
                            method: registrationMethod,
                            email,
                            password,
                            orgId: user.org.id,
                            recaptcha_token: recaptchaToken,
                          });
                        } else {
                          mutateRegisterUser({
                            country,
                            method: registrationMethod,
                            email,
                            password,
                            orgId: user.org.id,
                          });
                        }
                      }}
                    >
                      {strings[language].navbar.register}
                    </Button>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Stack spacing={4}>
                    <FormControl>
                      <FormLabel>{strings[language].signup.country}</FormLabel>
                      <Menu>
                        <MenuButton
                          w="100%"
                          as={Button}
                          rightIcon={<FaChevronDown />}
                          leftIcon={countryIcon}
                        >
                          {countryLong}
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setCountry("CM");
                              setCountryLong("Cameroon");
                            }}
                          >
                            <CM width="20px" height="30px" />{" "}
                            <Text ml="10px">Cameroon</Text>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCountry("CA");
                              setCountryLong("Canada");
                            }}
                          >
                            <CA width="20px" height="30px" />{" "}
                            <Text ml="10px">Canada</Text>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCountry("US");
                              setCountryLong("United States");
                            }}
                          >
                            <US width="20px" height="30px" />{" "}
                            <Text ml="10px">United States</Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </FormControl>

                    <FormControl>
                      <FormLabel>
                        {strings[language].profile.phoneNumber}
                      </FormLabel>
                      <Flex>
                        <Menu>
                          <MenuButton
                            as={Button}
                            rightIcon={<FaChevronDown />}
                            h="53px"
                            backgroundColor="#FAFAFA"
                            borderEndRadius="none"
                            outline="1px solid lightgray"
                          >
                            <Text mr={2}>{countryCodeIcon}</Text>
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                setCountryCode("+1");
                                setCountryCodeIconSelector("US");
                              }}
                            >
                              <US width="20px" height="30px" />
                              <Text mx={2}>United States (+1)</Text>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setCountryCode("+1");
                                setCountryCodeIconSelector("CA");
                              }}
                            >
                              <CA width="20px" height="30px" />
                              <Text mx={2}>Canada (+1)</Text>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setCountryCode("+237");
                                setCountryCodeIconSelector("CM");
                              }}
                            >
                              <CM width="20px" height="30px" />
                              <Text mx={2}>Cameroon (+237)</Text>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                        <InputGroup>
                          <InputLeftElement
                            color="black"
                            h="53px"
                            children={countryCode}
                          />
                          <Input
                            type="tel"
                            borderStartRadius="none"
                            h="53px"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </InputGroup>
                      </Flex>
                    </FormControl>

                    <FormControl>
                      <FormLabel>{strings[language].login.password}</FormLabel>
                      <PasswordField
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        width="100%"
                        bg="white"
                      />
                      <Text as="sub" color="#575757">
                        {strings[language].signup.passwordError}
                      </Text>
                    </FormControl>

                    <Button
                      background={orgPrimaryColor}
                      color="white"
                      isLoading={isLoadingRegister}
                      isDisabled={
                        password.length < 8 ||
                        phone === "" ||
                        (!recaptchaSuccessful && recaptchaEnabled)
                      }
                      onClick={() => {
                        if (recaptchaEnabled) {
                          mutateRegisterUser({
                            country,
                            method: registrationMethod,
                            phoneNo: countryCode + phone,
                            password,
                            orgId: user.org.id,
                            recaptcha_token: recaptchaToken,
                          });
                        } else {
                          mutateRegisterUser({
                            country,
                            method: registrationMethod,
                            phoneNo: countryCode + phone,
                            password,
                            orgId: user.org.id,
                          });
                        }
                      }}
                    >
                      {strings[language].navbar.register}
                    </Button>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          {recaptchaEnabled && (
            <ModalFooter justifyContent="center" pb={6}>
              <ReCaptcha
                recaptchaToken={recaptchaToken}
                setRecaptchaToken={setRecaptchaToken}
                recaptchaSuccessful={recaptchaSuccessful}
                setRecaptchaSuccessful={setRecaptchaSuccessful}
              />
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>

      {/* ONE CLICK MODAL */}

      <Modal
        isOpen={isOneClickOpen}
        onClose={onOneClickClose}
        size="xl"
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings[language].signupSuccess.success}</ModalHeader>
          <ModalCloseButton onClick={() => history.push("/")} />
          <ModalBody p="6">
            <Stack spacing={6}>
              <Card variant="outline">
                <CardBody>
                  <Stack spacing={4}>
                    <Text fontWeight="bold">
                      {strings[language].signupSuccess.saveDetails}
                    </Text>
                    <SimpleGrid columns={[1, null, 2]} spacing={4}>
                      <FormControl>
                        <FormLabel>
                          {strings[language].signupSuccess.userId}
                        </FormLabel>
                        <Input value={oneClickId} isReadOnly />
                      </FormControl>
                      <FormControl>
                        <FormLabel>
                          {strings[language].login.password}
                        </FormLabel>
                        <Input value={oneClickPassword} isReadOnly />
                      </FormControl>
                    </SimpleGrid>

                    <Button
                      onClick={() => {
                        copyOneClickRegisterInfo();
                      }}
                      leftIcon={<FaClipboard />}
                    >
                      {strings[language].signupSuccess.copyClipboard}
                    </Button>
                    <Button
                      leftIcon={<FaRegFile />}
                      onClick={() => {
                        saveOneClickRegisterInfo();
                      }}
                    >
                      {strings[language].signupSuccess.saveFile}
                    </Button>
                  </Stack>
                </CardBody>
              </Card>
              <Button
                onClick={() => {
                  onOneClickClose();
                  history.push("/");
                }}
                background={orgPrimaryColor}
                color="white"
              >
                {strings[language].signupSuccess.continue}
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* VERIFY EMAIL MODAL */}

      <Modal
        isOpen={isEmailOpen}
        onClose={onEmailClose}
        size="xl"
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings[language].signup.verifyEmail}</ModalHeader>
          <ModalCloseButton onClick={() => history.push("/")} />
          <ModalBody p="6">
            <Stack spacing={6}>
              <form onSubmit={handleSubmit(onSubmitEmail)}>
                <Card variant="outline">
                  <CardBody>
                    <Stack spacing={4}>
                      <Text fontWeight="bold">
                        {strings[language].signup.emailMessage}
                      </Text>
                      <SimpleGrid columns={[1, null, 2]} spacing={4}>
                        <FormControl>
                          <FormLabel>
                            {strings[language].signup.email}
                          </FormLabel>
                          <Input
                            value={email}
                            {...register("email")}
                            isReadOnly
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            {strings[language].signup.oneTimePassword}
                          </FormLabel>
                          <Input
                            type="number"
                            required
                            {...register("MFAToken")}
                          />
                        </FormControl>
                      </SimpleGrid>
                      <Button
                        type="submit"
                        isLoading={isLoadingVerifyUser}
                        background={orgPrimaryColor}
                        color="white"
                      >
                        {strings[language].signup.completeRegistration}
                      </Button>
                    </Stack>
                  </CardBody>
                </Card>
              </form>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* VERIFY PHONE MODAL */}

      <Modal
        isOpen={isPhoneOpen}
        onClose={onPhoneClose}
        size="xl"
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings[language].signup.verifyPhone}</ModalHeader>
          <ModalCloseButton onClick={() => history.push("/")} />
          <ModalBody p="6">
            <Stack spacing={6}>
              <form onSubmit={handleSubmit(onSubmitPhone)}>
                <Card variant="outline">
                  <CardBody>
                    <Stack spacing={4}>
                      <Text fontWeight="bold">
                        {strings[language].signup.phoneMessage}
                      </Text>
                      <SimpleGrid columns={[1, null, 2]} spacing={4}>
                        <FormControl>
                          <FormLabel>
                            {strings[language].profile.phoneNumber}
                          </FormLabel>
                          <Input
                            value={phone}
                            {...register("phoneNo")}
                            isReadOnly
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            {strings[language].signup.oneTimePassword}
                          </FormLabel>
                          <Input
                            type="number"
                            required
                            {...register("MFAToken")}
                          />
                        </FormControl>
                      </SimpleGrid>
                      <Button
                        type="submit"
                        isLoading={isLoadingVerifyUser}
                        background={orgPrimaryColor}
                        color="white"
                      >
                        {strings[language].signup.completeRegistration}
                      </Button>
                    </Stack>
                  </CardBody>
                </Card>
              </form>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* USER VERIFIED MODAL */}

      <Modal
        isOpen={isVerifyUserOpen}
        onClose={onVerifyUserClose}
        size="xl"
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings[language].signupSuccess.success}</ModalHeader>
          <ModalCloseButton onClick={() => history.push("/")} />
          <ModalBody p="6" m="auto">
            <Button
              onClick={() => {
                onVerifyUserClose();
                history.push("/");
              }}
              background={orgPrimaryColor}
              color="white"
            >
              {strings[language].signupSuccess.continue}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
