import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  InputGroup,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { PlaceBetTicketDTO } from "models/Bets";
import { BettingLineDTO } from "models/BettingLine";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { FiX } from "react-icons/fi";
import { UseMutateFunction } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  fantasyBetsStore,
  orgPrimaryColorState,
  orgSecondaryColorState,
} from "store/Store";
import { calculateFantasyMultiplier } from "utils/fantasyCalcs";
import ConfirmFantasyBetModal from "./ConfirmFantasyBetModal";
import { sqlTimestampToTimestampStrLong } from "utils/dateCalcs";

interface FantasyBetSlipProps {
  placeBetLoading?: boolean;
  setPlaceBetLoading?: Dispatch<SetStateAction<boolean>>;
  placeBet?: UseMutateFunction<any, unknown, PlaceBetTicketDTO, unknown>;
}

export const FantasyBetSlip = (props: FantasyBetSlipProps) => {
  const cancelRef: any = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fantasyRisk, setFantasyRisk] = useState<string>("");
  const [fantasyWin, setFantasyWin] = useState<string>("");
  const [fantasyMultiplier, setFantasyMultiplier] = useState<string>("");
  const [fantasyBets, setFantasyBets] = useRecoilState(fantasyBetsStore);
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);

  var betConfirm: JSX.Element[] = [];

  const removeFantasyBet = (bet: BettingLineDTO) => {
    setFantasyBets(fantasyBets.filter((x) => x.id !== bet.id));
  };

  const toggleFantasyOver = (fantasyBet: BettingLineDTO, value: boolean) => {
    const updatedBets = fantasyBets.map((bet) =>
      bet.id === fantasyBet.id ? { ...bet, fantasyOver: value } : bet
    );
    setFantasyBets(updatedBets);
    console.log(fantasyBets);
  };

  const handleMoreLessClick = (fantasyBet: BettingLineDTO, isMore: boolean) => {
    const value = isMore ? true : false;
    toggleFantasyOver(fantasyBet, value);
  };

  const calculateFantasyWin = (
    fantasyRisk: number,
    numberOfBets: number
  ): void => {
    let winMultiplier = 0;

    switch (numberOfBets) {
      case 2:
        winMultiplier = 3;
        break;
      case 3:
        winMultiplier = 5;
        break;
      case 4:
        winMultiplier = 10;
        break;
      case 5:
        winMultiplier = 15;
        break;
      case 6:
        winMultiplier = 25;
        break;
      default:
        winMultiplier = 1;
    }

    const winAmount = fantasyRisk * winMultiplier;
    setFantasyWin(winAmount.toString());
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    let fantasyMult = calculateFantasyMultiplier(fantasyBets.length);
    setFantasyMultiplier(fantasyMult);
    calculateFantasyWin(+fantasyRisk, fantasyBets.length);
  }, [fantasyBets.length, fantasyRisk]);

  betConfirm = fantasyBets.map((bet) => {
    return (
      <Tr key={bet.id}>
        <Td>
          <Box>
            <Text color="#000000">{bet.name}</Text>
            <Text fontSize="12" color="gray">
              {capitalizeFirstLetter(bet.category)}
            </Text>
          </Box>
        </Td>
        <Td>
          <Text>{bet.paramFloat1.toFixed(1)}</Text>
        </Td>
        <Td>
          {bet.fantasyOver ? (
            <Text color="green">More</Text>
          ) : (
            <Text color="red">Less</Text>
          )}
        </Td>
      </Tr>
    );
  });

  return (
    <Box
      style={{
        width: "100%",
        boxShadow: "0px 3px 6px #00000029",
      }}
      height={{ md: "97vh", "2xl": "100vh" }}
      background="#FFFFFF"
      maxW="min(536px, 100%)"
      position="sticky"
      left={{ base: "10%", sm: "25%", lg: "calc(100% - 200px)" }}
    >
      <Flex background="#646879" p="5" h="16">
        <Text color="#fff" fontSize="lg" fontWeight="bold">
          {`${fantasyBets.length} Players Selected`}
        </Text>
        <Spacer />
      </Flex>

      <Box
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div>
          <Box
            style={{ overflowY: "auto" }}
            position="relative"
            maxHeight={"calc(100vh - 280px)"}
            height="100%"
          >
            {fantasyBets.map((fantasyBet) => {
              return (
                <>
                  <Box pb="10px" pt="0px" px="10px">
                    <Stack spacing={0}>
                      <HStack>
                        <Spacer />
                        <IconButton
                          aria-label=""
                          icon={<FiX />}
                          variant="ghost"
                          onClick={() => {
                            removeFantasyBet(fantasyBet);
                          }}
                        />
                      </HStack>
                      <HStack>
                        <Image
                          src={fantasyBet.paramParticipant1.imageUrl}
                          fallbackSrc="https://cdn.nba.com/headshots/nba/latest/260x190/1628966.png"
                          w="60px"
                        />
                        <Stack spacing={0}>
                          <Text fontWeight="bold">
                            {fantasyBet.paramParticipant1.name}
                          </Text>
                          <HStack>
                            <Badge>NBA</Badge>
                            <Text>
                              {fantasyBet.paramParticipant1.abbreviation} -{" "}
                              {fantasyBet.paramParticipant1.position}
                            </Text>
                          </HStack>
                          {fantasyBet.startTime && (
                            <Text>
                              {sqlTimestampToTimestampStrLong(
                                fantasyBet.startTime
                              )}
                            </Text>
                          )}
                          <Text>vs {fantasyBet.awayTeam}</Text>
                          <Box
                            color="white"
                            bg={orgSecondaryColor}
                            p="2"
                            borderRadius="10px"
                            fontSize="14px"
                          >
                            <HStack spacing="10px">
                              <Spacer />
                              <Text>{fantasyBet.paramFloat1.toFixed(1)}</Text>
                              <Text color={orgPrimaryColor}>|</Text>
                              <Text>{fantasyBet.category}</Text>
                              <Spacer />
                            </HStack>
                          </Box>
                        </Stack>
                        <Spacer />
                        <Stack
                          spacing="0px"
                          textAlign="center"
                          fontWeight="bold"
                          color="white"
                        >
                          <Box
                            borderTopRadius="10px"
                            bg={fantasyBet.fantasyOver ? "green" : "gray"}
                            p="10px"
                            cursor="pointer"
                            onClick={() =>
                              handleMoreLessClick(fantasyBet, true)
                            }
                          >
                            <Text>MORE</Text>
                          </Box>
                          <Divider />
                          <Box
                            borderBottomRadius="10px"
                            bg={!fantasyBet.fantasyOver ? "red" : "gray"}
                            p="10px"
                            cursor="pointer"
                            onClick={() =>
                              handleMoreLessClick(fantasyBet, false)
                            }
                          >
                            <Text>LESS</Text>
                          </Box>
                        </Stack>
                      </HStack>
                    </Stack>
                  </Box>
                  <Divider />
                </>
              );
            })}
          </Box>
          <Box position="absolute" bottom="8vh" w="100%" bg="#FFFFFF">
            {fantasyBets.length === 1 && (
              <Box w="100%" bg="red.400" mb="26px" p="2">
                <Text textAlign="center" fontWeight="bold" fontSize="18px">
                  You must select at least one more Player Prop to proceed
                </Text>
              </Box>
            )}

            {fantasyBets.length > 6 && (
              <Box w="100%" bg="red.400" mb="26px" p="2">
                <Text textAlign="center" fontWeight="bold" fontSize="18px">
                  Maximum of 6 players for each entry
                </Text>
              </Box>
            )}

            {fantasyBets.length > 1 && fantasyBets.length < 7 && (
              <Stack>
                <Box
                  background="#FFFFFF"
                  borderTop="1px solid #00000029"
                  borderBottom="1px solid #00000029"
                >
                  <Flex
                    align="center"
                    bg={
                      fantasyRisk === "" || fantasyRisk === "0"
                        ? "#F6F6F6"
                        : orgPrimaryColor
                    }
                    h="10"
                  >
                    <Flex pl="4">
                      <Text
                        fontSize="md"
                        color={
                          fantasyRisk === "" || fantasyRisk === "0"
                            ? "#000000"
                            : "#FFFFFF"
                        }
                        fontWeight="bold"
                      >
                        {fantasyBets.length} Correct
                      </Text>
                      <Text
                        fontSize="md"
                        fontWeight="bold"
                        color={
                          fantasyRisk === "" || fantasyRisk === "0"
                            ? orgPrimaryColor
                            : "#F6F6F6"
                        }
                        pl="2"
                        position="absolute"
                        right="6"
                      >
                        X {fantasyMultiplier}
                      </Text>
                    </Flex>
                  </Flex>
                  <Box pb="2">
                    <SimpleGrid columns={2} py="2" px="4">
                      <Box pr="2">
                        <Flex>
                          <Stack spacing={0}>
                            <Text fontSize="xs" color="#000000" pt="2">
                              Entry
                            </Text>
                            <InputGroup>
                              <InputGroup>
                                <NumberInput
                                  borderColor="#D3D3D3"
                                  bg={
                                    fantasyRisk !== "" && fantasyRisk !== "0"
                                      ? `${orgPrimaryColor}40`
                                      : "#FFFFFF"
                                  }
                                  color="#000000"
                                  errorBorderColor="red.500"
                                  _hover={{
                                    border: "1px solid #D3D3D3",
                                  }}
                                  value={fantasyRisk}
                                  onChange={(e) => {
                                    setFantasyRisk(e);
                                  }}
                                >
                                  <NumberInputField
                                    fontSize="xl"
                                    fontWeight="bold"
                                    borderRadius="2px"
                                    placeholder="0.00"
                                    _placeholder={{
                                      color: "gray",
                                      fontWeight: "normal",
                                    }}
                                  />
                                </NumberInput>
                              </InputGroup>
                            </InputGroup>
                          </Stack>
                        </Flex>
                      </Box>

                      <Box pl="2">
                        <Flex>
                          <Stack spacing={0}>
                            <Text fontSize="xs" color="#000000" pt="2">
                              To Win
                            </Text>
                            <InputGroup>
                              <InputGroup>
                                <NumberInput
                                  borderColor="#D3D3D3"
                                  bg={
                                    fantasyRisk !== "" && fantasyRisk !== "0"
                                      ? `${orgPrimaryColor}40`
                                      : "#FFFFFF"
                                  }
                                  color="#000000"
                                  errorBorderColor="red.500"
                                  _hover={{
                                    border: "1px solid #D3D3D3",
                                  }}
                                  value={fantasyWin}
                                  isReadOnly
                                >
                                  <NumberInputField
                                    fontSize="xl"
                                    fontWeight="bold"
                                    borderRadius="2px"
                                    placeholder="0.00"
                                    _placeholder={{
                                      color: "gray",
                                      fontWeight: "normal",
                                    }}
                                  />
                                </NumberInput>
                              </InputGroup>
                            </InputGroup>
                          </Stack>
                        </Flex>
                      </Box>
                    </SimpleGrid>
                  </Box>
                </Box>

                <Box px="4" mb="4" py="4" background="#FFFFFF">
                  <Button
                    w="100%"
                    background={orgPrimaryColor}
                    color="#fff"
                    _hover={{
                      background: orgPrimaryColor,
                      color: "#FFFFFF",
                    }}
                    isDisabled={
                      window.localStorage.loggedIn !== "true" ||
                      fantasyBets.length > 6 ||
                      +fantasyRisk < 1
                    }
                    isLoading={props.placeBetLoading}
                    onClick={() => onOpen()}
                  >
                    {window.localStorage.loggedIn !== "true"
                      ? "Log In to Place Entry"
                      : "Place Entry"}
                  </Button>
                </Box>
              </Stack>
            )}
          </Box>
          {fantasyBets.length === 0 && (
            <Flex pt="40" align="center" justify="center">
              <Stack>
                <Image src="/empty.png" h="100px" />
                <Text pt="5" fontWeight="semibold" color="#000000">
                  No entries selected!
                </Text>
              </Stack>
            </Flex>
          )}
        </div>
      </Box>
      <ConfirmFantasyBetModal
        cancelRef={cancelRef}
        onClose={onClose}
        betConfirm={betConfirm}
        fantasyWin={fantasyWin}
        fantasyRisk={fantasyRisk}
        isOpen={isOpen}
      />
    </Box>
  );
};
