import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Stack,
  Text,
  useToast,
  VisuallyHiddenInput,
} from "@chakra-ui/react";
import { PasswordField } from "components/auth/PasswordField";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory, Link as RouteLink } from "react-router-dom";
import APIService from "services/API";
import { SetPasswordDTO } from "../../models";
import { validEmail } from "utils/Regex";
import { Link } from "components/shared/Link";
import { FaArrowLeft } from "react-icons/fa";

interface ResetPasswordFormProps {
  admin: boolean;
}

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const history = useHistory();
  const toast = useToast();

  const [password, setPassword] = useState("");
  const [comparePassword, setComparePassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const tempToken = window.location.search.split("=")[1];

  const { register, handleSubmit, setValue } = useForm<SetPasswordDTO>({
    mode: "onChange",
  });

  const { mutate, isLoading } = useMutation(APIService.setPassword, {
    onSuccess: (data) => {
      setPasswordChanged(true);
      toast({
        title: "Success",
        description: "Your password has been updated",
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });
    },

    onError: () => {
      toast({
        title: "Error",
        description: "Email Address Not Found",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });
    },
  });

  const confirmPassword = () => {
    if (password !== comparePassword) {
      setErrorMessage("Passwords must match");
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = (data: SetPasswordDTO) => {
    let passwordsMatch = confirmPassword();
    if (passwordsMatch) {
      const setPassword = {
        ...data,
      };
      mutate(setPassword);
    }
  };
  useEffect(() => {
    setErrorMessage("");
  }, [password, comparePassword]);

  return (
    <Box w="85%" mx="auto">
      {!passwordChanged ? (
        <Box>
          <Heading
            textAlign="center"
            size="lg"
            fontWeight="bold"
            mb="4"
            mt="4"
            color="black"
          >
            Set new password
          </Heading>
          <Text color="#575757" fontSize={"17px"} align="center" maxW="md">
            Your new password must be different to previous passwords.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="3">
              <VisuallyHiddenInput value={tempToken} {...register("token")} />

              <FormLabel color="#000000">Password</FormLabel>
              <PasswordField onChange={(e) => setPassword(e.target.value)} />
              <Text as="sub" color="#575757">
                Must have at least 8 characters.
              </Text>
              <Spacer />
              <FormLabel color="#000000">Confirm Password</FormLabel>
              <PasswordField
                {...register("password")}
                onChange={(e) => setComparePassword(e.target.value)}
              />
              <Text fontSize="10px" color="red" height="10px">
                {errorMessage}
              </Text>

              <Button
                type="submit"
                colorScheme="blue"
                size="lg"
                fontSize="md"
                color="white"
                bg="#47AB37"
                tabIndex={4}
                isLoading={isLoading}
              >
                Reset Password
              </Button>
            </Stack>
          </form>
          <Text my="8" align="center" maxW="md" fontWeight="medium">
            <RouteLink to={"/"}>
              <Link color="#919191" display="inline-flex" ml="-4">
                <FaArrowLeft
                  color="#919191"
                  style={{ marginRight: "10", marginTop: "4" }}
                />
                Back to Login
              </Link>
            </RouteLink>
          </Text>
        </Box>
      ) : (
        <Center>
          <Stack>
            <Heading
              textAlign="center"
              size="lg"
              fontWeight="bold"
              mb="4"
              mt="4"
              color="black"
            >
              Password reset!
            </Heading>
            <Center>
              <Text textAlign="center" fontSize="17px" color="#575757">
                Your password has been successfully reset. <br /> Click below to
                log in.
              </Text>
            </Center>
            <Center>
              <Button
                colorScheme="blue"
                color="white"
                size="lg"
                w="325px"
                my="5"
                fontSize="md"
                bg="#47AB37"
                tabIndex={4}
                isLoading={isLoading}
                onClick={() => history.push("/")}
              >
                Log in
              </Button>
            </Center>
            <Text my="8" align="center" maxW="md" fontWeight="medium">
              <RouteLink to={"/"}>
                <Link color="#919191" display="inline-flex" ml="-4">
                  <FaArrowLeft
                    color="#919191"
                    style={{ marginRight: "10", marginTop: "4" }}
                  />
                  Back to Sign up
                </Link>
              </RouteLink>
            </Text>
          </Stack>
        </Center>
      )}
    </Box>
  );
};
