import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Card } from "components/shared/Card";
import { Link } from "components/shared/Link";
import { LoginForm } from "./LoginForm";
import { Link as RouteLink, useHistory } from "react-router-dom";
import macOS from "./images/macOS.svg";
import windows from "./images/windows.svg";

function Login() {
  const history = useHistory();
  const [tblModal, setTblModal] = useState<boolean>(false);

  return (
    <Box bgImage={"BoysBettingBar.jpg"} bgSize="cover">
      <Box
        backgroundColor="#377E2165"
        minH="100vh"
        px={{ base: "4", lg: "8" }}
        display="flex"
        alignItems="center"
      >
        <Box mx="auto" maxW="480px" w="100%">
          <Card bgColor="white" style={{ borderRadius: "inherit" }}>
            <Box h="225px" display="flex" alignItems="center">
              <img
                src="logo.png"
                style={{
                  width: "270px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="BetBooth Logo"
              />
            </Box>
            <LoginForm admin={false} />
            <Text
              color="#3F98ED"
              mt="6"
              mb="6"
              justifyContent="center"
              mx="auto"
              display="flex"
              maxW="md"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => setTblModal(true)}
            >
              Troubleshoot Location Issues
            </Text>
          </Card>
          {/* <Box padding={6}>
          <Center>
            <Text color="#000000">Version 0.3.0</Text>
          </Center>
        </Box> */}
        </Box>
        <Modal isOpen={tblModal} onClose={() => setTblModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader textAlign="center">
              Troubleshoot Login Issues
            </ModalHeader>
            <Divider mb="10px" />
            <ModalBody>
              <Text align={"left"} fontSize="17px" fontWeight={"500"} mb="5px">
                Location check failing
              </Text>
              <Text fontSize={"12px"}>
                If you are getting a failed location check and are in a valid
                region, make sure you have the "Player Location Check" program
                installed on your computer. It should appear in your installed
                applications. If not, please click on your OS below to download
                the software. Install and refresh to try again.
              </Text>
              <Spacer h="10px" />
              <Flex justify={"space-around"}>
                <Flex
                  direction={"column"}
                  _hover={{ opacity: "0.6" }}
                  transition="0.2s"
                  onClick={() => {
                    window.open(
                      "https://stg-ums.geocomply.net/installer/url?id=" +
                        process.env.REACT_APP_GEOCOMPLY_INSTALLER_ID +
                        "&os=win&version=" +
                        "3.1.1.3",
                      "_self"
                    );
                  }}
                >
                  <Image src={windows} cursor="pointer" />
                  <Text fontSize={"12px"} mt="3px">
                    Windows
                  </Text>
                </Flex>
                <Flex
                  direction={"column"}
                  transition="0.2s"
                  _hover={{ opacity: "0.6" }}
                  onClick={() => {
                    window.open(
                      "https://stg-ums.geocomply.net/installer/url?id=" +
                        process.env.REACT_APP_GEOCOMPLY_INSTALLER_ID +
                        "&os=mac&version=" +
                        "3.1.1.3",
                      "_self"
                    );
                  }}
                >
                  <Image src={macOS} cursor="pointer" />
                  <Text fontSize={"12px"} mt="3px">
                    Mac OS
                  </Text>
                </Flex>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button w="100%" onClick={() => history.push("/")}>
                Refresh Page
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}

export default Login;
