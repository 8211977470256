import { RoleDTO } from "./Role";
import { OrgDTO } from "./Orgs";

export interface UserLogin {
  id?: string;
  phoneNo?: string;
  password: string;
}

export interface ValidateLoginToken {
  kioskId: string;
  token: string;
}

export interface ValidateMFA {
  MFAToken: string;
  userId: string;
}

export interface UserEmail {
  email: string;
}

export interface RegisterOneClickRequest {
  method: string;
  orgId: number;
  recaptcha_token?: string | null;
}

export interface RegisterOneClickResponse {
  id: string;
  access_token: string;
  password: string;
}

export interface RegisterUserRequest {
  country: string;
  method: string;
  email?: string;
  phoneNo?: string;
  password: string;
  orgId: number;
  recaptcha_token?: string | null;
}

export interface VerifyUserRequest {
  email?: string;
  phoneNo?: string;
  MFAToken: number;
}

export interface UserLoginResponseDTO {
  access_token: string;
  role: RoleDTO;
}

export interface UserLoginResponseMFADTO {
  userId: string;
  access_token?: string;
  role?: RoleDTO;
}

export interface SetPasswordDTO {
  token: string;
  password: string;
}

export interface AcceptTermsDTO {
  acceptedTC: AcceptedTCDTO;
}

export class AcceptedTCDTO {
  id: string = "";
  version: string = "";
  content: string = "";
  createdDate: string = "";
  documentType: object = {};
}

export interface DepositFundsDTO {
  createPaymentMethodInfo: PaymentMethodInfo;
  amount: number;
}

export class PaymentMethodInfo {
  number: string = "";
  exp_month: number = 0;
  exp_year: number = 0;
  cvc: string = "";
}

export class UserProfileDTO {
  id: string = "";
  firstName: string = "";
  lastName: string = "";
  phoneNo: string = "";
  email: string = "";
  gender: string = "";
  balance: number = 0;
  createdDate: string = "";
  lastLogin: string = "";
  dob: string = "";
  address1: string = "";
  address2: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  active: boolean = false;
  facialModel: string = "";
  fingerprintModel: string = "";
  resetPasswordToken: string = "";
  resetPasswordExpiry: string = "";
  failedLoginCount: number = 0;
  locked: boolean = false;
  lockedToken: string = "";
  lockedTokenExpiry: string = "";
  signupCompleted: boolean = false;
  idVerified: boolean = false;
  verified: boolean = false;
  identityImageFront: string = "";
  identityImageBack: string = "";
  betLimit: number = 1000;
  role: RoleDTO = new RoleDTO();
  org: OrgDTO = new OrgDTO();
  groups: [] = [];
  acceptedTC: AcceptedTCDTO = new AcceptedTCDTO();
}

export interface UserDTO {
  id: number;
  email: string;
  balance: number;
}
