import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useToast,
  Button,
  HStack,
  useDisclosure,
  Center,
  Image,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import BBSpinner from "components/shared/BBSpinner";
import { Bet, OddsChangeData, SportBettingTypes } from "../../../models/Bets";
import { EventDTO } from "models/Events";
import {
  useQuery,
  useMutation,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import Event from "./Event";
import API from "services/API";
import { strings, LanguageKey } from "../../../utils/languageStrings";
import { BetSlip } from "../betslip/BetSlip";
import { useHistory, useParams } from "react-router-dom";
import { ParticipantDTO } from "models/Participant";
import { Footer } from "components/shared/Footer";
import AdCarousel from "components/shared/AdCarousel";
import { useRecoilValue } from "recoil";
import { languageState, orgSecondaryColorState } from "store/Store";
import { MobileBetSlip } from "../betslip/MobileBetSlip";
import OddsChangeModal from "../betslip/OddsChangeModal";

interface HomeProps {
  refetchProfile: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

interface HomeParams {
  sportId: string;
}

export default function Home(props: HomeProps) {
  const { sportId } = useParams<HomeParams>();
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const language = useRecoilValue<LanguageKey>(languageState);
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const [bets, setBets] = useState<Bet[]>([]);
  const [sportFilters, setSportFilters] = useState(0);
  const [oddsChangeData, setOddsChangeData] = useState<OddsChangeData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  // Get current selected bets
  var betArr: [];
  var stringBets = window.localStorage.getItem("bets");
  stringBets !== null ? (betArr = JSON.parse(stringBets!)) : (betArr = []);

  const toast = useToast();

  const getEventsData = useQuery(["getEvents", sportId], () => {
    return API.getUpcomingEvents();
  });

  const getSportsData = useQuery("getSports", () => {
    return API.getSports();
  });

  useEffect(() => {
    setBets(betArr);
  }, [sportId]);

  var events: JSX.Element[] = [];
  var sports: JSX.Element[] = [];

  const { mutate: placeBet } = useMutation(API.placeBet, {
    onSuccess: (data) => {
      setBets([]);

      props.refetchProfile();

      toast({
        title: `Bet successfully placed!`,
        position: "bottom-right",
        status: "success",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
    onError: (error: any) => {
      if (error.response.status == "409") {
        setOddsChangeData(error.response.data);
        onOpen();
      }

      toast({
        title: `Error placing bet`,
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });

      setPlaceBetLoading(false);
    },
  });

  if (getSportsData.status === "success") {
    sports = getSportsData.data.map((sport: any) => {
      var selected = false;
      if (sportFilters === sport.id) {
        selected = true;
      }

      return (
        <Button
          key={sport.id}
          variant="ghost"
          color={selected ? "white" : "darkgray"}
          _hover={{ color: "white" }}
          m="1"
          onClick={() => {
            setSportFilters(sport.id);
            history.push(`/sports/${sport.id}`);
          }}
        >
          {sport.name}
        </Button>
      );
    });
  }

  if (getEventsData.status === "success") {
    events = getEventsData.data.map((event: any) => {
      // Ensure the betOverride field is overriding the bets field,
      const eventOverride: EventDTO = {
        ...event,
        bettingLines: event.bettingLines.map((bl: any) => {
          return {
            ...bl,
            odds: bl.oddsOverride != null ? bl.oddsOverride : bl.odds,
          };
        }),
      };

      return (
        <Box bg="white">
          <Box>
            <Event
              key={event.id}
              bets={bets}
              event={eventOverride}
              sport={event.sport}
              moreWagersVisible={true}
              clickHandler={(
                bettingLineId: string,
                externalId: string,
                odds: number,
                betType: SportBettingTypes,
                category: string,
                participant: ParticipantDTO
              ) => {
                if (bets.some((e) => e.bettingLineId === bettingLineId)) {
                  var newBets = bets;
                  const index = bets.findIndex((betItem) => {
                    return betItem.bettingLineId === bettingLineId;
                  });

                  if (index > -1) {
                    newBets.splice(index, 1);
                  }

                  setBets([...newBets]);
                  const betString = JSON.stringify(bets);
                  window.localStorage.setItem("bets", betString);
                  return;
                }

                let bet: Bet = {
                  event: event,
                  riskAmount: 0,
                  winAmount: 0,
                  bettingLineId: bettingLineId,
                  externalId: externalId,
                  bettingType: betType,
                  category: category,
                  odds: odds,
                  participant: participant,
                  sport: event.sport.name,
                };
                newBets = bets;

                newBets.push(bet);
                setBets([...newBets]);
                const betString = JSON.stringify(bets);
                window.localStorage.setItem("bets", betString);

                /** Display Error Toast if bet slip is greater than 25 */
                if (bets.length > 25) {
                  toast({
                    title: `Error`,
                    description: "Only 25 Bets Allowed On A Ticket",
                    position: "bottom-right",
                    status: "error",
                    isClosable: true,
                  });
                }
              }}
            />
          </Box>
        </Box>
      );
    });
  }

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Box
          pb="0"
          h="100vh"
          style={{ position: "relative", overflowY: "auto" }}
          width={{ base: "100%", lg: "calc(200% - 335px)" }}
        >
          <Box minH="100vh">
            <Box p={2} w="100%">
              <AdCarousel bets={bets} setBets={setBets} />
            </Box>
            <Flex pl="4" alignItems="center" h="5rem" hideBelow="sm">
              <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                {strings[language].sidebar.home}
              </Text>
            </Flex>

            {sports.length !== 0 && (
              <Box
                p="4"
                h="43px"
                w="100%"
                display="flex"
                alignItems="center"
                background={`${orgSecondaryColor} 0% 0% no-repeat padding-box`}
                boxShadow="inset 0px 4px 4px #00000029, 0px 4px 4px #00000029"
                overflowX="auto"
                overflowY="hidden"
              >
                {getSportsData.status === "success" && (
                  <HStack ml="2">{sports}</HStack>
                )}
              </Box>
            )}

            {getEventsData.status === "loading" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30%",
                  height: "100%",
                }}
              >
                <BBSpinner />
              </div>
            )}

            {events.length !== 0 && (
              <Box
                h="68px"
                w="100%"
                display="flex"
                alignItems="center"
                background={`${orgSecondaryColor}30 0% 0% no-repeat padding-box`}
                boxShadow="inset 0px 4px 4px #00000029"
              >
                <Text color="#000000" fontWeight="bold" ml="40px">
                  {strings[language].sportsbook.upcomingEvents}
                </Text>
              </Box>
            )}
            {events.length !== 0 && (
              <Box
                borderRadius="0px 0px 5px 5px"
                maxW="100%"
                position="relative"
                overflowY="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {events}
              </Box>
            )}
            {events.length === 0 && getEventsData.status !== "loading" && (
              <Box mt="20" p="10" background="white" h="50vh">
                <Center>
                  <Box>
                    <Image src="/event.png" w="141px" />
                  </Box>
                </Center>
                <Center mt="5">
                  <Alert colorScheme="whiteAlpha" width="auto">
                    <AlertIcon color="#FCD28D" />
                    <Center>
                      <Text as="b" align="center" color="black">
                        {strings[language].sportsbook.noEventsUpcoming}!
                      </Text>
                    </Center>
                  </Alert>
                </Center>
              </Box>
            )}
          </Box>
          <Box mb="60px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          left="100%"
          zIndex={1}
          hideBelow="lg"
        >
          <BetSlip
            placeBetLoading={placeBetLoading}
            setPlaceBetLoading={setPlaceBetLoading}
            bets={bets}
            setBets={setBets}
            placeBet={placeBet}
          />
        </Box>
      </SimpleGrid>
      <MobileBetSlip
        placeBetLoading={placeBetLoading}
        setPlaceBetLoading={setPlaceBetLoading}
        bets={bets}
        setBets={setBets}
        placeBet={placeBet}
        collapseSlip={props.collapseSlip}
        setCollapseSlip={props.setCollapseSlip}
      />
      {oddsChangeData && (
        <OddsChangeModal
          isOpen={isOpen}
          onClose={onClose}
          oddsChangeData={oddsChangeData}
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
        />
      )}
    </Box>
  );
}
