import { Box, Center } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Bet, SportBettingTypes } from "models/Bets";
import { EventDTO } from "models/Events";
import { ParticipantDTO } from "models/Participant";
import React, { useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { calcPayout } from "utils/oddsCalc";
import TournamentEvent from "./TournamentEvent";

interface Proptypes {
  tournament: EventDTO;
  expanded: boolean;
  bets: Bet[];
  setBets: React.Dispatch<React.SetStateAction<Bet[]>>;
}

export default function TournamentEvents({
  tournament,
  expanded,
  bets,
  setBets,
}: Proptypes) {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(expanded);
  }, []);

  return (
    <Box mb="10px">
      <Box
        borderRadius="5px"
        fontSize="xl"
        fontWeight="bold"
        color="white"
        background="gray.600"
        p="20px 0"
        position={"relative"}
      >
        <Center>{tournament.name}</Center>
        <motion.div
          style={{
            display: "flex",
            position: "absolute",
            right: "20px",
            top: 0,
            height: "100%",
            alignItems: "center",
            cursor: "pointer",
          }}
          animate={{
            rotate: isExpanded ? 180 : 0,
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <FaChevronDown />
        </motion.div>
      </Box>
      <motion.div
        animate={{
          height: isExpanded ? "100%" : "0",
        }}
        transition={{
          y: { duration: 1 },
        }}
        style={{
          width: "100%",
          height: "0",
          overflow: "hidden",
        }}
      >
        <Box bg="gray.900" pt="1px" pb="20px">
          <Box>
            <TournamentEvent
              key={tournament.id}
              bets={bets}
              tournament={tournament}
              clickHandler={(
                bettingLineId: string,
                externalId: string,
                odds: number,
                betType: SportBettingTypes,
                category: string,
                participant: ParticipantDTO
              ) => {
                if (bets.some((e) => e.bettingLineId === bettingLineId)) {
                  var newBets = bets;
                  const index = bets.findIndex((betItem) => {
                    return betItem.bettingLineId === bettingLineId;
                  });

                  if (index > -1) {
                    newBets.splice(index, 1);
                  }

                  setBets([...newBets]);

                  return;
                }
                // ASK ABOUT THIS
                let bet: Bet = {
                  event: tournament,
                  riskAmount: 5,
                  winAmount: calcPayout(5, odds),
                  bettingLineId: bettingLineId,
                  externalId: externalId,
                  bettingType: betType,
                  category: category,
                  odds: odds,
                  participant: participant,
                };
                newBets = bets;

                newBets.push(bet);
                setBets([...newBets]);
              }}
            />
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
}
