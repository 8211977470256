// Calculate the payout based on Metric odds
export function calcPayout(risk: number, odds: number) {
  return risk * odds;
}

// Calculate the risk based on Metric odds
export function calcRisk(winAmount: number, odds: number) {
  return winAmount / odds;
}

export function metricToAmericanOdds(odds: number) {
  if (odds >= 2) {
    return (odds - 1) * 100;
  } else {
    return -100 / (odds - 1);
  }
}
