import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useColorModeValue as mode,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  SimpleGrid,
} from "@chakra-ui/react";
import { LoginForm } from "components/auth/LoginForm";
import { Sidebar } from "components/shared/Sidebar";
import { FR, US } from "country-flag-icons/react/3x2";
import { FaCog } from "react-icons/fa";
import { FiCheckCircle, FiCircle, FiLogOut } from "react-icons/fi";
import { HiOutlineMenu } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  denominationSymbolState,
  fantasyEnabledState,
  languageState,
  layoutState,
  oddsDisplayState,
  orgPrimaryColorState,
  orgSecondaryColorState,
  userState,
} from "store/Store";
import { strings, LanguageKey } from "utils/languageStrings";
import { currencyFormatter } from "utils/formatters";
import { useMobileMenuState } from "utils/useMobileMenuState";

interface MobileTopBarProps {}

export const MobileTopBar = (props: MobileTopBarProps) => {
  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();
  const { isOpen, onClose, onOpen } = useMobileMenuState();
  const user = useRecoilValue(userState);
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const fantasyEnabled = useRecoilValue(fantasyEnabledState);
  const [oddsDisplay, setOddsDisplay] = useRecoilState(oddsDisplayState);
  const [layout, setLayout] = useRecoilState(layoutState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);
  const [language, setLanguage] = useRecoilState<LanguageKey>(languageState);
  const history = useHistory();

  return (
    <Flex
      align="center"
      justify="space-between"
      p="2"
      bg={mode(orgSecondaryColor, orgSecondaryColor)}
      display={{ base: "flex", "2xl": "none" }}
    >
      <HStack>
        <IconButton
          onClick={onOpen}
          variant="unstyled"
          display="flex"
          cursor="pointer"
          aria-label="Menu"
          color="white"
          icon={<HiOutlineMenu fontSize="1.5rem" />}
        />

        <Button
          variant="unstyled"
          color="white"
          hideBelow="sm"
          onClick={onOpen}
        >
          {strings[language].sidebar.allSports}
        </Button>
      </HStack>

      <HStack>
        {window.localStorage.loggedIn &&
          window.localStorage.loggedIn !== "false" && (
            <Button
              color="white"
              background="rgba(255, 255, 255, 0.2)"
              display="block"
              fontWeight="md"
              onClick={() => {
                history.push("/account");
              }}
            >
              <SimpleGrid columns={1} textAlign="left">
                <Text fontSize="xs">
                  {strings[language].navbar.accountBalance}
                </Text>
                <Text fontSize="md" fontWeight="semibold" color="white">
                  {denominationSymbol +
                    currencyFormatter.format(user.balance ?? 0)}
                </Text>
              </SimpleGrid>
            </Button>
          )}

        {!fantasyEnabled && (
          <Menu>
            <MenuButton
              as={Button}
              color="white"
              background="rgba(255, 255, 255, 0.2)"
              iconSpacing={0}
              leftIcon={<FaCog />}
            ></MenuButton>
            <MenuList zIndex={2500}>
              <MenuGroup title="Odds Format">
                <MenuItem
                  closeOnSelect={false}
                  onClick={() => {
                    window.localStorage.oddsDisplay = "Decimal";
                    setOddsDisplay("Decimal");
                  }}
                >
                  {oddsDisplay === "Decimal" && <FiCheckCircle color="green" />}
                  {oddsDisplay !== "Decimal" && <FiCircle />}
                  <Text ml="2">Decimal</Text>
                </MenuItem>
                <MenuItem
                  closeOnSelect={false}
                  onClick={() => {
                    window.localStorage.oddsDisplay = "American";
                    setOddsDisplay("American");
                  }}
                >
                  {oddsDisplay === "American" && (
                    <FiCheckCircle color="green" />
                  )}
                  {oddsDisplay !== "American" && <FiCircle />}
                  <Text ml="2">American</Text>
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuGroup title="Layout">
                <MenuItem
                  closeOnSelect={false}
                  onClick={() => {
                    window.localStorage.layout = "European";
                    setLayout("European");
                  }}
                >
                  {layout === "European" && <FiCheckCircle color="green" />}
                  {layout !== "European" && <FiCircle />}
                  <Text ml="2">European</Text>
                </MenuItem>
                <MenuItem
                  closeOnSelect={false}
                  onClick={() => {
                    window.localStorage.layout = "American";
                    setLayout("American");
                  }}
                >
                  {layout === "American" && <FiCheckCircle color="green" />}
                  {layout !== "American" && <FiCircle />}
                  <Text ml="2">American</Text>
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        )}

        <Menu>
          <MenuButton
            color="white"
            background="rgba(255, 255, 255, 0.2)"
            as={Button}
            leftIcon={
              language === "en" ? (
                <US width="20px" height="30px" />
              ) : (
                <FR width="20px" height="30px" />
              )
            }
          >
            {language === "en" ? "EN" : "FR"}
          </MenuButton>
          <MenuList zIndex={2500}>
            <MenuItem
              closeOnSelect={false}
              onClick={() => {
                window.localStorage.language = "en";
                setLanguage("en");
              }}
            >
              <US width="20px" height="30px" /> <Text ml="10px">English</Text>
            </MenuItem>
            <MenuItem
              closeOnSelect={false}
              onClick={() => {
                window.localStorage.language = "fr";
                setLanguage("fr");
              }}
            >
              <FR width="20px" height="30px" /> <Text ml="10px">French</Text>
            </MenuItem>
          </MenuList>
        </Menu>

        {(!window.localStorage.loggedIn ||
          window.localStorage.loggedIn === "false") && (
          <Button
            color="white"
            background="rgba(255, 255, 255, 0.2)"
            w="120px"
            onClick={() => {
              onLoginOpen();
            }}
          >
            {strings[language].navbar.login}
          </Button>
        )}

        {window.localStorage.loggedIn &&
          window.localStorage.loggedIn !== "false" && (
            <IconButton
              onClick={() => {
                delete localStorage.jwt;
                delete localStorage.loggedIn;
                delete window.localStorage.oddsDisplay;
                delete window.localStorage.layout;
                delete window.localStorage.language;
                history.push("/");
                window.location.reload();
              }}
              icon={<FiLogOut />}
              aria-label="log out"
              color="white"
              background="rgba(255, 255, 255, 0.2)"
            />
          )}
      </HStack>

      <Drawer
        size="xs"
        placement="left"
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={mode("gray.800", "gray.800")}
          shadow="none"
          position="relative"
          maxW="64"
        >
          <Sidebar
            width="full"
            height="full"
            bg="inherit"
            border="0"
            display={{ base: "flex", "2xl": "none" }}
            onClose={onClose}
          />
          <DrawerCloseButton
            bg={orgPrimaryColor}
            rounded="0"
            position="absolute"
            color="white"
            right="-8"
            top="0"
          />
        </DrawerContent>
      </Drawer>

      <Modal isOpen={isLoginOpen} onClose={onLoginClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings[language].navbar.login}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="6">
            <LoginForm admin={false} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
