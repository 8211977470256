import { Box, Center, Text } from "@chakra-ui/react";
import { useQuery } from "react-query";
import API from "services/API";
import { Footer } from "components/shared/Footer";
import { Card } from "components/shared/Card";

export const HouseRules = () => {
  let houseRulesHTML: string = "";
  let version: string = "";

  const latestHouseRules = useQuery(["document"], () => {
    return API.getLatestDocument("house_rules");
  });

  if (latestHouseRules.isSuccess) {
    console.log(latestHouseRules);
    houseRulesHTML = latestHouseRules.data.content;
    version = latestHouseRules.data.version;
  }

  return (
    <Box>
      <Box h="100vh" overflowY="auto">
        <Box minH="100vh">
          <Center>
            <Card bg="white" p={0} my={4}>
              <Center>
                <Text color="black" fontSize="lg">{`v${version}`}</Text>
              </Center>
              <html dangerouslySetInnerHTML={{ __html: houseRulesHTML }} />
            </Card>
          </Center>
        </Box>
        <Box mb="55px">
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};
