import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouteLink,
} from "react-router-dom";
import { Card } from "components/shared/Card";
import { DividerWithText } from "components/shared/DividerWithText";
import { Link } from "components/shared/Link";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { FaArrowLeft } from "react-icons/fa";

function ForgotPassword() {
  return (
    <Flex
      bg={useColorModeValue("white", "white")}
      minH="100vh"
      alignItems="center"
      px={{ base: "4", lg: "8" }}
    >
      <Box maxW="md" mx="auto">
        <img
          src="/Bet-Booth-Stacked-Logo.png"
          style={{
            width: "213px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
          <ForgotPasswordForm admin={false} />

          <Text my="8" align="center" maxW="md" fontWeight="medium">
            <RouteLink to={"/"}>
              <Link color="#919191" display="inline-flex" ml="-4"><FaArrowLeft color="#919191" style={{ marginRight: "10", marginTop: "4" }}/>Back to Login</Link>
            </RouteLink>
          </Text>
      </Box>
    </Flex>
  );
}

export default ForgotPassword;
