import {
  Box,
  Divider,
  Text,
  SimpleGrid,
  Center,
  Avatar,
  AvatarBadge,
  FormControl,
  Input,
} from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import { useRecoilValue } from "recoil";
import {
  languageState,
  orgPrimaryColorState,
  orgSecondaryColorState,
  userState,
} from "store/Store";
import { strings, LanguageKey } from "utils/languageStrings";

export const Profile = () => {
  const user = useRecoilValue(userState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);

  return (
    <Center overflowX="hidden">
      <Box width="100%" mb="20">
        <Center>
          <Box>
            <Center>
              <Avatar
                name={user.email}
                src={user.facialModel}
                background={orgSecondaryColor}
                size="xl"
              >
                <AvatarBadge
                  as={FiCheck}
                  boxSize="0.8em"
                  bottom="6px"
                  right="2px"
                  p="1"
                  bg={orgPrimaryColor}
                  border="none"
                />
              </Avatar>
            </Center>

            <Center>
              <Text
                mt="2"
                color="#0C1335"
                fontSize="25px"
                fontWeight="semibold"
              >
                {user.firstName.toUpperCase() +
                  " " +
                  user.lastName.toUpperCase()}
              </Text>
            </Center>
            <Center>
              <Text color="#919191" fontSize="19px">
                {user.email}
              </Text>
            </Center>
          </Box>
        </Center>

        <FormControl mt="100">
          <SimpleGrid columns={2} pb={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.firstName}
            </Text>
            <Input
              id="first-name"
              placeholder={strings[language].profile.firstName}
              value={user.firstName}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.lastName}
            </Text>
            <Input
              id="last-name"
              placeholder={strings[language].profile.lastName}
              value={user.lastName}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              Email
            </Text>
            <Input
              id="email"
              placeholder="Email"
              value={user.email}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.gender}
            </Text>
            <Input
              id="gender"
              placeholder={strings[language].profile.gender}
              value={user.gender}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.phoneNumber}
            </Text>
            <Input
              id="phone-number"
              placeholder={strings[language].profile.phoneNumber}
              value={user.phoneNo}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.addressLine1}
            </Text>
            <Input
              id="address1"
              placeholder={strings[language].profile.addressLine1}
              value={user.address1}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.addressLine2}
            </Text>
            <Input
              id="address2"
              placeholder={strings[language].profile.addressLine2}
              value={user.address2}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.city}
            </Text>
            <Input
              id="city"
              placeholder={strings[language].profile.city}
              value={user.city}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.state}
            </Text>
            <Input
              id="state"
              placeholder={strings[language].profile.state}
              value={user.state}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>

        <Divider bg="#919191" ml="-50" w="112%" />

        <FormControl>
          <SimpleGrid columns={2} py={3}>
            <Text fontSize="20px" color="#919191" pt={2}>
              {strings[language].profile.postalCode}
            </Text>
            <Input
              id="postalCode"
              placeholder={strings[language].profile.postalCode}
              value={user.postalCode}
              bgColor="white"
              size="lg"
              fontWeight="semibold"
              disabled
              _disabled={{
                color: "black",
              }}
            />
          </SimpleGrid>
        </FormControl>
      </Box>
    </Center>
  );
};
