import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Link as RouteLink, useHistory } from "react-router-dom";
import { Link } from "components/shared/Link";
import { Bet, SportBettingTypes } from "models/Bets";
import { BettingLineDTO } from "models/BettingLine";
import { EventDTO } from "models/Events";
import { ParticipantDTO } from "models/Participant";
import { SportDTO } from "models/Sports";
import { metricToAmericanOdds } from "utils/oddsCalc";
import { relativeTime } from "utils/dateCalcs";
import { useRecoilValue } from "recoil";
import {
  orgSecondaryColorState,
  orgPrimaryColorState,
  oddsDisplayState,
  languageState,
} from "store/Store";
import { LanguageKey } from "utils/languageStrings";

interface EventProps {
  bets: Bet[];
  event: EventDTO;
  sport: SportDTO;
  clickHandler: (
    bettingLineId: string,
    externalId: string,
    odds: number,
    betType: SportBettingTypes,
    betDescription: string,
    participant: ParticipantDTO
  ) => void;
  moreWagersVisible: boolean;
}

function Event(props: EventProps) {
  let event = props.event;
  let sport = props.sport;
  let today = new Date();
  let startTime = event.startTime == null ? 0 : event.startTime;
  const boxHeight = "45px";

  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const language = useRecoilValue<LanguageKey>(languageState);

  const history = useHistory();

  // Parse teams

  let startTimeString =
    startTime == null ? "N/A" : relativeTime(startTime, language);

  // For individual sports (tennis, fighting), look for
  // individual participants, otherwise look for teams

  const teamA: ParticipantDTO | null = event.eventParticipants.filter(
    (eventParticipant) => {
      return eventParticipant.teamSide === "Away";
    }
  )[0].participant;

  const teamB: ParticipantDTO | null = event.eventParticipants.filter(
    (eventParticipant) => {
      return eventParticipant.teamSide === "Home";
    }
  )[0].participant;

  // Parse betting lines

  let moneyLineA: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return (
        bettingLine.betType === "moneyline" &&
        (bettingLine.paramParticipant1?.id === teamA?.id ||
          bettingLine.externalId.includes("away"))
      );
    }
  )[0];
  let moneyLineB: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return (
        bettingLine.betType === "moneyline" &&
        (bettingLine.paramParticipant1?.id === teamB?.id ||
          bettingLine.externalId.includes("home"))
      );
    }
  )[0];
  let moneyLineC: BettingLineDTO | null = event.bettingLines.filter(
    (bettingLine) => {
      return (
        bettingLine.betType === "moneyline" &&
        bettingLine.externalId.includes("draw")
      );
    }
  )[0];

  // Show Selected Bets

  var moneyLineASelected = false;
  var moneyLineBSelected = false;
  var moneyLineCSelected = false;

  if (props.bets.some((e) => e.bettingLineId === moneyLineA?.id ?? "")) {
    moneyLineASelected = true;
  }
  if (props.bets.some((e) => e.bettingLineId === moneyLineB?.id ?? "")) {
    moneyLineBSelected = true;
  }
  if (props.bets.some((e) => e.bettingLineId === moneyLineC?.id ?? "")) {
    moneyLineCSelected = true;
  }

  return (
    <Box overflowX="hidden" w="100%">
      <SimpleGrid
        px={4}
        mt={2}
        columns={{ base: 1, "3xl": 2 }}
        cursor={props.moreWagersVisible ? "pointer" : "default"}
      >
        <Box
          onClick={() =>
            props.moreWagersVisible && history.push(`/events/${event.id}`)
          }
        >
          <Box display="flex" height={4} mb={1}>
            <Text
              color="black"
              fontSize="md"
              fontWeight="semibold"
              display="flex"
              alignItems="center"
            >
              {teamA?.name}
            </Text>
          </Box>

          <Box display="flex">
            <Text color="black" mr="2" fontWeight="semibold">
              {teamB?.name}
            </Text>
          </Box>
          {sport.name !== undefined && event.league !== undefined && (
            <Text display="block" color="gray">
              {`${sport.name} / ${event.league.name}`}
            </Text>
          )}
          <Text fontWeight="bold" display="block" color={orgPrimaryColor}>
            {startTimeString}
          </Text>
        </Box>

        <Box>
          <HStack justifyContent="space-between" pb={2}>
            <SimpleGrid columns={3} w="100%">
              <Box p={0} mx={1} pt={4}>
                {moneyLineB != null ? (
                  <Flex
                    background={
                      moneyLineBSelected ? orgPrimaryColor : "#FFFFFF"
                    }
                    borderRadius="md"
                    height={boxHeight}
                    px={2}
                    cursor="pointer"
                    borderWidth="1px"
                    alignItems="center"
                    _hover={
                      moneyLineBSelected
                        ? {}
                        : { background: `${orgSecondaryColor}30` }
                    }
                    borderColor={orgSecondaryColor}
                    onClick={() => {
                      props.clickHandler(
                        moneyLineB!.id,
                        moneyLineB!.externalId,
                        moneyLineB!.odds,
                        SportBettingTypes.moneyline,
                        moneyLineB!.name,
                        moneyLineB!.paramParticipant1
                      );
                    }}
                  >
                    <Text
                      color={moneyLineBSelected ? "white" : orgSecondaryColor}
                    >
                      1
                    </Text>
                    <Flex w="100%" justifyContent="center">
                      <Text
                        color={moneyLineBSelected ? "white" : orgSecondaryColor}
                        fontWeight="bold"
                      >
                        {`${
                          moneyLineB!.odds >= 2 && oddsDisplay === "American"
                            ? "+"
                            : ""
                        }${
                          oddsDisplay === "American"
                            ? metricToAmericanOdds(moneyLineB!.odds).toFixed(0)
                            : moneyLineB!.odds
                        }`}
                      </Text>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    borderRadius="md"
                    height={boxHeight}
                    px={2}
                    borderWidth="1px"
                    borderColor={orgSecondaryColor}
                    alignItems="center"
                  >
                    <Text>1</Text>
                    <Flex w="100%" justifyContent="center">
                      <Center>
                        <Text
                          mb={5}
                          fontSize="2xl"
                          color="black"
                          fontWeight="bold"
                        >
                          _
                        </Text>
                      </Center>
                    </Flex>
                  </Flex>
                )}
              </Box>
              <Box p={0} mx={2} pt={4}>
                {moneyLineC != null ? (
                  <Flex
                    background={
                      moneyLineCSelected ? orgPrimaryColor : "#FFFFFF"
                    }
                    borderRadius="md"
                    height={boxHeight}
                    px={2}
                    cursor="pointer"
                    borderWidth="1px"
                    alignItems="center"
                    _hover={
                      moneyLineCSelected
                        ? {}
                        : { background: `${orgSecondaryColor}30` }
                    }
                    borderColor={orgSecondaryColor}
                    onClick={() => {
                      props.clickHandler(
                        moneyLineC!.id,
                        moneyLineC!.externalId,
                        moneyLineC!.odds,
                        SportBettingTypes.moneyline,
                        moneyLineC!.name,
                        moneyLineC!.paramParticipant1
                      );
                    }}
                  >
                    <Text
                      color={moneyLineCSelected ? "white" : orgSecondaryColor}
                    >
                      X
                    </Text>
                    <Flex w="100%" justifyContent="center">
                      <Text
                        color={moneyLineCSelected ? "white" : orgSecondaryColor}
                        fontWeight="bold"
                      >
                        {`${
                          moneyLineC!.odds >= 2 && oddsDisplay === "American"
                            ? "+"
                            : ""
                        }${
                          oddsDisplay === "American"
                            ? metricToAmericanOdds(moneyLineC!.odds).toFixed(0)
                            : moneyLineC!.odds
                        }`}
                      </Text>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    borderRadius="md"
                    height={boxHeight}
                    px={2}
                    borderWidth="1px"
                    borderColor={orgSecondaryColor}
                    alignItems="center"
                  >
                    <Text>X</Text>
                    <Flex w="100%" justifyContent="center">
                      <Center>
                        <Text
                          mb={5}
                          fontSize="2xl"
                          color="black"
                          fontWeight="bold"
                        >
                          _
                        </Text>
                      </Center>
                    </Flex>
                  </Flex>
                )}
              </Box>
              <Box p={0} mx={2} pt={4}>
                {moneyLineA != null ? (
                  <Flex
                    height={boxHeight}
                    px={2}
                    background={
                      moneyLineASelected ? orgPrimaryColor : "#FFFFFF"
                    }
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={orgSecondaryColor}
                    alignItems="center"
                    _hover={
                      moneyLineASelected
                        ? {}
                        : { background: `${orgSecondaryColor}30` }
                    }
                    cursor="pointer"
                    onClick={() => {
                      props.clickHandler(
                        moneyLineA!.id,
                        moneyLineA!.externalId,
                        moneyLineA!.odds,
                        SportBettingTypes.moneyline,
                        moneyLineA!.name,
                        moneyLineA!.paramParticipant1
                      );
                    }}
                  >
                    <Text
                      color={moneyLineASelected ? "white" : orgSecondaryColor}
                    >
                      2
                    </Text>
                    <Flex w="100%" justifyContent="center">
                      <Text
                        color={moneyLineASelected ? "white" : orgSecondaryColor}
                        fontWeight="bold"
                      >
                        {`${
                          moneyLineA!.odds >= 2 && oddsDisplay === "American"
                            ? "+"
                            : ""
                        }${
                          oddsDisplay === "American"
                            ? metricToAmericanOdds(moneyLineA!.odds).toFixed(0)
                            : moneyLineA!.odds
                        }`}
                      </Text>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    borderRadius="md"
                    height={boxHeight}
                    px={2}
                    borderWidth="1px"
                    borderColor={orgSecondaryColor}
                    alignItems="center"
                  >
                    <Text>2</Text>
                    <Flex w="100%" justifyContent="center">
                      <Center>
                        <Text
                          mb={5}
                          fontSize="2xl"
                          color="black"
                          fontWeight="bold"
                        >
                          _
                        </Text>
                      </Center>
                    </Flex>
                  </Flex>
                )}
              </Box>
            </SimpleGrid>
            <Box w="80px">
              {props.moreWagersVisible && event.nonFeaturedLineCount > 0 && (
                <RouteLink to={`/events/${event.id}`}>
                  <Button
                    bg="white"
                    borderRadius="md"
                    borderWidth="1px"
                    h={boxHeight}
                    w="60px"
                    mt="15px"
                    borderColor={orgSecondaryColor}
                  >
                    <Link color={orgSecondaryColor} display="inline-flex">
                      + {event.nonFeaturedLineCount}
                    </Link>
                  </Button>
                </RouteLink>
              )}
            </Box>
          </HStack>
        </Box>
      </SimpleGrid>
      <Divider my="0px" h="1px" bgColor="#A0A0A0" />
    </Box>
  );
}

export default Event;
