import {
  Box,
  Flex,
  Spinner,
  Text,
  HStack,
  Image,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Account } from "components/account/Account";
import { Deposit } from "components/account/Deposit";
import { Help } from "components/help/Help";
import { MyBets } from "components/mybets/MyBets";
import { MobileTopBar } from "components/shared/MobileTopBar";
import { Navbar } from "components/shared/Navbar";
import { Sidebar } from "components/shared/Sidebar";
import { SelectedSportContext } from "context/sports";
import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import API from "services/API";
import {
  sportsState,
  userState,
  loginTime,
  tcAccepted,
  orgPrimaryColorState,
  orgSecondaryColorState,
  orgLogoUrlState,
  orgBadgeUrlState,
  oddsDisplayState,
  layoutState,
  denominationSymbolState,
  languageState,
  orgTertiaryColorState,
  fantasyEnabledState,
} from "store/Store";
import { LoginExpiry } from "utils/LoginExpiry";
import { Dashboard } from "./dashboard/Dashboard";
import Events from "./dashboard/Events";
import EuropeanEvents from "./dashboard/european/Events";
import LoginExpiryModal from "./auth/LoginExpiryModal";
import { expTime, currentTime } from "utils/LoginExpiry";
import { GeoComplyContext, GeoContextType } from "context/geoComplyContext";
import { GeoComplyAPI } from "services/GeoComplyAPI";
import { useIdleTimer } from "react-idle-timer";
import { HouseRules } from "./help/HouseRules";
import TCModal from "./dashboard/TCModal";
import Home from "./dashboard/Home";
import EuropeanHome from "./dashboard/european/Home";
import MoreWagers from "./dashboard/MoreWagers";
import EuropeanMoreWagers from "./dashboard/european/MoreWagers";
import { RegisterModal } from "./auth/RegisterModal";
import { MobileBottomBar } from "./shared/MobileBottomBar";
import { OrgDTO } from "models/Orgs";
import Fantasy from "./fantasy/Fantasy";
import UpcomingFantasyEvents from "./fantasy/UpcomingFantasyEvents";

export function Base() {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useRecoilState(userState);
  const [orgPrimaryColor, setOrgPrimaryColor] =
    useRecoilState(orgPrimaryColorState);
  const [orgSecondaryColor, setOrgSecondaryColor] = useRecoilState(
    orgSecondaryColorState
  );
  const [orgTertiaryColor, setOrgTertiaryColor] = useRecoilState(
    orgTertiaryColorState
  );
  const [orgLogoUrl, setOrgLogoUrl] = useRecoilState(orgLogoUrlState);
  const [orgBadgeUrl, setOrgBadgeUrl] = useRecoilState(orgBadgeUrlState);
  const [oddsDisplay, setOddsDisplay] = useRecoilState(oddsDisplayState);
  const [sports, setSports] = useRecoilState(sportsState);
  const [fantasyEnabled, setFantasyEnabled] =
    useRecoilState(fantasyEnabledState);
  const [modalHandler, setModalHandler] = useState(false);
  const [loginSecs, setLoginSecs] = useRecoilState(loginTime);
  const [redirect, setRedirect] = useState(false);
  const [collapseSlip, setCollapseSlip] = useState<boolean>(true);
  const [loading, setLoading] = React.useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [tcStatus, setTCStatus] = useRecoilState(tcAccepted);
  const [layout, setLayout] = useRecoilState(layoutState);
  const [language, setLanguage] = useRecoilState(languageState);
  const [orgLoading, setOrgLoading] = useState(false);
  const [defaultOrg, setDefaultOrg] = useState<OrgDTO | null>();
  const [denominationSymbol, setDenominationSymbol] = useRecoilState(
    denominationSymbolState
  );

  const { setGeoComplyData }: GeoContextType = GeoComplyContext();

  var userTermsDate: string = "";
  var latestTermsDate: string = "";

  const toast = useToast();

  const minutes = 30;

  const logout = () => {
    //history.push("/login");
    window.localStorage.loggedIn = false;
    delete window.localStorage.email;
    delete window.localStorage.jwt;
    delete window.localStorage.bets;
    delete window.localStorage.oddsDisplay;
    delete window.localStorage.layout;
    delete window.localStorage.language;
    setGeoComplyData(null);
  };

  /** Logout the user after 30 minutes of inactivity */
  const onIdle = () => {
    logout();
  };

  useIdleTimer({
    onIdle,
    timeout: 60000 * minutes,
  });

  const ref = useRef<any>();

  /* QUERIES */

  const ipLocationCheck = () => {
    setLocationLoading(true);
    API.ipLocationCheck()
      .then((data) => {
        if (data === false) {
          history.push("/location-restricted");
        }
      })
      .catch(() => {
        history.push("/location-restricted");
        toast({
          title: `Error fetching location.`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLocationLoading(false);
      });
  };

  const fetchDefaultOrg = (slug: string) => {
    setOrgLoading(true);
    API.getOrgBySlug(slug)
      .then((data) => {
        setDefaultOrg(data);
      })
      .catch(() => {
        toast({
          title: `Error fetching default org.`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setOrgLoading(false);
      });
  };

  const getProfileData = useQuery("getProfile", API.getProfile, {
    retry: false,
  });

  const getSportsData = useQuery("getSports", API.getSports);

  /*const getLatestTerms = useQuery("getLatestTerms", () => {
    return API.getLatestDocument("terms_and_conditions");
  });*/

  if (getSportsData.isSuccess && !redirect) {
    setRedirect(true);
    setSports(getSportsData.data);
  }

  if (getProfileData.isSuccess) {
    setUser(getProfileData.data);
    if (user.org) {
      setOrgPrimaryColor(user.org.primaryColor);
      setOrgSecondaryColor(user.org.secondaryColor);
      setOrgTertiaryColor(user.org.tertiaryColor);
      setOrgLogoUrl(user.org.logoUrl);
      setOrgBadgeUrl(user.org.badgeUrl);
      setDenominationSymbol(user.org.denominationSymbol);
      setFantasyEnabled(user.org.fantasy);
      setOddsDisplay(
        window.localStorage.oddsDisplay
          ? window.localStorage.oddsDisplay
          : user.org.oddsDisplay
      );
      setLayout(
        window.localStorage.layout
          ? window.localStorage.layout
          : user.org.layout
      );
      setLanguage(
        window.localStorage.language
          ? window.localStorage.language
          : user.org.language
      );
    } else {
      setOrgPrimaryColor(defaultOrg?.primaryColor || "#007cff");
      setOrgSecondaryColor(defaultOrg?.secondaryColor || "#00458f");
      setOrgTertiaryColor(defaultOrg?.tertiaryColor || "");
      setOrgLogoUrl(
        defaultOrg?.logoUrl ||
          "https://betbooth-public.s3.ca-central-1.amazonaws.com/org-branding/bb_logo_blue_white_text.png"
      );
      setOrgBadgeUrl(
        defaultOrg?.badgeUrl ||
          "https://betbooth-public.s3.ca-central-1.amazonaws.com/org-branding/bb_badge_blue.png"
      );
      setDenominationSymbol(defaultOrg?.denominationSymbol || "$");
      setFantasyEnabled(defaultOrg?.fantasy || false);
      setOddsDisplay(
        window.localStorage.oddsDisplay
          ? window.localStorage.oddsDisplay
          : defaultOrg?.oddsDisplay || "American"
      );
      setLayout(
        window.localStorage.layout
          ? window.localStorage.layout
          : defaultOrg?.layout || "American"
      );
      setLanguage(
        window.localStorage.language
          ? window.localStorage.language
          : defaultOrg?.language || "en"
      );
    }
  }

  if (getProfileData.isError && defaultOrg) {
    setOrgPrimaryColor(defaultOrg.primaryColor);
    setOrgSecondaryColor(defaultOrg.secondaryColor);
    setOrgTertiaryColor(defaultOrg.tertiaryColor);
    setOrgLogoUrl(defaultOrg.logoUrl);
    setOrgBadgeUrl(defaultOrg.badgeUrl);
    setDenominationSymbol(defaultOrg.denominationSymbol);
    setFantasyEnabled(defaultOrg.fantasy);
    setOddsDisplay(
      window.localStorage.oddsDisplay
        ? window.localStorage.oddsDisplay
        : defaultOrg.oddsDisplay
    );
    setLayout(
      window.localStorage.layout
        ? window.localStorage.layout
        : defaultOrg.layout
    );
    setLanguage(
      window.localStorage.language
        ? window.localStorage.language
        : defaultOrg.language
    );
  }

  if (getProfileData.isFetched || getProfileData.isError) {
    if (loading) {
      setLoading(false);
    }
  }

  useEffect(() => {
    ipLocationCheck();
    if (process.env.REACT_APP_DEFAULT_ORG) {
      fetchDefaultOrg(process.env.REACT_APP_DEFAULT_ORG);
    }
  }, []);

  /**
   * Compare latest terms & conditions creation date to
   * users' terms & conditions accepted date.
   * If users' date is less or null, setTCStatus(false) else setTCStatus(true)
   */
  /*const compareTermsDates = () => {
    if (getProfileData.isSuccess && getLatestTerms.isSuccess) {
      latestTermsDate = getLatestTerms.data.createdDate;

      if (getProfileData.data.acceptedTC !== null) {
        userTermsDate = getProfileData.data.acceptedTC.createdDate;

        let date1 = new Date(userTermsDate).getTime();
        let date2 = new Date(latestTermsDate).getTime();

        if (date1 < date2) {
          setTCStatus(false);
        } else {
          setTCStatus(true);
        }
      } else {
        setTCStatus(false);
      }
    }
  };*/

  /*useEffect(() => {
    compareTermsDates();
  }, [getLatestTerms, getProfileData]);

  useEffect(() => {
    if (tcStatus === false) {
      onOpen();
    } else {
      onClose();
    }
  }, [tcStatus]);*/

  if (loading || locationLoading || orgLoading) {
    return (
      <div className="loader-container">
        {" "}
        <Spinner color="black" />
      </div>
    );
  } else {
    return (
      <SelectedSportContext.Provider value={0}>
        <Flex h="100vh" flexDirection="column">
          {/* Responsible Gaming  */}
          {process.env.REACT_APP_AFRICAN_ENVIRONMENT !== "1" && (
            <>
              <Box
                zIndex={{ base: 3, lg: 1500 }}
                w="100%"
                bg={orgSecondaryColor}
              >
                <HStack justifyContent="end" mr="4">
                  <Text fontSize="12px" color="white">
                    Gambling problem? Call 1-800-522-4700
                  </Text>
                  <Image
                    src="/responsible-gaming-logo.png"
                    alt="Responsible Gaming Logo"
                    w="25px"
                  />
                </HStack>
              </Box>

              <Box mb={{ md: "0px", "2xl": "68px" }}>
                <Navbar />
              </Box>
            </>
          )}

          {process.env.REACT_APP_AFRICAN_ENVIRONMENT === "1" && (
            <Box mb={{ md: "0px", "2xl": "81px" }}>
              <Navbar />
            </Box>
          )}

          <MobileTopBar />

          <Flex flex="1" overflow="hidden" bg="#F6F6F6">
            <Flex h="100%" w="100%" mx="auto">
              <Flex flex="1" left="64" ref={ref}>
                <HStack m={0} w="100%">
                  <Sidebar display={{ base: "none", "2xl": "flex" }} />
                  <Flex flex="1" background="#FFFFFF" h="100%">
                    <Box
                      h="100%"
                      w="full"
                      ml={{ md: "0px", "2xl": "375px" }}
                      css={{
                        "&::-webkit-scrollbar": {
                          width: "8px",
                        },
                        "&::-webkit-scrollbar-track": {
                          width: "8px",
                          background: "#333",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#777",
                          borderRadius: "24px",
                        },
                      }}
                    >
                      {/* <Router> */}
                      <Switch>
                        <Route
                          path="/fantasy"
                          exact
                          render={(props) => (
                            <UpcomingFantasyEvents
                              refetchProfile={getProfileData.refetch}
                              collapseSlip={collapseSlip}
                              setCollapseSlip={setCollapseSlip}
                            />
                          )}
                        />

                        <Route
                          path="/history"
                          exact
                          render={(props) => (
                            <MyBets
                              collapseSlip={collapseSlip}
                              setCollapseSlip={setCollapseSlip}
                            />
                          )}
                        />

                        <Route
                          path="/account"
                          exact
                          render={(props) => (
                            <Account
                              collapseSlip={collapseSlip}
                              setCollapseSlip={setCollapseSlip}
                            />
                          )}
                        />

                        <Route
                          path="/help"
                          exact
                          render={(props) => (
                            <Help
                              collapseSlip={collapseSlip}
                              setCollapseSlip={setCollapseSlip}
                            />
                          )}
                        />

                        <Route
                          path="/house-rules"
                          exact
                          render={(props) => <HouseRules />}
                        />

                        <Route
                          path="/deposit"
                          exact
                          render={(props) => <Deposit />}
                        />

                        {layout === "American" ? (
                          <Route
                            path="/sports/:sportId"
                            render={(props) => (
                              <Events
                                refetchProfile={getProfileData.refetch}
                                leagueFilter={history.location.state as number}
                                collapseSlip={collapseSlip}
                                setCollapseSlip={setCollapseSlip}
                              />
                            )}
                          />
                        ) : (
                          <Route
                            path="/sports/:sportId"
                            render={(props) => (
                              <EuropeanEvents
                                refetchProfile={getProfileData.refetch}
                                leagueFilter={history.location.state as number}
                                collapseSlip={collapseSlip}
                                setCollapseSlip={setCollapseSlip}
                              />
                            )}
                          />
                        )}

                        <Route
                          path="/fantasy/leagues/:leagueId"
                          render={(props) => (
                            <Fantasy
                              refetchProfile={getProfileData.refetch}
                              collapseSlip={collapseSlip}
                              setCollapseSlip={setCollapseSlip}
                            />
                          )}
                        />

                        {layout === "American" ? (
                          <Route
                            path="/events/:eventId"
                            render={(props) => (
                              <MoreWagers
                                refetchProfile={getProfileData.refetch}
                                collapseSlip={collapseSlip}
                                setCollapseSlip={setCollapseSlip}
                              />
                            )}
                          />
                        ) : (
                          <Route
                            path="/events/:eventId"
                            render={(props) => (
                              <EuropeanMoreWagers
                                refetchProfile={getProfileData.refetch}
                                collapseSlip={collapseSlip}
                                setCollapseSlip={setCollapseSlip}
                              />
                            )}
                          />
                        )}

                        {layout === "American" ? (
                          <Route
                            path="/home"
                            render={(props) => (
                              <Home
                                refetchProfile={getProfileData.refetch}
                                collapseSlip={collapseSlip}
                                setCollapseSlip={setCollapseSlip}
                              />
                            )}
                          />
                        ) : (
                          <Route
                            path="/home"
                            render={(props) => (
                              <EuropeanHome
                                refetchProfile={getProfileData.refetch}
                                collapseSlip={collapseSlip}
                                setCollapseSlip={setCollapseSlip}
                              />
                            )}
                          />
                        )}

                        <Route
                          path="/"
                          exact
                          render={(props) => (
                            <Dashboard
                              refetchProfile={getProfileData.refetch}
                            />
                          )}
                        />
                      </Switch>
                      {/* </Router> */}
                    </Box>
                  </Flex>
                </HStack>
              </Flex>
            </Flex>
          </Flex>

          <MobileBottomBar
            collapseSlip={collapseSlip}
            setCollapseSlip={setCollapseSlip}
          />

          {/*
          <Box
            position="fixed"
            w="100%"
            bottom="62px"
            bg={orgPrimaryColor}
            color="white"
            textAlign="center"
            zIndex="10000"
            fontWeight="bold"
            pt="3"
            pb="3"
          >
            Select One More Player Prop to Proceed
          </Box>*/}

          {/* Latest Terms and Conditions Modal */}
          {/* <TCModal
            isOpen={isOpen}
            onClose={onClose}
            tcStatus={tcStatus}
            setTcStatus={setTCStatus}
          /> */}
        </Flex>
      </SelectedSportContext.Provider>
    );
  }
}
