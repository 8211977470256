import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { RegisterModal } from "./RegisterModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { orgSecondaryColorState, userState } from "store/Store";
import { useMutation, useQuery } from "react-query";
import API from "services/API";
import { useHistory, useLocation } from "react-router-dom";

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Register = () => {
  const [user, setUser] = useRecoilState(userState);
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const [loading, setLoading] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [tokenValidated, setTokenValidated] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const {
    isOpen: isRegisterOpen,
    onOpen: onRegisterOpen,
    onClose: onRegisterClose,
  } = useDisclosure();

  let query = useQueryParams();

  const token = query.get("token");
  const kioskId = query.get("kioskId");

  const getProfileData = useQuery("getProfile", API.getProfile, {
    retry: false,
  });

  const { mutate } = useMutation(API.validateLoginToken, {
    onSuccess: (data) => {
      setLoading(false);
      setTokenValidated(true);
    },
    onError: () => {
      setLoading(false);
      toast({
        title: "Error",
        description:
          "Could not verify token, please return to kiosk and retry.",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });
    },
  });

  if (getProfileData.isSuccess) {
    setUser(getProfileData.data);
  }

  useEffect(() => {
    if (user.id) {
      setUserLoggedIn(true);
      onRegisterClose();
      if (token && kioskId) {
        setLoading(true);
        mutate({ token, kioskId });
      }
    } else {
      onRegisterOpen();
    }
  }, [user]);

  useEffect(() => {
    if (isRegistered) {
      if (token && kioskId) {
        setLoading(true);
        mutate({ token, kioskId });
      }
    }
  }, [isRegistered]);

  return (
    <Box h="100vh" bg="white">
      <img
        src="https://betbooth-public.s3.ca-central-1.amazonaws.com/org-branding/bb_badge_blue.png"
        style={{
          width: "213px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "20px",
        }}
      />
      {tokenValidated && userLoggedIn && (
        <Box maxW="md" mx="auto">
          <Center>
            <Stack textAlign="center">
              <Heading m={4}>User already logged in.</Heading>
              <Text fontSize="2xl">Please return to kiosk.</Text>
              <Center>
                <Button
                  colorScheme="blue"
                  bg={orgSecondaryColor}
                  color="white"
                  size="lg"
                  w="325px"
                  mt="5"
                  fontSize="md"
                  tabIndex={4}
                  onClick={() => history.push("/")}
                >
                  Ok
                </Button>
              </Center>
            </Stack>
          </Center>
        </Box>
      )}
      {tokenValidated && isRegistered && (
        <Box maxW="md" mx="auto">
          <Center>
            <Stack textAlign="center">
              <Heading m={4}>Successfully Registered User!</Heading>
              <Text fontSize="2xl">Please return to kiosk.</Text>
              <Center>
                <Button
                  colorScheme="blue"
                  color="white"
                  size="lg"
                  w="325px"
                  mt="5"
                  fontSize="md"
                  bg={orgSecondaryColor}
                  tabIndex={4}
                  onClick={() => history.push("/")}
                >
                  Ok
                </Button>
              </Center>
            </Stack>
          </Center>
        </Box>
      )}
      {loading && (
        <Box maxW="md" mx="auto">
          <Heading m={4}>Validate Register Token</Heading>
          <Center p={4}>
            <Stack>
              <Text fontSize="2xl">Validating Token</Text>
              <Spinner color="black" m="auto" mt={4} size="xl" />
            </Stack>
          </Center>
        </Box>
      )}
      <RegisterModal
        isOpen={isRegisterOpen}
        onClose={onRegisterClose}
        isRegistered={isRegistered}
        setIsRegistered={setIsRegistered}
      />
    </Box>
  );
};

export default Register;
