export const calculateFantasyMultiplier = (numBets: number) => {
    let winMultiplier = "";

    switch (numBets) {
      case 2:
        winMultiplier = "3";
        break;
      case 3:
        winMultiplier = "5";
        break;
      case 4:
        winMultiplier = "10";
        break;
      case 5:
        winMultiplier = "15";
        break;
      case 6:
        winMultiplier = "25";
        break;
      default:
        winMultiplier = "1";
    }

    return winMultiplier;
  };