import { Box, Center, ButtonGroup, IconButton, Text } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const Pagination = ({
  currentPage,
  totalPages,
  setCurrentPage,
}: PaginationProps) => {
  return (
    <Box borderColor="gray.200" mt="5" mb="5">
      <Center>
        <ButtonGroup color="gray.200" size="md" isAttached variant="outline">
          {currentPage == 1 || (totalPages == 1 && <div></div>)}

          {currentPage !== 1 && (
            <IconButton
              children={<FaChevronLeft color="black" />}
              bgColor="white"
              borderRadius="6px 0px 0px 6px"
              aria-label="Previous Page"
              onClick={(e) => {
                setCurrentPage(currentPage - 1);
              }}
            />
          )}

          <Box bgColor="white">
            <Center>
              <Text padding="6px" color="black">
                {currentPage} / {totalPages}
              </Text>
            </Center>
          </Box>

          {currentPage !== totalPages && (
            <IconButton
              children={<FaChevronRight color="black" />}
              bgColor="white"
              borderRadius="0px 6px 6px 0px"
              aria-label="Next Page"
              onClick={(e) => {
                setCurrentPage(currentPage + 1);
              }}
            />
          )}
        </ButtonGroup>
      </Center>
    </Box>
  );
};
