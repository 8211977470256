import {
  Box,
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Card } from "components/shared/Card";
import { Link } from "components/shared/Link";
import { Link as RouteLink, useHistory } from "react-router-dom";

function Welcome() {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box bgImage={"BoysBettingBar.jpg"} bgSize="cover">
      <Box
        backgroundColor="#377E2165"
        minH="100vh"
        py="10"
        px={{ base: "4", lg: "8" }}
        display="flex"
        alignItems="center"
      >
        <Box mx="auto" maxW="480px" w="100%">
          <Card
            bgColor="white"
            p="0"
            style={{
              borderRadius: "inherit",
              paddingRight: "0",
              paddingLeft: "0",
            }}
          >
            <Box
              w="100%"
              h="218px"
              display="flex"
              borderRadius="0 0 14rem 14rem"
              backgroundColor="#36D10528"
              justifyContent="center"
            >
              <Box display="flex" alignItems="center">
                <Image
                  src="Bet-Booth-Stacked-Logo.png"
                  style={{
                    width: "212px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  alt="BetBooth Logo"
                />
              </Box>
            </Box>
            <Heading
              textAlign="center"
              as="h2"
              mt="50px"
              fontSize="30px"
              color="black"
            >
              Welcome To Bet Booth
            </Heading>
            <Text textAlign="center" fontSize="22px" color="#575757" mt="12px">
              Get in on the action!
            </Text>
            <Box w="426" px="10" mt="50px">
              <Button
                display="block"
                colorScheme="blue"
                color="white"
                h="53"
                w="100%"
                size="lg"
                fontSize="md"
                bgColor="#47AB37"
                tabIndex={4}
                onClick={() => history.push("/login")}
              >
                Log in
              </Button>
              <Button
                display="block"
                colorScheme="blue"
                color="white"
                mt="30px"
                h="53"
                w="100%"
                size="lg"
                fontSize="md"
                bgColor="#000000"
                tabIndex={4}
                onClick={() => onOpen()}
              >
                Sign Up
              </Button>
            </Box>
            <Box mt="35px" pb="33px" display="flex" justifyContent="center">
              <Text color="#909090">
                By clicking continue I agree to the <br />{" "}
                <RouteLink to={"/welcome"}>
                  <Link
                    color="#909090"
                    textDecoration="underline"
                    display="inline"
                  >
                    Terms of Service
                  </Link>
                </RouteLink>{" "}
                and{" "}
                <RouteLink to={"/welcome"}>
                  <Link
                    color="#909090"
                    textDecoration="underline"
                    display="inline"
                  >
                    Privacy Policy
                  </Link>
                </RouteLink>
              </Text>
            </Box>
          </Card>
        </Box>
      </Box>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
        <ModalOverlay />
        <ModalContent bg="white">
          <ModalHeader color="black" textAlign="center" pt="6">
            Bet Booth Signup
          </ModalHeader>
          <ModalBody py="4">
            <Text color="black" textAlign={"center"} fontSize="18px">
              Currently you must sign up at a designated Bet Booth kiosk. Please
              contact{" "}
              <Link
                href="mailto:support@betbooth.com"
                _hover={{ textDecoration: "underline" }}
                color="blue"
              >
                support@betbooth.com
              </Link>{" "}
              for more info.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              bgColor="#47AB37"
              color="white"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Welcome;
