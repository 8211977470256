import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Image,
  Flex,
  SimpleGrid,
  Text,
  useToast,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import BBSpinner from "components/shared/BBSpinner";
import {
  Bet,
  OddsChangeData,
  PlaceBetTicketDTO,
  SportBettingTypes,
} from "../../models/Bets";
import { EventDTO } from "models/Events";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import Event from "./Event";
import API from "services/API";
import { BetSlip } from "./betslip/BetSlip";
import { useParams } from "react-router-dom";
import { ParticipantDTO } from "models/Participant";
import TournamentEvents from "./TournamentEvents";
import { Footer } from "components/shared/Footer";
import AdCarousel from "components/shared/AdCarousel";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  languageState,
  leagueIdState,
  orgSecondaryColorState,
} from "store/Store";
import { MobileBetSlip } from "./betslip/MobileBetSlip";
import { strings, LanguageKey } from "utils/languageStrings";
import OddsChangeModal from "./betslip/OddsChangeModal";
import { SportDTO } from "models/Sports";

interface EventsProps {
  refetchProfile: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
  leagueFilter?: number;
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}

interface EventsParams {
  sportId: string;
}

export default function Events(props: EventsProps) {
  const { sportId } = useParams<EventsParams>();
  const [placeBetLoading, setPlaceBetLoading] = useState<boolean>(false);
  const [bets, setBets] = useState<Bet[]>([]);
  const [leagueFilters, setLeagueFilters] = useState(0);
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const [leagueId, setLeagueId] = useRecoilState(leagueIdState);
  const [loading, setLoading] = useState(true);
  const [sports, setSports] = useState<SportDTO | null>(null);
  const [events, setEvents] = useState<SportDTO | null>(null);
  const [oddsChangeData, setOddsChangeData] = useState<OddsChangeData>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  // const [isMobile] = useMediaQuery("(max-width: 768px)");

  // Get current selected bets
  var betArr: [];
  var stringBets = window.localStorage.getItem("bets");
  stringBets !== null ? (betArr = JSON.parse(stringBets!)) : (betArr = []);

  var eventRows: JSX.Element[] = [];
  var filters: JSX.Element[] = [];
  var leagueName: string = "";
  var tournaments: JSX.Element[] = [];
  var sportName = "";

  const fetchEvents = () => {
    setLoading(true);
    API.getEvents(+sportId, 0)
      .then((data) => {
        setEvents(data);
      })
      .catch(() => {
        toast({
          title: `Error fetching events.`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSports = () => {
    API.getSport(+sportId)
      .then((data) => setSports(data))
      .catch();
  };

  const placeBet = (data: PlaceBetTicketDTO) => {
    setPlaceBetLoading(true);
    API.placeBet(data)
      .then((data) => {
        setBets([]);

        props.refetchProfile();

        toast({
          title: `Bet successfully placed!`,
          position: "bottom-right",
          status: "success",
          isClosable: true,
        });
      })
      .catch((error) => {
        if (error.response.status == "409") {
          setOddsChangeData(error.response.data);
          onOpen();
        }

        toast({
          title: `Error placing bet`,
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
      })
      .finally(() => {
        setPlaceBetLoading(false);
      });
  };

  if (sports !== null) {
    sportName = sports.name;
  }

  useEffect(() => {
    setLeagueFilters(props.leagueFilter || 0);
    setBets(betArr);
  }, [sportId, props.leagueFilter]);

  useEffect(() => {
    fetchSports();
    fetchEvents();
  }, [sportId]);

  if (events !== null) {
    // Proceed creating filters if leagues exist and sport is not a tournament sport
    if (events.leagues && !events.allowTournaments) {
      // Set default league filter to first league if not set
      if (leagueFilters === 0 && events.leagues.length > 0) {
        setLeagueFilters(events.leagues[0].id);
      } else {
        let filteredLeague = events.leagues.filter((league) => {
          return league.id === leagueFilters;
        })[0];

        if (filteredLeague) {
          leagueName = filteredLeague.name;
        }
      }

      // Load league filter buttons
      filters = events.leagues.map((league) => {
        var selected = false;
        if (leagueFilters === league.id) {
          selected = true;
        }
        return (
          <Button
            key={league.id}
            background={selected ? orgSecondaryColor : "transparent"}
            borderWidth="1px"
            borderStyle="solid"
            w="200px"
            borderColor={selected ? "white" : orgSecondaryColor}
            color={selected ? "white" : orgSecondaryColor}
            _hover={
              selected
                ? {
                    background: orgSecondaryColor,
                    borderColor: "white",
                    color: "white",
                  }
                : {
                    background: "transparent",
                    borderColor: orgSecondaryColor,
                    color: orgSecondaryColor,
                  }
            }
            m="1"
            onClick={() => {
              setLeagueFilters(league.id);
              setLeagueId(league.id);
            }}
          >
            {league.name}
          </Button>
        );
      });

      // Load events
      if (leagueFilters !== 0) {
        var eventsList = events.leagues.filter((league) => {
          return league.id === leagueFilters;
        })[0];

        if (eventsList) {
          if (eventsList.events.length !== 0) {
            eventRows = eventsList.events.map((event) => {
              // Ensure the betOverride field is overriding the bets field,
              // this will eventually be handled on the backend
              const eventOverride: EventDTO = {
                ...event,
                bettingLines: event.bettingLines.map((bl) => {
                  return {
                    ...bl,
                    odds: bl.oddsOverride != null ? bl.oddsOverride : bl.odds,
                  };
                }),
              };

              return (
                <Box bg="white">
                  <Box>
                    <Event
                      key={event.id}
                      bets={bets}
                      event={eventOverride}
                      sport={events}
                      moreWagersVisible={true}
                      clickHandler={(
                        bettingLineId: string,
                        externalId: string,
                        odds: number,
                        betType: SportBettingTypes,
                        category: string,
                        participant: ParticipantDTO
                      ) => {
                        if (
                          bets.some((e) => e.bettingLineId === bettingLineId)
                        ) {
                          var newBets = bets;
                          const index = bets.findIndex((betItem) => {
                            return betItem.bettingLineId === bettingLineId;
                          });

                          if (index > -1) {
                            newBets.splice(index, 1);
                          }

                          setBets([...newBets]);
                          const betString = JSON.stringify(bets);
                          window.localStorage.setItem("bets", betString);
                          return;
                        }

                        let bet: Bet = {
                          event: event,
                          riskAmount: 0,
                          winAmount: 0,
                          bettingLineId: bettingLineId,
                          externalId: externalId,
                          bettingType: betType,
                          category: category,
                          odds: odds,
                          participant: participant,
                          sport: events.name,
                        };
                        newBets = bets;

                        newBets.push(bet);
                        setBets([...newBets]);
                        const betString = JSON.stringify(bets);
                        window.localStorage.setItem("bets", betString);

                        /** Display Error Toast if bet slip is greater than 25 */
                        if (bets.length > 25) {
                          toast({
                            title: `Error`,
                            description: "Only 25 Bets Allowed On A Ticket",
                            position: "bottom-right",
                            status: "error",
                            isClosable: true,
                          });
                        }
                      }}
                    />
                  </Box>
                </Box>
              );
            });
          }
        }
      }
    } else if (events.tournamentEvents && events.allowTournaments) {
      if (events.tournamentEvents.length > 0) {
        events.tournamentEvents.forEach((tournament, i) => {
          tournaments.push(
            <TournamentEvents
              tournament={tournament}
              expanded={i === 0 ? true : false}
              bets={bets}
              setBets={setBets}
            />
          );
        });
      } else {
        tournaments.push(
          <Box mt="10" p="10" background="gray.800">
            <Center>
              <Alert status="warning" variant="top-accent" width="auto">
                <AlertIcon />
                <Center>
                  <Text align="center">
                    {strings[language].sportsbook.noEventsSport}
                  </Text>
                </Center>
              </Alert>
            </Center>
          </Box>
        );
      }
    }
  }

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
        <Box
          pb="0"
          h="100vh"
          style={{ position: "relative", overflowY: "auto" }}
          width={{ base: "100%", lg: "calc(200% - 335px)" }}
        >
          <Box minH="100vh">
            <Box p={2} w="100%">
              <AdCarousel bets={bets} setBets={setBets} />
            </Box>
            <Flex pl="4" alignItems="center" h="5rem" hideBelow="sm">
              <Text color="black" fontSize="3xl" fontWeight="semibold" ml="4">
                {sportName.charAt(0).toUpperCase() + sportName.slice(1)}
              </Text>
            </Flex>

            {loading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10%",
                  height: "100%",
                }}
              >
                <BBSpinner />
              </div>
            )}

            {eventRows.length !== 0 && !loading && (
              <Box>
                <Box
                  p="10"
                  h="93px"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  background="#FFFFFF 0% 0% no-repeat padding-box"
                  boxShadow="inset 0px 4px 4px #00000029, 0px 4px 4px #00000029"
                  overflowX="auto"
                  overflowY="hidden"
                >
                  {events !== null && <HStack ml="2">{filters}</HStack>}
                </Box>
                <Box
                  h="68px"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  background="#F6F6F6 0% 0% no-repeat padding-box"
                  boxShadow="inset 0px 4px 4px #00000029"
                >
                  {events !== null && !events.allowTournaments && leagueName ? (
                    <Text color="#000000" fontWeight="bold" ml="40px">
                      {leagueName}
                    </Text>
                  ) : (
                    tournaments
                  )}
                  {events !== null && eventRows.length !== 0 && (
                    <Flex>
                      <Text
                        color="#434343"
                        w="120px"
                        ml="3"
                        mr="3"
                        textAlign="center"
                        fontWeight="semibold"
                        hideBelow="md"
                      >
                        {strings[language].sportsbook.spread.toUpperCase()}
                      </Text>
                      <Text
                        color="#434343"
                        w="120px"
                        ml="3"
                        mr="3"
                        textAlign="center"
                        fontWeight="semibold"
                      >
                        {strings[language].sportsbook.moneyline.toUpperCase()}
                      </Text>
                      <Text
                        color="#434343"
                        w="120px"
                        ml="3"
                        mr="3"
                        textAlign="center"
                        fontWeight="semibold"
                        hideBelow="md"
                      >
                        {strings[language].sportsbook.total.toUpperCase()}
                      </Text>
                    </Flex>
                  )}
                </Box>
                <Box
                  borderRadius="0px 0px 5px 5px"
                  maxW="100%"
                  position="relative"
                  overflowY="auto"
                  css={{
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {eventRows}
                </Box>
              </Box>
            )}
            {events !== null &&
              events.leagues &&
              events.leagues.length === 0 && (
                <Box mt="20" p="10" background="white" h="50vh">
                  <Center>
                    <Box>
                      <Image src="/event.png" w="141px" />
                    </Box>
                  </Center>
                  <Center mt="5">
                    <Alert colorScheme="whiteAlpha" width="auto">
                      <AlertIcon color="#FCD28D" />
                      <Center>
                        <Text as="b" align="center" color="black">
                          {strings[language].sportsbook.noEventsSport}!
                        </Text>
                      </Center>
                    </Alert>
                  </Center>
                </Box>
              )}
            {events !== null &&
              eventRows.length === 0 &&
              events.leagues &&
              events.leagues.length !== 0 && (
                <Box mt="20" p="10" background="white" h="50vh">
                  <Center>
                    <Box>
                      <Image src="/event.png" w="141px" />
                    </Box>
                  </Center>
                  <Center mt="5">
                    <Alert colorScheme="whiteAlpha" width="auto">
                      <AlertIcon color="#FCD28D" />
                      <Center>
                        <Text as="b" align="center" color="black">
                          {strings[language].sportsbook.noEventsLeague}!
                        </Text>
                      </Center>
                    </Alert>
                  </Center>
                </Box>
              )}
          </Box>
          <Box mb="60px">
            <Footer />
          </Box>
        </Box>
        <Box
          width="375px"
          h="100%"
          position="sticky"
          top="0"
          left="100%"
          zIndex={1}
          hideBelow="lg"
        >
          <BetSlip
            placeBetLoading={placeBetLoading}
            setPlaceBetLoading={setPlaceBetLoading}
            bets={bets}
            setBets={setBets}
            placeBet={placeBet}
          />
        </Box>
      </SimpleGrid>
      <MobileBetSlip
        placeBetLoading={placeBetLoading}
        setPlaceBetLoading={setPlaceBetLoading}
        bets={bets}
        setBets={setBets}
        placeBet={placeBet}
        collapseSlip={props.collapseSlip}
        setCollapseSlip={props.setCollapseSlip}
      />
      {oddsChangeData && (
        <OddsChangeModal
          isOpen={isOpen}
          onClose={onClose}
          oddsChangeData={oddsChangeData}
          placeBetLoading={placeBetLoading}
          setPlaceBetLoading={setPlaceBetLoading}
          bets={bets}
          setBets={setBets}
          placeBet={placeBet}
        />
      )}
    </Box>
  );
}
