import { Badge } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { languageState } from "store/Store";
import { LanguageKey, strings } from "utils/languageStrings";

interface BetStatusProps {
  betStatus: string;
}

export const BetStatus = ({ betStatus }: BetStatusProps) => {
  const language = useRecoilValue<LanguageKey>(languageState);
  var background = "";
  var status = "";
  switch (betStatus) {
    case "won":
      status = strings[language].myBets.won;
      background = "green.200";
      break;
    case "lost":
      status = strings[language].myBets.lost;
      background = "red.200";
      break;
    case "unresolved":
      status = strings[language].myBets.unresolved;
      background = "gray.200";
      break;
    case "voided":
      status = strings[language].myBets.voided;
      background = "purple.200";
      break;
    case "refunded":
      status = strings[language].myBets.refunded;
      background = "yellow.200";
      break;
  }

  return (
    <Badge background={background} color="black">
      {status}
    </Badge>
  );
};
