import {
  HStack,
  Icon,
  Image,
  Link,
  LinkProps,
  useColorModeValue as mode,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { orgPrimaryColorState } from "store/Store";

interface NavLinkProps extends LinkProps {
  isActive?: boolean;
  label: string;
  image?: string;
  icon?: React.ElementType;
  to?: string;
  fontSize?: string;
}

export const NavLink = (props: NavLinkProps) => {
  const { to, image, icon, isActive, label, ...rest } = props;
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);

  return (
    <Link
      display="block"
      px="3"
      borderRadius="md"
      transition="all 0.3s"
      fontWeight="medium"
      fontSize={props.fontSize || "sm"}
      userSelect="none"
      aria-current={isActive ? "page" : undefined}
      _hover={{
        bg: mode("gray.400", "gray.400"),
        color: mode("white", "white"),
      }}
      _activeLink={{
        bg: mode(orgPrimaryColor, orgPrimaryColor),
        color: mode("white", "white"),
      }}
      as={RouterLink}
      to={to}
      {...rest}
    >
      <HStack spacing="0">
        {image != null && (
          <Text className={isActive ? "" : "svgIcon"}>
            <Image src={image} alt="sportsIcon" boxSize="45px" />
          </Text>
        )}
        {icon != null && (
          <Icon
            as={icon}
            fontSize="2xl"
            color={isActive ? "#FFFFFF" : "#CCCCCC"}
            mr="10px"
            ml="12px"
            my="11px"
          />
        )}
        <Text as="span">{label}</Text>
      </HStack>
    </Link>
  );
};
