import {
  Box,
  Flex,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

interface FooterProps {
  width?: string;
  height?: string;
  bg?: string;
  border?: string;
  style?: Object;
}

export const Footer = (props: FooterProps) => {
  const history = useHistory();

  return (
    <Box
      bg={mode("#3C4153", "#3C4153")}
      display={"block"}
      position="relative"
      pb={{ base: 14, lg: 8 }}
      width="100%"
      zIndex="500"
    >
      <Flex flex="1" overflowY="auto" px="4">
        <SimpleGrid columns={{ base: 2, lg: 3 }} spacing={1} width="100%">
          <Box p="8">
            <Link
              width="auto"
              display="block"
              href="#"
              color="#FFFFFF"
              fontWeight="bold"
              fontSize="18"
            >
              About
            </Link>

            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              FAQs
            </Link>
            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              Responsible Gaming
            </Link>
            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              Privacy Policy
            </Link>
            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              Terms of Use
            </Link>
            <Link
              display="block"
              href="#"
              color="#C4C3D3"
              fontSize="14"
              mt="2"
              onClick={() => history.push("/house-rules")}
            >
              House Rules
            </Link>
            <Text color="#FFFFFF" mt="2" fontSize="14">
              v1.0.0
            </Text>
          </Box>

          <Box pt="8">
            <Link
              display="block"
              href="#"
              color="#FFFFFF"
              fontWeight="bold"
              fontSize="18"
            >
              Help and Contact{" "}
            </Link>

            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              Help Center
            </Link>
            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              Support and Advice
            </Link>
            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              Self-Exclusion
            </Link>
            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              Chat with an Expert
            </Link>
            <Link display="block" href="#" color="#C4C3D3" fontSize="14" mt="2">
              Email Support
            </Link>
          </Box>
        </SimpleGrid>
      </Flex>
    </Box>
  );
};
