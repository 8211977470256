/* eslint-disable */
import axios from "axios";
export class GeoComplyAPI {
  static getGeoLicense = async (geoEnv) => {
    console.log("Making geolicense API call...");
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/geocomply/${geoEnv}/license`
      );
      return data.license;
    } catch (error) {
      console.log(error);
    }
  };

  static unencryptGeoPacket = async (geoPacket, jwt) => {
    console.log("Making unencrypt API call...");
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/checkLocation`,
        { geoPacket: geoPacket },
        {
          headers: {
            Authorization: jwt
              ? "Bearer " + jwt
              : "Bearer " + window.localStorage.jwt,
          },
        }
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  /** GeoComply provides a JS package which retrieves a geopacket asynchronously.
   * However, JS will not await the response, so we must pass a setState variable
   * to catch the packet when the async call is complete. We can then
   * continue with the decryption in the geocomply context by catching the
   * state change in a use effect.
   */
  static getGeoPacket = async (
    geoComplyConfig,
    setGeoPacket,
    setGeoComplyError,
    setDownloadModal
  ) => {
    window.GeoComply.Client.on("connect", function () {
      console.log("Connecting to geocomply client...");
      console.log("Setting license in client...");
      const setLicense = GeoComply.Client.setLicense(geoComplyConfig.license)
        .setGeolocationReason(geoComplyConfig.reason)
        .setUserId(geoComplyConfig.userId);
      // GeoComply.Client.customFields.set(
      //   "session_key",
      //   geoComplyConfig.customFields.sessionKey
      // );
      console.log("Requesting geolocation in client...");
      GeoComply.Client.requestGeolocation();
      // GeoComply.Client.disconnect();
    })

      // USE BELOW FOR DEVELOPMENT TO CATCH GEOCOMPLY ERRORS
      .on("error", function (errorCode, errorMessage) {
        switch (errorCode) {
          case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
            // console.log(
            //   "Connection to GeoComply Client failed. Details: ErrCode=[" +
            //     errorCode +
            //     "]; ErrMessage=[" +
            //     errorMessage +
            //     "]"
            // );
            break;
          case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
            // console.log(
            //   "Connection to GeoComply Client failed. Details: ErrCode=[" +
            //     errorCode +
            //     "]; ErrMessage=[" +
            //     errorMessage +
            //     "]"
            // );
            break;
          case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
            // console.log(
            //   "Connection to GeoComply Client failed. Details: ErrCode=[" +
            //     errorCode +
            //     "]; ErrMessage=[" +
            //     errorMessage +
            //     "]"
            // );
            // If this event handler is called, most probably Player Location Check Service
            // is not installed. The handler downloads and install Player Location Check service.
            if (navigator.userAgentData.platform === "Windows") {
              window.open(
                "https://stg-ums.geocomply.net/installer/url?id=" +
                  geoComplyConfig.installerID +
                  "&os=win&version=" +
                  "3.1.1.3",
                "_self"
              );
            } else if (navigator.userAgentData.platform === "macOS") {
              window.open(
                "https://stg-ums.geocomply.net/installer/url?id=" +
                  geoComplyConfig.installerID +
                  "&os=mac&version=" +
                  "3.1.1.3",
                "_self"
              );
            }
            setDownloadModal(true);
            break;
          case GeoComply.Client.CLNT_ERROR_TRANSACTION_TIMEOUT:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
            // console.log(
            //   "Connection to GeoComply Client failed. Details: ErrCode=[" +
            //     errorCode +
            //     "]; ErrMessage=[" +
            //     errorMessage +
            //     "]"
            // );
            break;
          // Missing required parameters
          case GeoComply.Client.CLNT_ERROR_WRONG_OR_MISSING_PARAMETER:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
            // console.log(
            //   "GeoLocation failed. Details: ErrCode=[" +
            //     errorCode +
            //     "]; ErrMessage=[" +
            //     errorMessage +
            //     "]"
            // );
            break;
          case GeoComply.Client.CLNT_ERROR_LICENSE_EXPIRED:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
          case GeoComply.Client.CLNT_ERROR_INVALID_LICENSE_FORMAT:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
            // console.log(
            //   "GeoLocation failed. Details: ErrCode=[" +
            //     errorCode +
            //     "]; ErrMessage=[" +
            //     errorMessage +
            //     "]"
            // );
            break;
          case GeoComply.Client.CLNT_ERROR_SERVER_COMMUNICATION:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
            // console.log(
            //   "GeoLocation failed. Details: ErrCode=[" +
            //     errorCode +
            //     "]; ErrMessage=[" +
            //     errorMessage +
            //     "]"
            // );
            break;
          default:
            setGeoComplyError(`Error getting geo-packet: ${errorCode}`);
            // console.log(
            //   "GeoLocation failed. Details: ErrCode=[" +
            //     errorCode +
            //     "]; ErrMessage=[" +
            //     errorMessage +
            //     "]"
            // );
            break;
        }
      })
      .on("geolocation", function (data) {
        // Process geolocation data here
        console.log("Successfully retrieved client data...");
        setGeoPacket(data);
        return data;
      })
      .on("log", console.log);
    GeoComply.Client.connect(
      geoComplyConfig.installerID,
      geoComplyConfig.envId
    );
  };
}
