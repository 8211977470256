export class OrgDTO {
  id: number = 0;
  name: string = "";
  active: boolean = false;
  slug: string = "";
  primaryColor: string = "";
  secondaryColor: string = "";
  tertiaryColor: string = "";
  badgeUrl: string = "";
  logoUrl: string = "";
  oddsDisplay: string = "";
  layout: string = "";
  denominationSymbol: string = "";
  taxRate: number = 0;
  language: string = "";
  fantasy: boolean = false;
}
