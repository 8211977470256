import * as React from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { fantasyEnabledState, sportsState } from "store/Store";

interface DashboardProps {
  refetchProfile: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
}

export const Dashboard = (props: DashboardProps) => {
  const sports = useRecoilValue(sportsState);
  const fantasyEnabled = useRecoilValue(fantasyEnabledState);

  if (
    window.location.pathname === "/" &&
    sports.length > 0 &&
    !fantasyEnabled
  ) {
    return <Redirect push to="/home" />;
  }

  if (window.location.pathname === "/" && fantasyEnabled) {
    return <Redirect push to="/fantasy" />;
  }

  return <div></div>;
};
