import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import React from "react";
import { FaArrowLeft, FaFacebook, FaGithub, FaGoogle } from "react-icons/fa";
import { Card } from "components/shared/Card";
import { DividerWithText } from "components/shared/DividerWithText";
import { ResetPasswordForm } from "./ResetPasswordForm";

function ResetPassword() {
  return (
    <Flex
      bg={useColorModeValue("white", "white")}
      minH="100vh"
      alignItems="center"
      py="8"
      px={{ base: "4", lg: "8" }}
    >
      <Box maxW="md" mx="auto">
        <img
          src="/Bet-Booth-Stacked-Logo.png"
          style={{
            width: "213px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
          <ResetPasswordForm admin={false} />
      </Box>
    </Flex>
  );
}

export default ResetPassword;
