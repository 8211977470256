import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Spacer,
  Stack,
  Switch,
  Tab,
  TabList,
  Tabs,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { GeoComplyContext, GeoContextType } from "context/geoComplyContext";
import { Bet, PlaceBetTicketDTO, SportBettingTypes } from "models/Bets";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { UseMutateFunction, useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import API from "services/API";
import {
  betsState,
  denominationSymbolState,
  languageState,
  oddsDisplayState,
  orgPrimaryColorState,
  orgSecondaryColorState,
} from "store/Store";
import { relativeTime } from "utils/dateCalcs";
import { currencyFormatter } from "utils/formatters";
import { LanguageKey, strings } from "../../../utils/languageStrings";
import {
  calcPayout,
  calcRisk,
  metricToAmericanOdds,
} from "../../../utils/oddsCalc";
import ConfirmBetModal from "./ConfirmBetModal";
interface MobileBetSlipProps {
  placeBetLoading: boolean;
  setPlaceBetLoading: Dispatch<SetStateAction<boolean>>;
  bets: Bet[];
  setBets: Dispatch<SetStateAction<Bet[]>>;
  placeBet: UseMutateFunction<any, unknown, PlaceBetTicketDTO, unknown>;
  collapseSlip: boolean;
  setCollapseSlip: Dispatch<SetStateAction<boolean>>;
}
export enum BetType {
  parlay = "Parlay",
  straight = "Straight",
}

export const MobileBetSlip = (props: MobileBetSlipProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: oddsInfoIsOpen,
    onOpen: oddsInfoOnOpen,
    onClose: oddsInfoOnClose,
  } = useDisclosure();
  const cancelRef: any = useRef();
  const [confirmInput, setConfirmInput] = useState<boolean>(false);
  const [acceptOddsChange, setAcceptOddsChange] = useState(false);
  const [parlayRisk, setParlayRisk] = useState<string>("");
  const [parlayWin, setParlayWin] = useState<string>("");
  const [betType, setBetType] = useState<BetType>();
  const [locationLoading, setLocationLoading] = useState("");
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const [isStraight, setIsStraight] = useState(true);
  const [bets, setBets] = useRecoilState(betsState);
  const [dirtyBets, setDirtyBets] = useState<string[]>([]);
  const [dirtyParlay, setDirtyParlay] = useState(false);
  const [invalidParlay, setInvalidParlay] = useState(false);
  const [emptyBets, setEmptyBets] = useState<string[]>([]);
  const [straightBetsInvalid, setStraightBetsInvalid] = useState(false);
  const language = useRecoilValue<LanguageKey>(languageState);
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);
  const {
    getGeoComply,
    geoComplyData,
    geoComplyError,
    setGeoComplyData,
  }: GeoContextType = GeoComplyContext();

  var showParlay = false;
  var parlayDisabled = false;
  var parlayOdds = 0;
  var betConfirm: JSX.Element[] = [];

  const tabProps = {
    color: "white",
    bg: orgSecondaryColor,
  };

  const addDirtyBet = (bettingLineId: string) => {
    setDirtyBets((prevDirtyBets) => [...prevDirtyBets, bettingLineId]);
  };

  const removeDirtyBet = (bettingLineId: string) => {
    setDirtyBets((prevDirtyBets) =>
      prevDirtyBets.filter((bet) => bet !== bettingLineId)
    );
  };

  const checkIfBetIsDirty = (bettingLineId: string) => {
    return dirtyBets.includes(bettingLineId);
  };

  const checkEmptyBets = (props: MobileBetSlipProps) => {
    const tempEmptyBets: string[] = [];

    props.bets.forEach((bet) => {
      if (bet.riskAmount === 0 || !bet.riskAmount) {
        tempEmptyBets.push(bet.bettingLineId);
      }
    });

    if (tempEmptyBets.length > 0) {
      setEmptyBets(tempEmptyBets);
    }
  };

  const removeFromEmptyBets = (bettingLineId: string) => {
    setEmptyBets((prevEmptyBets) =>
      prevEmptyBets.filter((betId) => betId !== bettingLineId)
    );
  };

  const getProfileData = useQuery(["getProfile"], () => {
    return API.getProfile();
  });

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setBets(props.bets.length);
  }, [props.bets]);

  useEffect(() => {
    if (getProfileData.status === "success") {
      setStraightBetsInvalid(
        props.bets.filter(
          (bet) =>
            bet.riskAmount < 5 ||
            bet.riskAmount > getProfileData.data!.betLimit ||
            !bet.riskAmount
        ).length
          ? true
          : false
      );
    }
  }, [props.bets]);

  if (props.bets.length > 1) {
    showParlay = true;
    for (const i in props.bets) {
      if (parlayOdds == 0) {
        parlayOdds = props.bets[i].odds;
      } else {
        parlayOdds = parlayOdds * props.bets[i].odds;
      }
      // Disable the parlay if bet is in the same event but
      // on different teams
      for (const j in props.bets) {
        if (
          props.bets[i].event.id === props.bets[j].event.id &&
          props.bets[i].bettingLineId != props.bets[j].bettingLineId
        ) {
          parlayDisabled = true;
          break;
        }
      }
    }
  }

  betConfirm = props.bets.map((bet) => {
    return (
      <Tr key={bet.bettingLineId}>
        <Td>
          <Box>
            <Text color="#000000">{bet.category}</Text>
            <Text fontSize="12" color="gray">
              {capitalizeFirstLetter(bet.bettingType)}
            </Text>
          </Box>
        </Td>
        <Td>
          <Text color={orgPrimaryColor}>
            {oddsDisplay === "American"
              ? metricToAmericanOdds(bet.odds).toFixed(0)
              : bet.odds}
          </Text>
        </Td>
        {betType === BetType.straight && (
          <Td>
            <Text color="#000000">
              {denominationSymbol + currencyFormatter.format(bet.riskAmount)}
            </Text>
          </Td>
        )}
        {betType === BetType.straight && (
          <Td>
            <Text color="#000000">
              {denominationSymbol + currencyFormatter.format(bet.winAmount)}
            </Text>
          </Td>
        )}
      </Tr>
    );
  });

  let betList = props.bets.map((bet) => {
    var betType = "";
    if (bet.bettingType === SportBettingTypes.moneyline) {
      betType = "Money Line";
    } else if (bet.bettingType === SportBettingTypes.spread) {
      betType = "Spread";
    } else if (bet.bettingType === SportBettingTypes.total) {
      betType = "Totals";
    }

    return (
      <Box
        p="2"
        background="#FFFFFF"
        border="1px solid #00000029"
        zIndex={1}
        key={bet.bettingLineId}
      >
        <IconButton
          background="fff"
          mt="-2"
          float="right"
          color="#DD0D0D"
          aria-label="Remove Bet"
          icon={<AiOutlineMinusCircle />}
          onClick={() => {
            var newBets = props.bets;
            const index = props.bets.findIndex((betItem) => {
              return betItem.bettingLineId === bet.bettingLineId;
            });

            if (index > -1) {
              newBets.splice(index, 1);
            }

            removeFromEmptyBets(bet.bettingLineId);
            removeDirtyBet(bet.bettingLineId);
            props.setBets([...newBets]);
            const betString = JSON.stringify([...newBets]);
            window.localStorage.setItem("bets", betString);
          }}
        />
        <Flex>
          <HStack w="100%" justify="space-between">
            <Text fontSize="sm" fontWeight="bold">
              {bet.category}
            </Text>
            <Text fontSize="sm" fontWeight="bold" color={orgPrimaryColor}>
              {oddsDisplay === "American"
                ? metricToAmericanOdds(bet.odds).toFixed(0)
                : bet.odds}
            </Text>
          </HStack>
        </Flex>
        <Text fontSize="sm" fontWeight="semibold" color="#575757">
          {betType}
        </Text>
        <Text fontSize="sm" fontWeight="semibold" color="#575757" noOfLines={1}>
          {bet.event.name}
        </Text>
        <Text fontSize="sm" color="#919191">
          {relativeTime(bet.event.startTime, language)}
        </Text>
        {isStraight && (
          <SimpleGrid columns={2} pt="2">
            <Box pr="2">
              <Flex>
                <Stack spacing={0}>
                  <Text fontSize="xs" color="#000000">
                    {strings[language].betSlip.wager}
                  </Text>
                  <InputGroup>
                    <NumberInput
                      id={bet.bettingLineId}
                      borderColor="#D3D3D3"
                      background="transparent"
                      _hover={{
                        border: "1px solid #D3D3D3",
                        borderTop: "transparent",
                      }}
                      color="#000000"
                      errorBorderColor="red.500"
                      isInvalid={
                        ((bet.riskAmount >
                          (getProfileData.data?.betLimit ?? 1000) ||
                          bet.riskAmount < 5) &&
                          checkIfBetIsDirty(bet.bettingLineId)) ||
                        emptyBets.includes(bet.bettingLineId)
                      }
                      value={+bet.riskAmount.toFixed(2) || ""}
                      onBlur={() => addDirtyBet(bet.bettingLineId)}
                      onFocus={() => {
                        if (emptyBets.includes(bet.bettingLineId)) {
                          removeFromEmptyBets(bet.bettingLineId);
                        }
                      }}
                      onChange={(e) => {
                        var newBets = props.bets;
                        const index = props.bets.findIndex((betItem) => {
                          return betItem.bettingLineId === bet.bettingLineId;
                        });

                        if (index > -1) {
                          newBets[index].riskAmount = +e;
                          newBets[index].winAmount = calcPayout(
                            +e,
                            newBets[index].odds
                          );
                        }

                        props.setBets([...newBets]);
                      }}
                    >
                      <NumberInputField
                        fontSize="xl"
                        fontWeight="bold"
                        borderRadius="2px"
                        placeholder="0.00"
                        _placeholder={{
                          color: "gray",
                          fontWeight: "normal",
                        }}
                      />
                    </NumberInput>
                  </InputGroup>
                </Stack>
              </Flex>
            </Box>

            <Box pl="2">
              <Flex>
                <Stack spacing={0}>
                  <Text fontSize="xs" color="#000000">
                    {strings[language].betSlip.win}
                  </Text>
                  <InputGroup>
                    <NumberInput
                      id={bet.bettingLineId}
                      borderColor="#D3D3D3"
                      background="transparent"
                      color="#000000"
                      errorBorderColor="red.500"
                      isInvalid={
                        ((bet.riskAmount >
                          (getProfileData.data?.betLimit ?? 1000) ||
                          bet.riskAmount < 5) &&
                          checkIfBetIsDirty(bet.bettingLineId)) ||
                        emptyBets.includes(bet.bettingLineId)
                      }
                      _hover={{
                        border: "1px solid #D3D3D3",
                      }}
                      onBlur={() => addDirtyBet(bet.bettingLineId)}
                      onFocus={() => {
                        if (emptyBets.includes(bet.bettingLineId)) {
                          removeFromEmptyBets(bet.bettingLineId);
                        }
                      }}
                      value={+bet.winAmount.toFixed(2) || ""}
                      onChange={(e) => {
                        var newBets = props.bets;
                        const index = props.bets.findIndex((betItem) => {
                          return betItem.bettingLineId === bet.bettingLineId;
                        });

                        if (index > -1) {
                          newBets[index].riskAmount = calcRisk(
                            +e,
                            newBets[index].odds
                          );
                          newBets[index].winAmount = +e;
                        }

                        props.setBets([...newBets]);
                      }}
                    >
                      <NumberInputField
                        fontSize="xl"
                        fontWeight="bold"
                        borderRadius="2px"
                        placeholder="0.00"
                        _placeholder={{
                          color: "gray",
                          fontWeight: "normal",
                        }}
                      />
                    </NumberInput>
                  </InputGroup>
                </Stack>
              </Flex>
            </Box>
          </SimpleGrid>
        )}
        {emptyBets.includes(bet.bettingLineId) && isStraight && (
          <Box w="100%" bg="red.400" mt="1">
            <Text textAlign="center">
              {strings[language].betSlip.emptyStraightBetsWarning}
            </Text>
          </Box>
        )}
        {bet.riskAmount < 5 &&
          checkIfBetIsDirty(bet.bettingLineId) &&
          isStraight &&
          !emptyBets.includes(bet.bettingLineId) && (
            <Box w="100%" bg="red.400" mt="1">
              <Text textAlign="center">
                {`${strings[language].betSlip.minimumWagerWarning}(${denominationSymbol}5)`}
              </Text>
            </Box>
          )}
        {bet.riskAmount > (getProfileData.data?.betLimit ?? 1000) &&
          isStraight && (
            <Box w="100%" bg="red.400" mt="1">
              <Text textAlign="center">
                {`${strings[language].betSlip.maximumWagerWarning}(${
                  denominationSymbol + getProfileData.data?.betLimit ?? 1000
                })`}
              </Text>
            </Box>
          )}
      </Box>
    );
  });

  return (
    <Flex hideFrom="lg">
      {!props.collapseSlip && (
        <Box
          style={{
            boxShadow: "0px 3px 6px #00000029",
            margin: "auto",
          }}
          background="#FFFFFF"
          w="100%"
          h="calc(100% - 62px)"
          pos="fixed"
          left={0}
          bottom="62px"
          borderRadius="5px"
          boxShadow={{ base: "dark-lg", lg: "none" }}
          zIndex={600}
        >
          <Flex
            background="#646879"
            borderRadius="5px 5px 0px 0px"
            p="3"
            cursor="pointer"
            onClick={() => {
              props.setCollapseSlip(true);
              setIsStraight(true);
            }}
          >
            <Text color="#fff" fontSize="lg" fontWeight="bold">
              {`${strings[language].betSlip.betSlip} (${props.bets.length})`}
            </Text>
            <Spacer />
            <Icon
              background="#646879"
              color="white"
              aria-label="Hide Bet Slip"
              pt="1"
              fontSize="24px"
              as={FaChevronDown}
            />
          </Flex>

          <Tabs isLazy isFitted color="black" variant="unstyled">
            <TabList bg="white" w="100%">
              <Tab
                _selected={tabProps}
                color={orgSecondaryColor}
                border={`1px solid ${orgSecondaryColor}`}
                onClick={() => setIsStraight(true)}
              >
                {strings[language].betSlip.straight}
              </Tab>
              <Tab
                _selected={tabProps}
                color={orgSecondaryColor}
                border={`1px solid ${orgSecondaryColor}`}
                onClick={() => setIsStraight(false)}
              >
                {strings[language].betSlip.parlay}
              </Tab>
            </TabList>
          </Tabs>
          <Flex justify="center">
            <Box
              style={{ overflowY: "auto" }}
              maxHeight={
                isStraight ? "calc(100vh - 275px)" : "calc(100vh - 415px)"
              }
              height="100%"
              width="100%"
              pos="absolute"
            >
              {betList}
              {betList.length !== 0 && (
                <Box
                  py="2"
                  bg="#F6F6F6"
                  textAlign="center"
                  alignContent="center"
                  cursor="pointer"
                  color="#DD0D0D"
                  onClick={() => {
                    props.setBets([]);
                    setDirtyBets([]);
                    setEmptyBets([]);
                    setParlayRisk("");
                    setDirtyParlay(false);
                    window.localStorage.setItem("bets", JSON.stringify([]));
                  }}
                >
                  <HStack justify="center">
                    <BsTrash />
                    <Text>{strings[language].betSlip.removeAll}</Text>
                  </HStack>
                </Box>
              )}
            </Box>
            <Box
              pos="absolute"
              bottom={0}
              h={!isStraight ? "250px" : "0px"}
              w="100%"
            >
              {showParlay && !isStraight && (
                <Box
                  p="2"
                  background="#FFFFFF"
                  borderTop="1px solid #00000029"
                  borderBottom="1px solid #00000029"
                >
                  <Flex>
                    {parlayDisabled && (
                      <Text fontSize="md" color="#000000" fontWeight="bold">
                        {strings[language].betSlip.parlay}
                      </Text>
                    )}
                    {!parlayDisabled && (
                      <Flex>
                        <Text fontSize="md" color="#000000" fontWeight="bold">
                          {strings[language].betSlip.parlayHeader(
                            betList.length
                          )}
                        </Text>
                        <Text
                          fontSize="md"
                          fontWeight="bold"
                          color={orgPrimaryColor}
                          pl="2"
                        >
                          {parlayDisabled
                            ? ""
                            : oddsDisplay === "American"
                            ? metricToAmericanOdds(parlayOdds).toFixed(0)
                            : parlayOdds.toFixed(2)}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  {parlayDisabled && (
                    <Box p="2">
                      <Alert status="warning" variant="top-accent">
                        <AlertIcon />
                        {strings[language].betSlip.parlayWarning}
                      </Alert>
                    </Box>
                  )}
                  {!parlayDisabled && (
                    <SimpleGrid columns={2} spacing={2}>
                      <Box mx="auto">
                        <Flex>
                          <Stack spacing={0}>
                            <Text fontSize="xs" color="#000000" pt="2">
                              {strings[language].betSlip.parlayWager}
                            </Text>
                            <InputGroup>
                              <NumberInput
                                borderColor="#D3D3D3"
                                bg={
                                  parlayRisk !== "" && parlayRisk !== "0"
                                    ? `${orgPrimaryColor}40`
                                    : "#FFFFFF"
                                }
                                color="#000000"
                                _hover={{
                                  border: "1px solid #D3D3D3",
                                }}
                                errorBorderColor="red.500"
                                isInvalid={
                                  ((+parlayRisk >
                                    (getProfileData.data?.betLimit ?? 1000) ||
                                    +parlayRisk < 5) &&
                                    dirtyParlay) ||
                                  invalidParlay
                                }
                                onFocus={() => {
                                  +parlayRisk === 0 && setParlayRisk("");
                                  if (invalidParlay) {
                                    setInvalidParlay(false);
                                  }
                                }}
                                onBlur={() => setDirtyParlay(true)}
                                value={parlayRisk || ""}
                                onChange={(e) => {
                                  setParlayRisk(e);
                                  setParlayWin((+e * parlayOdds).toFixed(2));
                                }}
                              >
                                <NumberInputField
                                  fontSize="xl"
                                  fontWeight="bold"
                                  borderRadius="2px"
                                  placeholder="0.00"
                                  _placeholder={{
                                    color: "gray",
                                    fontWeight: "normal",
                                  }}
                                />
                              </NumberInput>
                            </InputGroup>
                          </Stack>
                        </Flex>
                      </Box>

                      <Box mx="auto">
                        <Flex>
                          <Stack spacing={0}>
                            <Text fontSize="xs" color="#000000" pt="2">
                              {strings[language].betSlip.parlayWin}
                            </Text>
                            <InputGroup>
                              <NumberInput
                                borderColor="#D3D3D3"
                                bg={
                                  parlayRisk !== "" && parlayRisk !== "0"
                                    ? `${orgPrimaryColor}40`
                                    : "#FFFFFF"
                                }
                                color="#000000"
                                errorBorderColor="red.500"
                                isInvalid={
                                  ((+parlayRisk >
                                    (getProfileData.data?.betLimit ?? 1000) ||
                                    +parlayRisk < 5) &&
                                    dirtyParlay) ||
                                  invalidParlay
                                }
                                _hover={{
                                  border: "1px solid #D3D3D3",
                                }}
                                onFocus={() => {
                                  +parlayWin === 0 && setParlayWin("");
                                  if (invalidParlay) {
                                    setInvalidParlay(false);
                                  }
                                }}
                                onBlur={() => setDirtyParlay(true)}
                                value={parlayWin || ""}
                                onChange={(e) => {
                                  setParlayWin(e);
                                  setParlayRisk((+e / parlayOdds).toFixed(2));
                                }}
                              >
                                <NumberInputField
                                  fontSize="xl"
                                  fontWeight="bold"
                                  borderRadius="2px"
                                  placeholder="0.00"
                                  _placeholder={{
                                    color: "gray",
                                    fontWeight: "normal",
                                  }}
                                />
                              </NumberInput>
                            </InputGroup>
                          </Stack>
                        </Flex>
                      </Box>
                    </SimpleGrid>
                  )}
                  {invalidParlay && (
                    <Box w="100%" bg="red.400" mt="1">
                      <Text textAlign="center">
                        {strings[language].betSlip.emptyParlayBetWarning}
                      </Text>
                    </Box>
                  )}
                  {+parlayRisk < 5 && dirtyParlay && !invalidParlay && (
                    <Box w="100%" bg="red.400" mt="1">
                      <Text textAlign="center">
                        {`${strings[language].betSlip.minimumWagerWarning}(${denominationSymbol}5)`}
                      </Text>
                    </Box>
                  )}
                  {+parlayRisk > (getProfileData.data?.betLimit ?? 1000) && (
                    <Box w="100%" bg="red.400" mt="1">
                      <Text textAlign="center">
                        {`${strings[language].betSlip.maximumWagerWarning}(${
                          denominationSymbol + getProfileData.data?.betLimit ??
                          1000
                        })`}
                      </Text>
                    </Box>
                  )}
                </Box>
              )}
              {betList.length == 1 && !isStraight && (
                <Box w="100%" bg="red.400" mt="1">
                  <Text textAlign="center">
                    {strings[language].betSlip.parlayBetsWarning}
                  </Text>
                </Box>
              )}
            </Box>
            {betList.length !== 0 && (
              <Box
                p="6"
                background="#FFFFFF"
                borderRadius="0 0 5px 5px"
                pos="absolute"
                bottom={0}
                w="100%"
              >
                <HStack justify="space-between" px={1} pb={2}>
                  <Flex>
                    <Text mr={1}>{strings[language].betSlip.oddsChange}</Text>
                    <IoIosInformationCircleOutline
                      fontSize="24px"
                      cursor="pointer"
                      onClick={() => oddsInfoOnOpen()}
                    />
                  </Flex>
                  <Switch
                    onChange={() => setAcceptOddsChange(!acceptOddsChange)}
                  />
                </HStack>
                {!isStraight && (
                  <Button
                    w="100%"
                    background={orgPrimaryColor}
                    color="#fff"
                    _hover={{
                      background: orgPrimaryColor,
                      color: "#FFFFFF",
                    }}
                    isDisabled={
                      window.localStorage.loggedIn !== "true" ||
                      props.bets.length < 2 ||
                      props.bets.length > 25
                    }
                    isLoading={props.placeBetLoading}
                    onClick={() => {
                      if (parlayRisk === "" || +parlayRisk === 0) {
                        setInvalidParlay(true);
                        return 0;
                      }
                      if (
                        +parlayRisk < 5 ||
                        +parlayRisk > getProfileData.data!.betLimit
                      ) {
                        return 0;
                      }
                      setBetType(BetType.parlay);
                      onOpen();
                    }}
                  >
                    {window.localStorage.loggedIn !== "true"
                      ? strings[language].betSlip.login
                      : strings[language].betSlip.placeParlayBet(
                          props.bets.length
                        )}
                  </Button>
                )}
                {isStraight && (
                  <Button
                    w="100%"
                    background={orgPrimaryColor}
                    color="#fff"
                    _hover={{
                      background: orgPrimaryColor,
                      color: "#FFFFFF",
                    }}
                    isDisabled={
                      window.localStorage.loggedIn !== "true" ||
                      props.bets.length > 25
                    }
                    isLoading={props.placeBetLoading}
                    onClick={() => {
                      checkEmptyBets(props);
                      if (straightBetsInvalid) {
                        return 0;
                      }
                      setBetType(BetType.straight);
                      onOpen();
                    }}
                  >
                    {window.localStorage.loggedIn !== "true"
                      ? strings[language].betSlip.login
                      : strings[language].betSlip.placeStraightBet(
                          props.bets.length
                        )}
                  </Button>
                )}
              </Box>
            )}
            <Modal isOpen={oddsInfoIsOpen} onClose={oddsInfoOnClose} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  {strings[language].betSlip.oddsChangeHeader}
                </ModalHeader>
                <ModalBody>
                  <Text>{strings[language].betSlip.oddsChangeBody}</Text>
                </ModalBody>
                <ModalFooter>
                  <Button
                    bg={orgSecondaryColor}
                    color="white"
                    _hover={{ bg: `${orgSecondaryColor}90` }}
                    onClick={() => oddsInfoOnClose()}
                  >
                    {strings[language].betSlip.okay}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Box display="none">
              <ConfirmBetModal
                cancelRef={cancelRef}
                onClose={onClose}
                betType={betType}
                acceptOddsChange={acceptOddsChange}
                betConfirm={betConfirm}
                parlayWinAmount={+parlayWin}
                parlayOdds={parlayOdds}
                parlayRisk={parlayRisk}
                parlayDisabled={parlayDisabled}
                confirmInput={confirmInput}
                setConfirmInput={setConfirmInput}
                timeoutID={timeoutID}
                setTimeoutID={setTimeoutID}
                locationLoading={locationLoading}
                setLocationLoading={setLocationLoading}
                geoComplyData={geoComplyData}
                setGeoComplyData={setGeoComplyData}
                isOpen={isOpen}
                setPlaceBetLoading={props.setPlaceBetLoading!}
                bets={props.bets}
                placeBet={props.placeBet!}
              />
            </Box>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};
