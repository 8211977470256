import dayjs, {type Dayjs} from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {LanguageKey, strings} from './languageStrings';

declare module 'dayjs' {
  interface Dayjs {
    calendar: any;
    tz: any;
  }
}

var calendar = require('dayjs/plugin/calendar');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');
var advanced = require('dayjs/plugin/advancedFormat');
var enLocale = require('dayjs/locale/en');
var frLocale = require('dayjs/locale/fr');

dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
dayjs.extend(customParseFormat);

dayjs.locale('en', enLocale);
dayjs.locale('fr', frLocale);

export const startOfMonth = (date: Dayjs) => {
  return dayjs(date).startOf('month'); // identifier with type completions
};

export const relativeTime = (date: Dayjs, languageKey: LanguageKey) => {
  const dateCalcs = strings[languageKey].dateCalcs;

  return dayjs(date)
    .locale(languageKey)
    .calendar(null, {
      // add `z` to the end of any of these strings for time zone
      sameDay: `[${dateCalcs.today} @] h:mm A`, // The same day ( Today at 2:30 AM )
      nextDay: `[${dateCalcs.tomorrow} @] h:mm A`, // The next day ( Tomorrow at 2:30 AM )
      nextWeek: 'ddd' + ' @ ' + 'h:mm A', // The next week ( Sun at 2:30 AM )
      lastDay: `[${dateCalcs.yesterday}]`, // The day before ( Yesterday at 2:30 AM )
      lastWeek: `[${dateCalcs.last}] dddd`, // Last week ( Last Monday at 2:30 AM )
      sameElse: 'MMM DD' + ' @ ' + 'h:mm A', // Everything else ( 7/10/2011 )
    });
};

export function currentTimestampLong() {
  return dateToTimestampStrLong(new Date());
}

export function dateToTimestampStrLong(date: Date) {
  var day = date.getDate();
  var hour = date.getHours();
  var min = date.getMinutes();

  var hourString;
  var amPmString;

  let monthString = date.toLocaleString('default', {month: 'short'});
  let dayString = day;

  if (hour > 12) {
    hourString = +hour - 12;
    amPmString = 'PM';
  } else if (hour == 12) {
    hourString = 12;
    amPmString = 'PM';
  } else {
    hourString = +hour;
    amPmString = 'AM';
  }

  let minString = (min < 10 ? '0' : '') + min;
  // let timezoneString = date
  //   .toLocaleTimeString('en-us', {timeZoneName: 'short'})
  //   .split(' ')[2];

  var str = `${monthString} ${dayString}, ${hourString}:${minString}${amPmString}`;

  return str;
}

export function sqlTimestampToDate(sqlTimestamp: string) {
  var t = sqlTimestamp.split(/[- :TZ]/);

  return new Date(Date.UTC(+t[0], +t[1] - 1, +t[2], +t[3], +t[4], +t[5]));
}

export function sqlTimestampToTimestampStrLong(sqlTimestamp: string) {
  return dateToTimestampStrLong(sqlTimestampToDate(sqlTimestamp));
}