import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Card } from "components/shared/Card";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ValidateMFA } from "models";
import { useMutation } from "react-query";
import API from "services/API";
import macOS from "./images/macOS.svg";
import windows from "./images/windows.svg";
import { GeoComplyContext, GeoContextType } from "context/geoComplyContext";

function MFA() {
  const {
    getGeoComply,
    geoComplyData,
    geoComplyError,
    geoLocationRetrieved,
    setGeoComplyData,
    tempJwtToken,
    setTempJwtToken,
  }: GeoContextType = GeoComplyContext();
  const [downloadModal, setDownloadModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<string>("");
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const history = useHistory();
  const toast = useToast();

  const userId: string = history.location.state as string;

  const { register, handleSubmit } = useForm<ValidateMFA>({
    mode: "onChange",
  });

  const { mutate } = useMutation(API.validateMFA, {
    onSuccess: (data) => {
      setIsLoading("Checking Location...");
      if (!!parseInt(process.env.REACT_APP_ENABLE_GEO!)) {
        setTempJwtToken(data.access_token);
        checkLocation();
      } else {
        completeLogin(data.access_token);
      }
    },
    onError: () => {
      setIsLoading("");
      toast({
        title: "Error",
        description: "Invalid 2FA Verification Code",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });
    },
  });

  const completeLogin = (access_token?: string) => {
    setIsLoading("");
    window.localStorage.loggedIn = true;
    window.localStorage.loginTime = new Date();
    window.localStorage.jwt = access_token ? access_token : tempJwtToken;
    history.push("/");
    setTempJwtToken("");
  };

  const checkLocation = () => {
    console.log("Checking location...");
    getGeoComply("Login", setDownloadModal);
    // Get geo comply will have 20 seconds to provide a response,
    // Otherwise the error toast will be displayed
    const setTimeoutId = setTimeout(() => {
      if (!geoComplyData) {
        console.log("geocomply error msg: ", geoComplyError);
        toast({
          title: "Error",
          description: geoComplyError
            ? `Invalid region: ${geoComplyError}`
            : "Timeout getting geo-packet",
          position: "bottom-right",
          status: "error",
          isClosable: true,
        });
        setIsLoading("");
      }
    }, 20000);
    setTimeoutID(setTimeoutId);
  };

  useEffect(() => {
    /** Use effect catches geoComplyData when location check is complete.
     * Timeout is cleared, and navigation is approved
     *  */
    if (geoComplyData) {
      timeoutID && clearTimeout(timeoutID);
      completeLogin();
      setGeoComplyData(null);
    }
  }, [geoComplyData]);

  useEffect(() => {
    /** Use effect catches geoLocationRetrieved when location check is complete
     * If location is invalid timeout is cleared, and error toast is displayed
     */
    if (geoLocationRetrieved && geoComplyError !== "" && !geoComplyData) {
      timeoutID && clearTimeout(timeoutID);
      setIsLoading("");
      toast({
        title: "Error",
        description: geoComplyError
          ? `Invalid region: ${geoComplyError}`
          : "Timeout getting geo-packet",
        position: "bottom-right",
        status: "error",
        isClosable: true,
      });
    }
  }, [geoLocationRetrieved]);

  const onSubmit = (data: ValidateMFA) => {
    const userLogin = {
      ...data,
      userId,
    };
    mutate(userLogin);
  };

  return (
    <Box bgImage={"BoysBettingBar.jpg"} bgSize="cover">
      <Box
        backgroundColor="#377E2165"
        minH="100vh"
        px={{ base: "4", lg: "8" }}
        display="flex"
        alignItems="center"
      >
        <Box mx="auto" maxW="480px" w="100%">
          <Card bgColor="white" style={{ borderRadius: "inherit" }}>
            <Box h="150px" display="flex" alignItems="center">
              <img
                src="logo.png"
                style={{
                  width: "270px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="BetBooth Logo"
              />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="6" px="2">
                <FormControl>
                  <FormLabel textAlign="center" color="black">
                    Please enter your 2FA code sent to your email
                  </FormLabel>
                  <Input
                    required
                    tabIndex={1}
                    outline="1px solid lightgray"
                    h="53px"
                    color="black"
                    backgroundColor="#FAFAFA"
                    {...register("MFAToken")}
                  />
                </FormControl>
                <Button
                  type="submit"
                  colorScheme="blue"
                  color="white"
                  h="53"
                  size="lg"
                  fontSize="md"
                  bgColor="#47AB37"
                  tabIndex={4}
                  isLoading={!!isLoading}
                  loadingText={isLoading}
                >
                  Log in
                </Button>
              </Stack>
            </form>
          </Card>
        </Box>
      </Box>
      <Modal isOpen={downloadModal} onClose={() => setDownloadModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader textAlign="center">
            No Location Software Detected
          </ModalHeader>
          <Divider mb="10px" />
          <ModalBody textAlign="center">
            <Text>
              We've detected you do not have the required location software to
              run this application. Please install the downloaded software and
              refresh to try again.
            </Text>
            <Spacer h="20px" />
            <Text>
              If the download did not automatically start, please click on your
              OS below to begin the download.
            </Text>
            <Spacer h="20px" />
            <Flex justify={"space-around"}>
              <Flex
                direction={"column"}
                _hover={{ opacity: "0.6" }}
                transition="0.2s"
                onClick={() => {
                  window.open(
                    "https://stg-ums.geocomply.net/installer/url?id=" +
                      process.env.REACT_APP_GEOCOMPLY_INSTALLER_ID +
                      "&os=win&version=" +
                      "3.1.1.3",
                    "_self"
                  );
                }}
              >
                <Image src={windows} cursor="pointer" />
                <Text fontSize={"12px"} mt="3px">
                  Windows
                </Text>
              </Flex>
              <Flex
                direction={"column"}
                transition="0.2s"
                _hover={{ opacity: "0.6" }}
                onClick={() => {
                  window.open(
                    "https://stg-ums.geocomply.net/installer/url?id=" +
                      process.env.REACT_APP_GEOCOMPLY_INSTALLER_ID +
                      "&os=mac&version=" +
                      "3.1.1.3",
                    "_self"
                  );
                }}
              >
                <Image src={macOS} cursor="pointer" />
                <Text fontSize={"12px"} mt="3px">
                  Mac OS
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button w="100%" onClick={() => history.push("/")}>
              Refresh Page
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MFA;
