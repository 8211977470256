import { Link, useColorModeValue as mode } from "@chakra-ui/react";
import { NavLink as RouterLink } from "react-router-dom";

interface NavbarLinkProps {
  label: string;
  to: string;
  active: boolean;
}

export const NavbarLink = (props: NavbarLinkProps) => {
  return (
    <RouterLink to={props.to}>
      <Link
        aria-current={props.active ? "page" : undefined}
        color="#FFFFFF70"
        fontWeight="normal"
        _activeLink={{
          color: mode("#FFFFFF", "#FFFFFF"),
          borderBottom: "1px solid #FFFFFF",
          paddingBottom: "6",
        }}
      >
        {props.label}
      </Link>
    </RouterLink>
  );
};
