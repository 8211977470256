import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  FormControl,
  HStack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { GeoComplyDataDTO } from "context/geoComplyContext";
import { Bet, PlaceBetDTO, PlaceBetTicketDTO } from "models/Bets";
import { metricToAmericanOdds } from "utils/oddsCalc";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { UseMutateFunction } from "react-query";
import { BetType } from "./BetSlip";
import { useRecoilValue } from "recoil";
import {
  denominationSymbolState,
  languageState,
  oddsDisplayState,
  orgPrimaryColorState,
  userState,
} from "store/Store";
import { currencyFormatter } from "utils/formatters";
import { strings, LanguageKey } from "utils/languageStrings";

interface ConfirmBetModalProps {
  isOpen: boolean;
  setPlaceBetLoading: Dispatch<SetStateAction<boolean>>;
  bets: Bet[];
  placeBet: UseMutateFunction<any, unknown, PlaceBetTicketDTO, unknown>;
  cancelRef: any;
  betType: BetType | undefined;
  acceptOddsChange: boolean;
  betConfirm: JSX.Element[];
  parlayWinAmount: number;
  parlayOdds: number;
  parlayRisk: string;
  parlayDisabled: boolean;
  confirmInput: boolean;
  timeoutID: NodeJS.Timeout | undefined;
  setTimeoutID: React.Dispatch<
    React.SetStateAction<NodeJS.Timeout | undefined>
  >;
  geoComplyData: GeoComplyDataDTO | null;
  setGeoComplyData: React.Dispatch<
    React.SetStateAction<GeoComplyDataDTO | null>
  >;
  setConfirmInput: React.Dispatch<React.SetStateAction<boolean>>;
  locationLoading: string;
  setLocationLoading: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
}

export default function ConfirmBetModal({
  isOpen,
  setPlaceBetLoading,
  bets,
  placeBet,
  cancelRef,
  betType,
  acceptOddsChange,
  betConfirm,
  parlayWinAmount,
  parlayOdds,
  parlayRisk,
  parlayDisabled,
  confirmInput,
  setConfirmInput,
  timeoutID,
  setTimeoutID,
  locationLoading,
  setLocationLoading,
  geoComplyData,
  setGeoComplyData,
  onClose,
}: ConfirmBetModalProps) {
  useEffect(() => {
    /** Use effect catches geoComplyData when location check is complete.
     * Timeout is cleared, and loading is cleared.
     *  */
    if (geoComplyData) {
      setLocationLoading("");
      timeoutID && clearTimeout(timeoutID);
      betModalTimeout();
    }
  }, [geoComplyData]);

  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const oddsDisplay = useRecoilValue(oddsDisplayState);
  const denominationSymbol = useRecoilValue(denominationSymbolState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const user = useRecoilValue(userState);

  const totalStraightRiskAmount = bets.reduce(
    (total, bet) => total + (bet.riskAmount || 0),
    0
  );

  var taxRate = 0;

  if (user.org) {
    taxRate = user.org.taxRate;
  }

  var straightTaxAmount = taxRate * totalStraightRiskAmount;
  var straightTotal = straightTaxAmount + totalStraightRiskAmount;

  var parlayTaxAmount = taxRate * +parlayRisk;
  var parlayTotal = parlayTaxAmount + +parlayRisk;

  const betModalTimeout = () => {
    /** Timeout the bet modal 5 minutes after geolocation is retrived if no bet placed.
     * Timeout is cleared, loading is cleared, and geoComplyData is cleared
     */
    setTimeout(() => {
      onClose();
      setLocationLoading("");
      setConfirmInput(false);
      setGeoComplyData(null);
    }, 300000);
  };

  const clickPlaceBet = () => {
    setPlaceBetLoading(true);

    var dto: PlaceBetTicketDTO = {
      bets: [],
      riskAmount: +parlayRisk,
      geoPacket: geoComplyData!,
      ticketType: betType === BetType.parlay ? "Parlay" : "Straight",
      acceptOddsChange,
    };

    dto.bets = bets.map((bet) => {
      var dto: PlaceBetDTO = {
        riskAmount: bet.riskAmount,
        expectedProfit: bet.winAmount,
        bettingLineId: bet.bettingLineId,
        externalId: bet.externalId,
        odds: bet.odds,
      };

      return dto;
    });
    placeBet(dto);
    onClose();
    setConfirmInput(false);
    setGeoComplyData(null);
  };

  return (
    <Box pb="2" minWidth="500px">
      <AlertDialog
        size="xl"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        closeOnOverlayClick={false}
        onClose={onClose}
      >
        <AlertDialogOverlay backdropFilter="blur(10px)">
          <AlertDialogContent mt="8vh" bg="whiteAlpha.900">
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="black">
              Review&nbsp;
              <Text as={"span"} color={orgPrimaryColor}>
                {betType === BetType.parlay ? "Parlay" : "Straight"}
              </Text>
              &nbsp;Bet
            </AlertDialogHeader>

            <AlertDialogBody>
              <Table size={{ base: "xs", sm: "sm", md: "md" }}>
                <Thead>
                  <Tr>
                    <Th>
                      <Text color="#000000">
                        {strings[language].confirmBetModal.name}
                      </Text>
                    </Th>
                    <Th>
                      <Text color="#000000">
                        {strings[language].confirmBetModal.odds}
                      </Text>
                    </Th>
                    {betType === BetType.straight && (
                      <Th>
                        <Text color="#000000">
                          {strings[language].myBets.wager}
                        </Text>
                      </Th>
                    )}
                    {betType === BetType.straight && (
                      <Th>
                        <Text color="#000000">
                          {strings[language].myBets.payout}
                        </Text>
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>{betConfirm}</Tbody>
              </Table>
              {betType === BetType.straight && (
                <>
                  <Divider w="100%" mb="5" borderColor="#000000" />
                  <VStack
                    mb="20px"
                    pl={{ base: 0, sm: 4, md: 6 }}
                    pr={{ base: 2, sm: 12, md: 8 }}
                  >
                    {taxRate == 0 && (
                      <HStack
                        w="100%"
                        pr={{ base: 5, sm: 2, md: 4 }}
                        justify={"space-between"}
                      >
                        <Text color="#000000" fontWeight="bold">
                          {strings[language].confirmBetModal.total}
                        </Text>
                        <Text color="#000000">
                          {denominationSymbol +
                            currencyFormatter.format(totalStraightRiskAmount)}
                        </Text>
                      </HStack>
                    )}
                    {taxRate > 0 && (
                      <>
                        <HStack w="100%" justify={"space-between"}>
                          <Text color="#000000" fontWeight="bold">
                            {strings[language].confirmBetModal.subTotal}
                          </Text>
                          <Text color="#000000">
                            {denominationSymbol +
                              currencyFormatter.format(totalStraightRiskAmount)}
                          </Text>
                        </HStack>
                        <HStack w="100%" justify={"space-between"}>
                          <Text color="#000000" fontWeight="bold">{`Taxes (${
                            taxRate * 100
                          }%)`}</Text>
                          <Text color="#000000">
                            {denominationSymbol +
                              currencyFormatter.format(straightTaxAmount)}
                          </Text>
                        </HStack>
                        <HStack w="100%" justify={"space-between"}>
                          <Text color="#000000" fontWeight="bold">
                            {strings[language].confirmBetModal.total}
                          </Text>
                          <Text color="#000000">
                            {denominationSymbol +
                              currencyFormatter.format(straightTotal)}
                          </Text>
                        </HStack>
                      </>
                    )}
                  </VStack>
                </>
              )}
              {betType === BetType.parlay && (
                <>
                  <Divider w="100%" mb="5" borderColor="#000000" />
                  <VStack mb="20px" px={{ base: 0, sm: 4, md: 6 }}>
                    {taxRate == 0 && (
                      <HStack w="100%" justify={"space-between"}>
                        <Text color="#000000" fontWeight="bold">
                          {strings[language].myBets.wager}
                        </Text>
                        <Text color="#000000">
                          {denominationSymbol +
                            currencyFormatter.format(+parlayRisk)}
                        </Text>
                      </HStack>
                    )}
                    {taxRate > 0 && (
                      <>
                        <HStack w="100%" justify={"space-between"}>
                          <Text color="#000000" fontWeight="bold">
                            {strings[language].confirmBetModal.subTotal}
                          </Text>
                          <Text color="#000000">
                            {denominationSymbol +
                              currencyFormatter.format(+parlayRisk)}
                          </Text>
                        </HStack>
                        <HStack w="100%" justify={"space-between"}>
                          <Text color="#000000" fontWeight="bold">{`Taxes (${
                            taxRate * 100
                          }%)`}</Text>
                          <Text color="#000000">
                            {denominationSymbol +
                              currencyFormatter.format(parlayTaxAmount)}
                          </Text>
                        </HStack>
                        <HStack w="100%" justify={"space-between"}>
                          <Text color="#000000" fontWeight="bold">
                            {strings[language].confirmBetModal.total}
                          </Text>
                          <Text color="#000000">
                            {denominationSymbol +
                              currencyFormatter.format(parlayTotal)}
                          </Text>
                        </HStack>
                      </>
                    )}
                    <HStack w="100%" justify={"space-between"}>
                      <Text color="#000000" fontWeight="bold">
                        {strings[language].confirmBetModal.totalOdds}
                      </Text>
                      <Text color={orgPrimaryColor}>
                        {oddsDisplay === "American"
                          ? metricToAmericanOdds(parlayOdds).toFixed(0)
                          : parlayOdds.toFixed(2)}
                      </Text>
                    </HStack>
                    <HStack w="100%" justify={"space-between"}>
                      <Text color="#000000" fontWeight="bold">
                        {strings[language].myBets.payout}
                      </Text>
                      <Text color="#000000">
                        {denominationSymbol +
                          currencyFormatter.format(parlayWinAmount)}
                      </Text>
                    </HStack>
                  </VStack>
                </>
              )}

              <Divider mb="5" borderColor="black" />
            </AlertDialogBody>

            <AlertDialogBody>
              {
                <Box
                  display={
                    parlayDisabled && betType === BetType.parlay
                      ? "none"
                      : "block"
                  }
                >
                  <Center>
                    <Text color="#000000" mb="5">
                      {strings[language].confirmBetModal.question}
                    </Text>
                  </Center>

                  <FormControl>
                    <Center>
                      <Checkbox
                        isRequired
                        size={"lg"}
                        defaultChecked={confirmInput}
                        onChange={(e) => {
                          setConfirmInput(!confirmInput);
                          delete window.localStorage.bets;
                        }}
                      >
                        <Text color="#000000">
                          {strings[language].confirmBetModal.confirm}
                        </Text>
                      </Checkbox>
                    </Center>
                  </FormControl>
                </Box>
              }

              {parlayDisabled && betType === BetType.parlay && (
                <Box p="2">
                  <Alert status="warning" variant="top-accent">
                    <AlertIcon />
                    {strings[language].betSlip.parlayWarning}
                  </Alert>
                </Box>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                color="#000000"
                onClick={() => {
                  onClose();
                  setGeoComplyData(null);
                  setConfirmInput(false);
                  setLocationLoading("");
                  timeoutID && clearTimeout(timeoutID);
                }}
              >
                {strings[language].confirmBetModal.cancel}
              </Button>
              {/* {!confirmInput ||
            (parlayDisabled && (
              <Button isDisabled={true} ml={3}>
                Place Bet
              </Button>
            ))} */}
              <Button
                // disabled={!!locationLoading || !confirmInput} -- GEOLOCATION CHECK DISABLED
                _hover={{ background: orgPrimaryColor, opacity: 0.5 }}
                isDisabled={!confirmInput}
                color="white"
                background={orgPrimaryColor}
                onClick={() => {
                  clickPlaceBet();
                }}
                ml={3}
                // isLoading={!!locationLoading} -- GEOLOCATION CHECK DISABLED
                // loadingText={locationLoading} -- GEOLOCATION CHECK DISABLED
                w="200px"
              >
                {strings[language].confirmBetModal.placeBet}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
