import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { Base } from "components/Base";
import Login from "components/auth/Login";
import Welcome from "components/auth/Welcome";
import ForgotPassword from "components/auth/ForgotPassword";
import ResetPassword from "components/auth/ResetPassword";
import axios from "axios";
import { RecoilRoot } from "recoil";
import { withRouter } from "react-router";
import { GeoComplyContextProvider } from "context/geoComplyContext";
import MFA from "components/auth/MFA";
import ValidateLoginToken from "components/auth/ValidateLoginToken";
import Register from "components/auth/Register";
import LocationRestricted from "components/auth/LocationRestricted";

/*axios.interceptors.response.use(
  (response) => {
    //const loggedIn = localStorage.getItem('loggedIn');
    return response;
  },
  (error) => {
    //const loggedIn = localStorage.getItem('loggedIn');
    if (
      error.response.status === 401 &&
      !error.response.config.url.includes("/users/login/mobile")
    ) {
      console.log(error.response);
      //window.location.href = "/welcome";
    }
    throw error;
  }
);*/

const queryClient = new QueryClient();

function App() {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <GeoComplyContextProvider>
          <Router>
            <Switch>
              <Route exact path="/welcome" component={withRouter(Welcome)} />

              <Route exact path="/login" component={withRouter(Login)} />

              <Route
                exact
                path="/register"
                component={withRouter(Register)}
              />

              <Route
                exact
                path="/forgot-password"
                component={withRouter(ForgotPassword)}
              />

              <Route
                exact
                path="/reset-password"
                component={withRouter(ResetPassword)}
              />

              <Route
                exact
                path="/validateLoginToken"
                component={withRouter(ValidateLoginToken)}
              />

              <Route
                exact
                path="/location-restricted"
                component={withRouter(LocationRestricted)}
              />

              <Route path="/mfa" component={withRouter(MFA)} />

              <Route path="/" component={withRouter(Base)} />
            </Switch>
          </Router>
        </GeoComplyContextProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
