import { UserProfileDTO } from "models";
import { BettingLineDTO } from "models/BettingLine";
import { SportDTO } from "models/Sports";
import { atom } from "recoil";
import { LanguageKey } from "../utils/languageStrings";

export const userState = atom({ key: "user", default: new UserProfileDTO() });
export const sportsState = atom({ key: "sports", default: [] as SportDTO[] });
export const betsState = atom({ key: "bets", default: 0 });
export const loginTime = atom({
  key: "loginTime",
  default: window.localStorage.loginTime,
});
export const leagueIdState = atom({ key: "leagueId", default: 0 });
export const modalHandler = atom({ key: "modalHandler", default: false });
export const tcAccepted = atom<boolean>({ key: "tcAccepted", default: false });
export const languageState = atom<LanguageKey>({
  key: "language",
  default: "en",
});
export const orgPrimaryColorState = atom({
  key: "orgPrimaryColor",
  default: "#007cff",
});
export const orgSecondaryColorState = atom({
  key: "orgSecondaryColor",
  default: "#00458f",
});
export const orgTertiaryColorState = atom({
  key: "orgTertiaryColor",
  default: "",
});
export const orgLogoUrlState = atom({
  key: "orgLogoUrl",
  default:
    "https://betbooth-public.s3.ca-central-1.amazonaws.com/org-branding/bb_logo_blue_white_text.png",
});
export const orgBadgeUrlState = atom({
  key: "orgBadgeUrl",
  default:
    "https://betbooth-public.s3.ca-central-1.amazonaws.com/org-branding/bb_badge_blue.png",
});
export const oddsDisplayState = atom({
  key: "oddsDisplay",
  default: "American",
});
export const layoutState = atom({
  key: "layout",
  default: "American",
});
export const denominationSymbolState = atom({
  key: "denominationSymbol",
  default: "$",
});
export const fantasyEnabledState = atom<boolean>({
  key: "fantasyEnabled",
  default: false,
});
export const fantasyBetsStore = atom<BettingLineDTO[]>({
  key: "fantasyBets",
  default: [],
});
