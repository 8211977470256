import { Spinner } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { orgPrimaryColorState } from "store/Store";

function BBSpinner() {
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color={orgPrimaryColor}
      size="xl"
    />
  );
}

export default BBSpinner;
