import {
  Link,
  useColorModeValue as mode,
  Image,
  Center,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link as RouteLink } from "react-router-dom";

interface MobileNavLinkProps {
  label: string;
  href: string;
  icon: JSX.Element;
  active: boolean;
  onClick: () => void;
}

export const MobileNavLink = (props: MobileNavLinkProps) => {
  return (
    <RouteLink to={props.href} onClick={props.onClick}>
      <Center color="white" fontSize="25px">
        {props.icon}
      </Center>
      <Link
        fontSize="14px"
        color="white"
        aria-current={props.active ? "page" : undefined}
        _activeLink={{
          color: mode("#000", "#000"),
        }}
      >
        {props.label}
      </Link>
    </RouteLink>
  );
};
