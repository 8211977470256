import React, { createContext, useContext, useState } from "react";
import { GeoComplyAPI } from "services/GeoComplyAPI";

export interface GeoComplyDataDTO {
  region: string;
  regionCode: string;
}

const geoComplyContext = createContext<any>({});

export const GeoComplyContextProvider = ({ children }: any) => {
  /** GeoComply context provider allows for all geoComply states and functions
   *  to be accessible throughout the whole application
   */
  const [geoComplyData, setGeoComplyData] = useState<GeoComplyDataDTO | null>(
    null
  );
  const [gettingGeoComply, setGettingGeoComply] = useState(false);
  const [geoPacket, setGeoPacket] = React.useState();
  const [geoComplyError, setGeoComplyError] = useState("");
  const [geoLocationRetrieved, setGeoLocationRetrieved] = useState(false)

  // Below will only populate briefly for login to store user data while
  // Location is calculated. It is removed afterwards, and shouldn't be used
  // as a current stateful version of the session JWT
  const [tempJwtToken, setTempJwtToken] = useState("");

  const getGeoComply = async (
    reason: string,
    setDownloadModal?: React.Dispatch<React.SetStateAction<boolean>> | undefined
  ) => {
    /** If the user is logged in, then  */
    if (
      !geoComplyData?.region &&
      !gettingGeoComply &&
      window.localStorage.email &&
      !!parseInt(process.env.REACT_APP_ENABLE_GEO!)
    ) {
      setGettingGeoComply(true);
      const geoEnv = "US7";
      console.log("Getting geolicense...");
      const geoLicense = await GeoComplyAPI.getGeoLicense(geoEnv);
      if (!geoLicense) {
        setGeoComplyError("Error retrieving license");
        return;
      }
      console.log("Geolicense: ", geoLicense);
      const geoComplyConfig = {
        installerID: process.env.REACT_APP_GEOCOMPLY_INSTALLER_ID,
        envId: process.env.REACT_APP_GEOCOMPLY_ENV_ID,
        license: geoLicense,
        userId: window.localStorage.email,
        reason: reason,
      };
      console.log("Geocomply config: ", geoComplyConfig);
      console.log("Getting geo-packet...");
      await GeoComplyAPI.getGeoPacket(
        geoComplyConfig,
        setGeoPacket,
        setGeoComplyError,
        setDownloadModal
      );
      setTimeout(() => {
        // Only allow 1 req every 3 seconds to
        setGettingGeoComply(false);
      }, 3000);
    }
  };

  const unencryptGeoPacket = async (geoPacket: any) => {
    console.log("Geo-packet: ", geoPacket);
    console.log("Decoding geoPacket...");
    const decodedPacket = await GeoComplyAPI.unencryptGeoPacket(
      geoPacket,
      tempJwtToken
    );
    if (!decodedPacket) {
      setGeoComplyError("Error decoding geo-packet");
    } else {
      setGeoComplyError("");
    }
    console.log("Decoded geoPacket: ", decodedPacket);
    setGeoComplyData(decodedPacket);
    setGeoLocationRetrieved(true)
    setGettingGeoComply(false);
  };

  // When the geocomply package retrieves a packet, this useEffect will be triggered
  React.useEffect(() => {
    if (geoPacket) unencryptGeoPacket(geoPacket);
  }, [geoPacket]);

  return (
    <geoComplyContext.Provider
      /** The values specified below are available globally throughout the app.
       * They can be accessed as such:
       * const { getGeoComply, geoComplyData, ... } = GeoComplyContext();
       */
      value={{
        geoComplyData,
        setGeoComplyData,
        getGeoComply,
        geoLocationRetrieved,
        gettingGeoComply,
        geoComplyError,
        tempJwtToken,
        setTempJwtToken,
      }}
    >
      {children}
    </geoComplyContext.Provider>
  );
};

export const GeoComplyContext = () => useContext(geoComplyContext);

export type GeoContextType = {
  geoComplyData: GeoComplyDataDTO | null;
  setGeoComplyData: React.Dispatch<
    React.SetStateAction<GeoComplyDataDTO | null>
  >;
  getGeoComply: (
    reason: string,
    setDownloadModal?: React.Dispatch<React.SetStateAction<boolean>> | undefined
  ) => Promise<void>;
  geoLocationRetrieved: boolean;
  gettingGeoComply: boolean;
  geoComplyError: string;
  tempJwtToken: string;
  setTempJwtToken: React.Dispatch<React.SetStateAction<string>>;
};
