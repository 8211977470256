import {
  Box,
  Divider,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  layout,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { NavLink } from "components/shared/NavLink";
import { UserProfile } from "components/shared/UserProfile";
import { GeoComplyContext, GeoContextType } from "context/geoComplyContext";
import { SportDTO } from "models/Sports";
import { NavLink as RouterLink } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { FaWandMagicSparkles } from "react-icons/fa6";
import API from "services/API";
import {
  orgSecondaryColorState,
  orgPrimaryColorState,
  leagueIdState,
  orgLogoUrlState,
  languageState,
  layoutState,
  fantasyEnabledState,
} from "store/Store";
import { strings, LanguageKey } from "utils/languageStrings";
import { sportIconsFilter } from "utils/sportIconsFilter";
import { useEffect, useState } from "react";

interface SidebarProps {
  display?: any;
  width?: string;
  height?: string;
  bg?: string;
  border?: string;
  onClose?: () => void;
}

export const Sidebar = (props: SidebarProps) => {
  const location = useLocation();
  const { setGeoComplyData }: GeoContextType = GeoComplyContext();
  let orgLogoUrl = useRecoilValue(orgLogoUrlState);
  const orgPrimaryColor = useRecoilValue(orgPrimaryColorState);
  const orgSecondaryColor = useRecoilValue(orgSecondaryColorState);
  const fantasyEnabled = useRecoilValue(fantasyEnabledState);
  const selectedLeagueId = useRecoilValue(leagueIdState);
  const language = useRecoilValue<LanguageKey>(languageState);
  const layout = useRecoilValue(layoutState);
  const [leagueId, setLeagueId] = useState(0);
  const sportsData = useQuery("getSports", API.getSports, {
    refetchOnWindowFocus: false,
  });
  const fantasyLeaguesData = useQuery("getLeagues", API.getFantasyLeagues, {
    refetchOnWindowFocus: false,
  });
  var path = location.pathname.split("/");
  var selectedSportId = "1";

  if (path.length <= 2 && !fantasyEnabled) {
    if (path[1] === "home") {
      selectedSportId = path[1];
    }
  }

  if (path.length > 2 && !fantasyEnabled) {
    if (path[1] === "sports") {
      selectedSportId = path[2];
    }
  }

  if (path.length == 4 && fantasyEnabled) {
    selectedSportId = path[3];
  }

  var fantasyActive = false;
  if (location.pathname.includes("fantasy")) {
    fantasyActive = true;
  }

  /* Catch league filter changes and update corresponding sidebar link */
  useEffect(() => {
    setLeagueId(selectedLeagueId);
  }, [selectedLeagueId]);

  var sports: SportDTO[] = [];
  var sportElements: JSX.Element[] = [];
  var leagueElements: JSX.Element[] = [];
  var fantasyLeagueElements: JSX.Element[] = [];

  if (sportsData.status == "success") {
    sports = sportsData.data;

    sportElements = sports.map((sport) => {
      let icon: string = sportIconsFilter(sport.name);

      if (sport.leagues !== null) {
        leagueElements = sport.leagues.map((league) => {
          return (
            <Link
              key={league.id}
              display="block"
              py="2"
              pl="6"
              my="1"
              borderRadius="md"
              transition="all 0.3s"
              fontWeight="medium"
              fontSize={"sm"}
              userSelect="none"
              color={
                league.id === leagueId && sport.id === +selectedSportId
                  ? "white"
                  : "#CCCCCC"
              }
              background={
                league.id === leagueId && sport.id === +selectedSportId
                  ? orgPrimaryColor
                  : "#FFFFFF20"
              }
              _hover={{
                bg: mode("gray.400", "gray.400"),
                color: mode("white", "white"),
              }}
              to={{
                pathname: `/sports/${sport.id}`,
                state: league.id,
              }}
              onFocus={() => {
                setLeagueId(league.id);
              }}
              as={RouterLink}
              onClick={props.onClose}
            >
              <Text as="span">
                {league.name.charAt(0).toUpperCase() + league.name.slice(1)}
              </Text>
            </Link>
          );
        });
      }

      return (
        <Box key={sport.id}>
          <NavLink
            label={sport.name.charAt(0).toUpperCase() + sport.name.slice(1)}
            fontWeight="bold"
            fontSize="17px"
            image={`/icons/${icon}`}
            isActive={sport.id === +selectedSportId}
            to={`/sports/${sport.id}`}
            bg="#FFFFFF20"
            color="#CCCCCC"
            _active={{ color: "#FFFFFF" }}
            onClick={props.onClose}
          />
          <Box ml={8}>{leagueElements}</Box>
        </Box>
      );
    });
  }

  if (fantasyLeaguesData.status == "success") {
    fantasyLeagueElements = fantasyLeaguesData.data.map((league: any) => {
      let icon: string = sportIconsFilter(league.sport.name);

      return (
        <Box key={league.id}>
          <NavLink
            label={league.name.charAt(0).toUpperCase() + league.name.slice(1)}
            fontWeight="bold"
            fontSize="17px"
            image={`/icons/${icon}`}
            isActive={league.id === +selectedSportId}
            to={`/fantasy/leagues/${league.id}`}
            bg="#FFFFFF20"
            color="#CCCCCC"
            _active={{ color: "#FFFFFF" }}
            onFocus={() => {
              setLeagueId(league.id);
            }}
            onClick={props.onClose}
          />
        </Box>
      );
    });
  }

  return (
    <Flex
      bg={mode(orgSecondaryColor, orgSecondaryColor)}
      direction="column"
      py="10"
      width={{ base: "255px", "2xl": "375px" }}
      position="fixed"
      top="0"
      bottom="-200px"
      display={props.display}
    >
      <Flex direction="column" flex="1" pt="4" pb="40" overflowY="auto" px="4">
        <Stack
          spacing="6"
          as="nav"
          aria-label="Sidebar Navigation"
          mt={{ base: "0px", "2xl": "12" }}
          pb={2}
        >
          <Box>
            <Image
              src={orgLogoUrl}
              alt="Bet Booth Logo"
              height="55px"
              hideFrom="2xl"
            />
          </Box>
          <Stack spacing="1">
            <Box>
              {!fantasyEnabled && (
                <NavLink
                  label={strings[language].sidebar.home}
                  isActive={selectedSportId === "home"}
                  icon={AiFillHome}
                  to="/home"
                  color="#CCCCCC"
                  _active={{ color: "#FFFFFF" }}
                  fontSize="md"
                  onClick={props.onClose}
                />
              )}
            </Box>
            <Text fontSize="2xl" color="#FFFFFF" fontWeight="600">
              {strings[language].sidebar.allSports}
            </Text>

            {!fantasyEnabled ? sportElements : fantasyLeagueElements}
          </Stack>

          <Divider bgColor="gray.400" />

          {/* <Divider color="white" display={{ base: "flex", md: "none" }} />
          {!!parseInt(process.env.REACT_APP_ENABLE_GEO!) &&
            geoComplyData?.region && (
              <HStack
                py="2"
                px="4"
                fontWeight="medium"
                fontSize="sm"
                color="white"
                spacing="4"
              >
                <Icon as={FaLocationArrow} />
                <Text>{geoComplyData?.region}</Text>
              </HStack>
            )} */}
        </Stack>
      </Flex>
    </Flex>
  );
};
