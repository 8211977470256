import { Dispatch, SetStateAction } from "react";
import ReCAPTCHA from "react-google-recaptcha";

interface ReCaptchaProps {
  recaptchaToken: string | null;
  setRecaptchaToken: Dispatch<SetStateAction<string | null>>;
  recaptchaSuccessful: boolean;
  setRecaptchaSuccessful: Dispatch<SetStateAction<boolean>>;
}

const ReCaptcha = (props: ReCaptchaProps) => {
  const SITE_KEY: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

  const onChange = (value: string | null) => {
    console.log("Captcha value:", value);
    props.setRecaptchaToken(value);
    props.setRecaptchaSuccessful(true);
  };

  return (
    <div>
      <ReCAPTCHA sitekey={SITE_KEY} onChange={onChange} />
    </div>
  );
};

export default ReCaptcha;
