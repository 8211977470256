import { Box, Text, SimpleGrid, Center, Flex, Image } from "@chakra-ui/react";
import { Card } from "components/shared/Card";
import { FaChevronRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { languageState } from "store/Store";
import { strings, LanguageKey } from "utils/languageStrings";

export const DepositMethod = () => {
  const language = useRecoilValue<LanguageKey>(languageState);

  var history = useHistory();

  return (
    <Center>
      <Box maxWidth="800px" width="100%" mb="20">
        <Center display="block">
          <Box mb="10">
            <Text textAlign="center" fontSize="xl">
              {strings[language].account.depositMethod}
            </Text>
          </Box>
          <Card
            bg="white"
            w="80%"
            m="auto"
            cursor="pointer"
            onClick={() => history.push("/deposit")}
          >
            <SimpleGrid columns={2}>
              <Box>{strings[language].account.creditCard}</Box>
              <Flex justifySelf="end">
                <Image
                  src="/icons/cc-visa.svg"
                  h="30px"
                  display="inline-block"
                  mx={1}
                />
                <Image
                  src="/icons/cc-amex.svg"
                  h="30px"
                  display="inline-block"
                  mx={1}
                />
                <Image
                  src="/icons/cc-mastercard.svg"
                  h="30px"
                  display="inline-block"
                  mx={1}
                />{" "}
                <Box mt="2">
                  <FaChevronRight display="inline-block" />
                </Box>
              </Flex>
            </SimpleGrid>
          </Card>
        </Center>
      </Box>
    </Center>
  );
};
