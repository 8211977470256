import { GeoComplyDataDTO } from "context/geoComplyContext";
import { EventDTO } from "./Events";
import { ParticipantDTO } from "./Participant";
import { BettingLineDTO } from "./BettingLine";

export enum SportBettingTypes {
  spread = "spread",
  moneyline = "moneyline",
  total = "total",
}

export class BetTicketResponseDTO {
  items: BetTicketDTO[] = [];
  meta: PageMetaDTO = new PageMetaDTO();
}

export interface Bet {
  event: EventDTO;
  riskAmount: number;
  winAmount: number;
  bettingLineId: string;
  externalId: string;
  bettingType: SportBettingTypes;
  category: string;
  odds: number;
  participant: ParticipantDTO;
  sport?: string;
}

export interface BetTicketDTO {
  id: string;
  createdAt: any;
  riskAmount: number;
  winAmount: number;
  odds: number;
  ticketType: string;
  bets: BetDTO[];
  ticketStatus: string;
}

export interface BetDTO {
  id: number;
  eventName: string;
  outcomeName: string;
  startTime: any;
  riskAmount: number;
  expectedProfit: number;
  outcomeId: string;
  eventId: string;
  bettingOfferId: string;
  odds: number;
  bettingType: string;
  betStatus: string;
  paramFloat1: number;
  fantasyOver: boolean;
  sport: {
    name: string;
  };
}

export interface PlaceBetTicketDTO {
  bets: PlaceBetDTO[];
  riskAmount: number;
  ticketType: string;
  acceptOddsChange?: boolean;
  geoPacket: GeoComplyDataDTO;
}

export interface PlaceBetDTO {
  riskAmount: number;
  expectedProfit: number;
  bettingLineId: string;
  externalId: string;
  odds: number;
}

export interface OddsChangeData {
  message: string;
  req: PlaceBetTicketDTO;
  staleLines: PlaceBetDTO[];
  statusCode: number;
  updatedLines: BettingLineDTO[];
}

export class PageMetaDTO {
  total_count: number;
  count: number;
  take: number;
  total_pages: number;
  page: number;

  constructor() {
    this.total_count = 0;
    this.count = 0;
    this.take = 0;
    this.total_pages = 0;
    this.page = 0;
  }
}
